import Cookies from 'universal-cookie';
import _ from 'lodash';
import Moment from 'moment';
import AccountsApi from './../api/accountsApi';
import * as types from './actionTypes';
import UsersApi from './../api/usersApi';
import { getAccountsSucceeded, setCurrentUserAccount } from './../actions/accountActions';
import default_avatar from './../assets/images/default_avatar.jpg';
import { showSpinner, hideSpinner } from './spinnerActions';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';


function getUserAccountFromCookie(userEmail, accounts) {
    var accountId = new Cookies().get(`${userEmail}_selected_account_id`);
    return accountId ? accounts.find(account => account.id === accountId) : null;
}

function sortArray(array) {
    var index = _.findIndex(array, { 'role': 'owner' });
    if (index !== 0) {
        array.splice(0, 0, array.splice(index, 1)[0]);
    }
}

const getUsersSucceeded = payload => ({
    type: types.REQUEST_GET_USERS_SUCCEEDED,
    payload
})

const addUserSucceeded = payload => ({
    type: types.REQUEST_POST_USER_SUCCEEDED,
    payload
})

const deleteUserSucceeded = payload => ({
    type: types.REQUEST_DELETE_USER_SUCCEEDED,
    payload
})

const updateUserSucceeded = payload => ({
    type: types.REQUEST_UPDATE_USER_SUCCEEDED,
    payload
})

export const userRoles = {
    applicationAdministrator: 'Application Administrator',
    support: 'Support',
    owner: 'Owner',
    administrator: 'Administrator',
    operator: 'Operator',
    user: 'User'
}

function convertUser(userFromApi, index = 0) {
    userFromApi.avatarUrl = default_avatar;
    userFromApi.latestSignInFmt = userFromApi.latestSignIn ? Moment(userFromApi.latestSignIn).utc().format(window.env.dateFormat) : '';
    return userFromApi;
}

function updateCurrentUserAccountFromCookie(userEmail, dispatch) {
    return AccountsApi.getAccounts().then(({ data: accounts }) => {
        //save accounts into store
        dispatch(getAccountsSucceeded(accounts));
        //redirect user to /userspage if no session
        if (!accounts || !accounts.length) {
            return Promise.reject("no_account");
        }
        //set current Account into store
        var selectedAccount = getUserAccountFromCookie(userEmail, accounts) || accounts[0];
        return dispatch(setCurrentUserAccount(userEmail, selectedAccount.id)).then(() => {
            return selectedAccount.id;
        });
    }, error => {
        if (error.response && error.response.status === 404)
            throw new Error("Account not found");
    });
}

export function updateCurrentUserAccount(userEmail) {
    return dispatch => updateCurrentUserAccountFromCookie(userEmail, dispatch);
}

export function initUserSession(userEmail) {
    return function (dispatch) {
        //get user accounts
        let promiseUserAccount = updateCurrentUserAccountFromCookie(userEmail, dispatch);
        //update last activity
        return promiseUserAccount.then(async (selectedAccountId) => {
            try {
                await UsersApi.updateUser(selectedAccountId, userEmail, { latestSignIn: new Date().toISOString() })
            }
            catch (e) {
                console.log(e);
            }
            return Promise.resolve();
        });
    }
}

export function refreshUserSession() {
    return function (dispatch, getState) {
        return updateCurrentUserAccountFromCookie(getState().keycloak.userInfo.email, dispatch);
    }
}

export function addUser(accountId, email, role) {
    return function (dispatch) {
        return UsersApi.addUser(accountId, email, role).then(({ data: user }) => {
            dispatch(addUserSucceeded(convertUser(user)));
        })
    }
}

export function deleteUser(accountId, email) {
    return function (dispatch) {
        return UsersApi.deleteUser(accountId, email, "Delete User").then((user) => {
            dispatch(deleteUserSucceeded(email));
        })
    }
}

export function updateUser(accountId, email, values, refresh, action = null) {
    return function (dispatch) {
        return UsersApi.updateUser(accountId, email, values, action).then(({ data: user }) => {
            if (refresh)
                dispatch(updateUserSucceeded(convertUser(user)));
        })
    }
}

export function initializeUsersPage(accountId, cancelToken = null) {
    return function (dispatch) {
        dispatch(showSpinner());
        return UsersApi.getUsers(accountId, cancelToken).then(({ data: users }) => {
            if (users.length > 0) {
                sortArray(users);
                users = users.map((user, index) => convertUser(user, index));
            }
            dispatch(getUsersSucceeded(users));
            dispatch(hideSpinner());
        },(error) => {
            if (!axios.isCancel(error)){
                toastr.error("Init Users Page failed",error.message)
            }
        });
    }
}

export const resetUsersPage = () => ({
    type: types.RESET_USERS_PAGE
})



