import axiosInstance from './axiosConfig';

export default class utilApi {

  static getVersion() {
    return axiosInstance.get('/version');
  }

  static getParameterByKey(key) {
    return axiosInstance.get(`/parameters/${key}`);
  }
};