import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import CustomInput from '../../common/inputs/CustomInput';
import CustomDropdown from '../../common/inputs/CustomDropdown';
import CustomDatePicker from '../../common/inputs/CustomDatePicker';
import * as formatter from './../../../utils/formFormatterUtils';
import * as validation from './../../../utils/formValidationUtils';
import Moment from "moment";

const FormSectionSubscription = props => {

    let { readOnlySubscription, initialValues, setHasErrorValidation } = props;
    let expirationDate = initialValues.subscription.expirationDate;
    return (
        <fieldset style={{ marginTop: '10px' }} key="fieldset-2">
            <legend style={{ paddingRight: '0' }}>
                <FormattedMessage id='cloudAccountPage.form.legendSubscription' />
                <div className="pull-right">
                    <button className="btn btn-default btn-xs" onClick={props.onChangeSubscription}>
                        <i className="fa fa-envelope" style={{ marginRight: '4px' }}></i>
                        <FormattedMessage id='cloudAccountPage.form.buttonSubscription' />
                    </button>
                </div>
            </legend>
            {readOnlySubscription &&
                <div>
                    <Field name="subscription.type" id="subscription-type" disabled={true} type="text" component={CustomInput} label={<FormattedMessage id='cloudAccountPage.form.subscriptionType' />} />
                    <Field name="subscription.maximumLinkedAgent" id="maximum-linked-agent" disabled={true} type="number" component={CustomInput} label={<FormattedMessage id='cloudAccountPage.form.nbMaxLinkedAgent' />} />
                    <Field name="subscription.expirationDate" format={formatter.formatDate} id="expiration-date" disabled={true} type="text" component={CustomInput} label={<FormattedMessage id='cloudAccountPage.form.expirationDate' />} />
                </div>
            }
            {!readOnlySubscription &&
                <div>
                    <Field name="subscription.type" id="subscription-type" type="text" component={CustomDropdown} label={<FormattedMessage id='cloudAccountPage.form.subscriptionType' />} >
                        <option value="Annual">Annual</option>
                    </Field>
                    <Field name="subscription.maximumLinkedAgent" id="subscription.maximumLinkedAgent" parse={value => parseInt(value,10)} type="text" component={CustomDropdown} label={<FormattedMessage id='cloudAccountPage.form.nbMaxLinkedAgent' />} >
                        <option value="10">10</option>
                        <option value="50">50</option>
                    </Field>       
                    <Field name="subscription.expirationDate" id="expiration-date" setHasErrorValidation={setHasErrorValidation} disabled={readOnlySubscription} type="text" customProps={{ selectedItem: {expirationDate}, isValidDate: date => date.isAfter(Moment())}}  component={CustomDatePicker} label={<FormattedMessage id='cloudAccountPage.form.expirationDate' />} validate={[validation.required, validation.isDate]} />
                </div>
            }
        </fieldset>
    )
}

FormSectionSubscription.propTypes = {
    readOnlySubscription: PropTypes.bool.isRequired
}

FormSectionSubscription.defaultProps = {
    readOnlySubscription: false
}

export default FormSectionSubscription;