import { useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./SlotCode.module.scss";
import { injectIntl } from "react-intl";

interface SlotCodeProps {
  message: string;
  dataTestId: string;
  intl: any; // update react-intl and use the hook useIntl()
}

const SlotCode = ({ message, intl, dataTestId }: SlotCodeProps) => {
  const [tooltipMessage, setTooltipMessage] = useState<string>(
    intl.formatMessage({ id: "xtraMotionPage.slotCode.copyToClipboard" })
  );

  return (
    <code data-test-id={`code-${dataTestId}`} className={styles.slotCode}>
      {message}{" "}
      <OverlayTrigger
        delayShow={500}
        placement="top"
        overlay={
          <Tooltip id={`tooltip-copy-code-${dataTestId}`}>{tooltipMessage}</Tooltip>
        }
      >
        <Button
          data-test-id={`btn-copy-code-${dataTestId}`}
          bsStyle="link"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            navigator.clipboard.writeText(message);
            setTooltipMessage(
              intl.formatMessage(
                { id: "xtraMotionPage.slotCode.codeCopied" },
                { code: message }
              )
            );
          }}
          onMouseOut={() => {
            setTooltipMessage(
              intl.formatMessage({
                id: "xtraMotionPage.slotCode.copyToClipboard",
              })
            );
          }}
        >
          <i className="fa fa-clipboard no-text"></i>
        </Button>
      </OverlayTrigger>
    </code>
  );
};

export default injectIntl(SlotCode);
