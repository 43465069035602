import { AxiosResponse, CancelToken } from "axios";
import { OperationSummary } from "../@types";
import axiosInstance from "./axiosConfig";

export default class OperationsApi {
  static getOperationsSummary = (
    accountId: string,
    limit?: number,
    cancelToken?: CancelToken
  ): Promise<Array<OperationSummary>> =>
    axiosInstance
      .get(`/accounts/${accountId}/credits/operations`, {
        params: { limit },
        cancelToken
      })
      .then((response: AxiosResponse) => response.data.operations);

  static getOperationsCsvReport = (accountId: string, cancelToken?: CancelToken) =>
    axiosInstance.get(`/accounts/${accountId}/credits/operations/report`, {
      cancelToken,
      responseType: "blob",
      headers: {
        Accept: "text/csv"
      }
    });
}
