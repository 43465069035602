import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Panel from 'react-bootstrap/lib/Panel';
import { FormattedMessage } from 'react-intl';
import CustomInput from '../common/inputs/CustomInput';
import { selectAuthKeyFromUrl } from './../../reducers/pages/accountPageReducer';

class AuthKeyDetail extends Component {

    render() {
         if (!this.props.initialValues)
             return null;

        return (
            <Panel>
                <Panel.Heading><h3 className="panel-title">{<FormattedMessage id='authKeysMgmtPage.form.detailTitle' />}</h3></Panel.Heading>
                <Panel.Body>
                <form id="authKeyFrom" name="authKeyFrom" className="form-horizontal" autoComplete="off">
                    <div className="col-sm-12" data-test-id='form'>
                        <Field name='name' id="name" type="text" disabled={true} component={CustomInput} label={<FormattedMessage id='authKeysMgmtPage.form.name' />} />
                        <Field name='apiKey' id="apiKey" type="text" disabled={true} component={CustomInput} label={<FormattedMessage id='authKeysMgmtPage.form.authKey' />} />
                        <Field name='createdBy' id="createdBy" type="text" disabled={true} component={CustomInput} label={<FormattedMessage id='authKeysMgmtPage.form.createdBy' />} />
                        <Field name='creationDate' id="creationDate" type="text" disabled={true} component={CustomInput} label={<FormattedMessage id='authKeysMgmtPage.form.createdOn' />} />
                        <Field name='revokedBy' id="revokedBy" type="text" disabled={true} component={CustomInput} label={<FormattedMessage id='authKeysMgmtPage.form.revokedBy' />} />
                        <Field name='revocationDate' id="revocationDate" type="text" disabled={true} component={CustomInput} label={<FormattedMessage id='authKeysMgmtPage.form.revokedOn' />} />
                    </div>
                    <div className="col-sm-12 text-right">
                        <button type="button" id="btnCancel" onClick={(e) => { e.preventDefault(); this.props.history.push('/app/authkeys') }} className="btn btn-default">
                            <i className="fa fa-times" aria-hidden="true"></i>
                            Close
                            </button>
                    </div>
                </form>
                </Panel.Body>
            </Panel>
        );
    }
}

AuthKeyDetail.propTypes = {
    item: PropTypes.object.isRequired,
}

function mapStateToProps(state, props) {
    return {
        initialValues: selectAuthKeyFromUrl(state, props)
    };
}

AuthKeyDetail = reduxForm({
    form: 'authKeyForm',
    enableReinitialize: true
})(AuthKeyDetail);

export default connect(mapStateToProps, null)(AuthKeyDetail);