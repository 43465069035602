import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import CloudAccountForm from './../../common/forms/CloudAccountForm';
import { initializeCloudAccountMgmtPage } from './../../../actions/accountActions';
import { refreshUserSession } from './../../../actions/userActions';
import AccountsApi from './../../../api/accountsApi';
import { cloudAccountFromUrl } from './../../../reducers/pages/accountsMgmtPageReducer';
import FormSectionDetails from './formSections/FormSectionDetails';
import FormSectionSubscription from './formSections/FormSectionSubscription';
import FormSectionServices from './formSections/FormSectionServices';


class CloudAccountDetails extends Component {

    handleSubmitForm = async form => {
        try {
            let createdAccount = await AccountsApi.saveAccount(form);
            await Promise.all([this.props.refreshUserSession(), this.props.initializeCloudAccountMgmtPage()]);
            this.props.history.push(`/app/admin/cloudaccounts/${createdAccount.data.id}`);
            toastr.success('Success', 'Save Cloud Account Succeed');
        }
        catch (error) {
            toastr.error('Error', 'Save Cloud Account Failed');
        }
    }

    handleCancelForm = () => this.props.history.push(`/app/admin/cloudaccounts`);

    render() {
        let { cloudAccountForm} = this.props;
        return (
            <CloudAccountForm name="cloudAccountDetail" initialValues={cloudAccountForm} readOnly={this.props.match.params.cloudaccountid !== 'add'} onSubmit={this.handleSubmitForm} onCancel={this.handleCancelForm} >
                <FormSectionDetails />
                <FormSectionSubscription />
                <FormSectionServices />
            </CloudAccountForm>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        cloudAccountForm: cloudAccountFromUrl(state, props)

    }
}

export default connect(mapStateToProps, { refreshUserSession, initializeCloudAccountMgmtPage })(CloudAccountDetails);