import * as types from '../actions/actionTypes';
import { createReducer } from './../utils/reduxHelper';

const initialState = {
    modalType: null,
    payload: {}
}

function showModal(state = initialState, action) {
    return {
        modalType: action.modalType,
        payload: action.payload
    }
}

function hideModal(state, action) {
    return initialState;
}

const modalReducer = createReducer(initialState, {
    [types.SHOW_MODAL]: showModal,
    [types.HIDE_MODAL]: hideModal
})

export default modalReducer;