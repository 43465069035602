import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './components/App';
import 'evs-themes-web-bootstrap3/dist/css/bootstrap-dark-evs.min.css';
import 'font-awesome/css/font-awesome.css';
import store from './store/configureStore';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './components/App.css';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import messages from './assets/i18n/messages';
import flattenMessages from './utils/i18nFlattenMessagesUtil';

addLocaleData([...en]);
let locale = window.env.locale;

ReactDOM.render(
    <Provider store={store}>
        <IntlProvider locale={locale} messages={flattenMessages(messages[locale])}>
            <App />
        </IntlProvider>
    </Provider>,
    document.getElementById('root')
);