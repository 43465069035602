import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import LoginPage from './login/LoginPage';
import UnauthorizePage from './common/error/UnauthorizePage';
import AccountNotFoundPage from './common/error/AccountNotFoundPage';
import TermsPage from './terms/TermsPage';
import SecureApp from './SecureApp';
import authComponent from './authComponent';
import 'babel-polyfill';
import './App.css';
import Spinner from './common/spinner/Spinner';
import history from './../utils/reactRouterHistory';
import ReactGA from 'react-ga';
import NotFoundPage from './../components/common/error/NotFoundPage';

const kcInitCheckSSO = authComponent({ onLoad: 'check-sso',redirectAfterCallback:'/app/users' });
const kcInitLoginRequired = authComponent({ onLoad: 'login-required'});

function initializeGa(history){
  ReactGA.initialize('UA-73974920-16');
  ReactGA.pageview(window.location.host + window.location.pathname);
  history.listen(function (location) {
    ReactGA.pageview(window.location.host + window.location.pathname);
  })
};

const App = ({ props }) => {
  initializeGa(history);
  return (
    <Router history={history}>
      <div>
        <Spinner></Spinner>
        <Switch>
          <Route path="/app" component={kcInitLoginRequired(SecureApp)} />
          <Route path="/login" component={kcInitCheckSSO(LoginPage)} />
          <Route path="/terms" component={TermsPage} />
          <Route path="/unauthorized" component={UnauthorizePage} />
          <Route path="/accountnotfound" component={AccountNotFoundPage} />
          <Route exact path="/" render={() => <Redirect to={`/login${history.location.search}`} />} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    </Router>
  )
}

export default App;
