import { AxiosResponse, CancelToken } from "axios";
import {
  HistoricOperationBackend,
  ListResponseBackend,
  ScheduleInstanceRequest,
  SlotBackend,
  SlotsBackend,
  SubmittedSlotUI,
  UpdateSlotBackend,
  XtraMotionVersionBackend,
} from "../@types";
import Moment from "moment";
import Api from "./axiosConfig";

const xtraMotionApi = {
  getXtraMotionVersions: async (
    accountId: string,
    cancelToken?: CancelToken
  ) => {
    let response = await Api.get<ListResponseBackend<XtraMotionVersionBackend>>(
      `/accounts/${accountId}/xtramotion/versions`,
      { cancelToken }
    );
    return response.data;
  },

  getSlots: async (accountId: string, cancelToken?: CancelToken) => {
    let response = await Api.get<SlotsBackend>(
      `/accounts/${accountId}/xtramotion/slots`,
      { cancelToken }
    );
    return response.data;
  },

  startInstanceForSlot: async (
    accountId: string,
    slotId: string,
    scheduledStopDateTime: Moment.Moment,
    cancelToken?: CancelToken
  ) => {
    let response = await Api.post<
      any,
      AxiosResponse<any>,
      ScheduleInstanceRequest
    >(
      `/accounts/${accountId}/xtramotion/slots/${slotId}/start`,
      { scheduledStopDateTime },
      { cancelToken }
    );
    return response.data;
  },

  stopInstanceForSlot: async (
    accountId: string,
    slotId: string,
    cancelToken?: CancelToken
  ) => {
    let response = await Api.post(
      `/accounts/${accountId}/xtramotion/slots/${slotId}/stop`,
      undefined,
      { cancelToken }
    );
    return response.data;
  },

  updateInstanceStopDateTime: async (
    accountId: string,
    slotId: string,
    scheduledStopDateTime: Moment.Moment,
    cancelToken?: CancelToken
  ) => {
    let response = await Api.put(
      `/accounts/${accountId}/xtramotion/slots/${slotId}/instance/schedule`,
      { scheduledStopDateTime },
      { cancelToken }
    );
    return response.data;
  },

  renewCode: async (
    accountId: string,
    slotId: string,
    cancelToken?: CancelToken
  ) => {
    let response = await Api.put<SlotBackend>(
      `/accounts/${accountId}/xtramotion/slots/${slotId}/code/renew`,
      null,
      { cancelToken }
    );
    return response.data;
  },

  removeSlot: async (
    accountId: string,
    slotId: string,
    cancelToken?: CancelToken
  ) => {
    let response = await Api.delete(
      `/accounts/${accountId}/xtramotion/slots/${slotId}`,
      { cancelToken }
    );
    return response.data;
  },

  getMonitoringInstanceUri: async (
    accountId: string,
    slotId: string,
    cancelToken?: CancelToken
  ) => {
    let response = await Api.get<string>(
      `/accounts/${accountId}/xtramotion/slots/${slotId}/instance/uri`,
      { cancelToken }
    );
    return response.data;
  },

  getHistoricOperations: async (
    accountId: string,
    instanceId?: string,
    orderBy?: "asc" | "desc",
    cancelToken?: CancelToken
  ) => {
    let response = await Api.get<HistoricOperationBackend[]>(
      `/accounts/${accountId}/xtramotion/historic-operations`,
      {
        cancelToken,
        params: {
          instanceId,
          orderBy,
        },
      }
    );
    return response.data;
  },

  addSlot: async (
    accountId: string,
    slotToAdd: SubmittedSlotUI,
    cancelToken?: CancelToken
  ) => {
    let response = await Api.post<
      SlotBackend,
      AxiosResponse<SlotBackend>,
      SubmittedSlotUI
    >(`/accounts/${accountId}/xtramotion/slots`, slotToAdd, { cancelToken });
    return response.data;
  },

  updateSlot: async (
    accountId: string,
    slotId: string,
    updatedSlot: UpdateSlotBackend,
    cancelToken?: CancelToken
  ) => {
    let response = await Api.put<
      SlotBackend,
      AxiosResponse<SlotBackend>,
      UpdateSlotBackend
    >(`/accounts/${accountId}/xtramotion/slots/${slotId}`, updatedSlot, {
      cancelToken,
    });
    return response.data;
  },
};

export default xtraMotionApi;
