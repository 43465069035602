import React, { Component } from 'react'
import InputText from './editInline/InputText'
import SelectBox from './editInline/SelectBox';
import { Tooltip } from 'react-tippy'; 
import 'react-tippy/dist/tippy.css'; 
import classnames from 'classnames';

class LocationCustomParameters extends Component {

    constructor(props) {
        super(props);
        this.state = {expandedAdvanced: false};
        this.handleOnClickAdvancedRow = this.handleOnClickAdvancedRow.bind(this);
    }

    renderExtraInput(parameter, idx) {
        switch (parameter.type) {
            case 'bool': {
                return (
                    <SelectBox
                        edit={this.props.edit}
                        key={idx}
                        name={parameter.label}
                        locationName={this.props.locationName}
                        onChange={(event) => this.props.onChange(event, true)}
                        value={parameter.value}
                        items={["true","false"]}
                        onKeyPress={this.props.onKeyPress}
                        className="input-parameter" />
                )
            }
            default: {
              return (
                    <InputText
                        className="input-parameter"
                        key={idx}
                        type={parameter.type}
                        name={parameter.label}
                        locationName={this.props.locationName}
                        value={parameter.value}
                        onChange={(event) => this.props.onChange(event, true)}
                        onKeyPress={this.props.onKeyPress}
                        edit={this.props.edit}
                    />
                )
            }
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if ((this.state.expandedAdvanced !== nextState.expandedAdvanced))
            this.updateDetailHeight(nextState.expandedAdvanced);
    }

    updateDetailHeight(nextExpandedState) {
        let advancedHeight = this.refs.advancedDetails.scrollHeight;
        let parentHeight = parseInt(this.refs.advanced.parentElement.style.maxHeight,10);
        if (nextExpandedState) {
            this.refs.advanced.parentElement.style.maxHeight = `${parentHeight + advancedHeight}px`;
            this.refs.advancedDetails.style.visibility = '';
            this.refs.advancedDetails.style.position = '';
        } else if (!nextExpandedState) {
            this.refs.advanced.parentElement.style.maxHeight = `${parentHeight - advancedHeight}px`;
            this.refs.advancedDetails.style.visibility = 'hidden';
            this.refs.advancedDetails.style.position = 'absolute';
        }
    }
    
    handleOnClickAdvancedRow(event) {
        this.setState({
            expandedAdvanced: !this.state.expandedAdvanced
        });
        event.stopPropagation();
    }

    render() {
        let expandAdvancedIconClass = classnames('fa fa-caret-down rotate click-to-expand', { 'down': this.state.expandedAdvanced });
        let defaultParameters = this.props.data.filter((parameter) => !parameter.advanced);
        let advancedParamters = this.props.data.filter((parameter) => parameter.advanced);
        let showAdvancedParameters = {display : advancedParamters.length === 0 ? "none" : ""};

        return (
            <div style={{"width": "100%"}} ref="advanced">
                <table className="table table-striped table-hover custom-table">
                    <thead>
                        <tr>
                            <th>Parameters</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {defaultParameters.map((parameter, idx) =>
                            <tr key={idx}>
                                <td>
                                    <Tooltip interactive html={(<span>{parameter.description}</span>)} size="big" arrow> 
                                        <span data-test-id={this.props.locationName + "_lbl_default_" + parameter.label}>{parameter.label}</span>
                                    </Tooltip>
                                </td>
                                <td>
                                    <InputText
                                        className="input-parameter"
                                        key={'input'+idx}
                                        name={parameter.label}
                                        value={parameter.value || ''}
                                        locationName={this.props.locationName + '_input_default'}
                                        type={parameter.sensitive ? 'password' : 'text'}
                                        onChange={(event) => this.props.onChange(event, false)}
                                        onKeyPress={this.props.onKeyPress}
                                        edit={this.props.edit}
                                    />
                                </td>
                            </tr>
                        )}
                        {this.props.extraData.map((parameter, idx) =>
                            <tr key={parameter.label}>
                                <td>{parameter.label} *</td>
                                <td>
                                    {this.renderExtraInput(parameter, idx)}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div style={showAdvancedParameters}>
                    <table className="table table-striped table-hover custom-table">
                        <thead data-test-id={`${this.props.locationName}-adv-params`} onClick={this.handleOnClickAdvancedRow}>
                            <tr>
                                <th><i className={expandAdvancedIconClass}></i>Advanced parameters</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody  ref="advancedDetails" style={{"visibility":"hidden","position":"absolute"}}>
                            {advancedParamters.map((parameter, idx) =>
                                <tr key={idx}>
                                    <td>
                                        <Tooltip interactive html={(<span>{parameter.description}</span>)} size="big" arrow> 
                                            <span data-test-id={this.props.locationName + "_lbl_adv_" + parameter.label}>{parameter.label}</span>
                                        </Tooltip>
                                    </td>
                                    <td>
                                        <InputText
                                            className="input-parameter"
                                            key={'input'+idx}
                                            name={parameter.label}
                                            value={parameter.value || ''}
                                            locationName={this.props.locationName + '_input_adv'}
                                            type={parameter.sensitive ? 'password' : 'text'}
                                            onChange={(event) => this.props.onChange(event, false)}
                                            onKeyPress={this.props.onKeyPress}
                                            edit={this.props.edit}
                                        />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default LocationCustomParameters;