import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import '../../../../assets/css/modal.css';
import evs_logo from '../../../../assets/images/evs.png';

const AboutModal = ({ productname, version, onClose }) => {
    return (
        <Modal show={true}>
            <Modal.Header closeButton onHide={onClose}> 
                <Modal.Title>About</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{'padding':'0'}} >
                <div className="container-fluid" style={{'margin':'0'}}>
                    <div className="row bg-primary">
                        <div className="col-xs-6">
                            <img src={evs_logo} alt="evs_logo" className="evs-about-panel-logo" style={{'margin':'20px 20px 20px 0'}} />
                        </div>
                        <div className="col-xs-6 text-right">
                            <h4>{ productname }</h4>
                            <p>{ version }</p>
                        </div>
                    </div>
                    <div className="row label-default">
                        <div className="col-sm-6">
                            <h5>ASIA &amp; PACIFIC</h5>
                            <div className="row">
                                <div className="col-xs-5"><strong>Hong&nbsp;Kong</strong></div>
                                <div className="col-xs-7"><a href="tel:+85294012395">+852 94 01 23 95</a></div>
                            </div>
                            <div className="row">
                                <div className="col-xs-5"><strong>China</strong></div>
                                <div className="col-xs-7"><a href="tel:+8613910289860">+86 10 5670 9883</a></div> 
                            </div>
                            <div className="row">
                                <div className="col-xs-5"><strong>Australia</strong></div>
                                <div className="col-xs-7"><a href="tel:+610294528600">+61 02 9452 8600</a></div>
                            </div>

                            <br />
                            <h5>NORTH&nbsp;AMERICA &amp; LATIN&nbsp;AMERICA</h5>
                            <div className="row">
                                <div className="col-xs-5"><strong>USA</strong></div>
                                <div className="col-xs-7"><a href="tel:+19735757813">+1 973 575 7813</a></div>
                            </div>
                            <div className="row">
                                <div className="col-xs-5"><strong>Mexico</strong></div>
                                <div className="col-xs-7"><a href="tel:+525546312209">+52 55 46 31 22 09</a></div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <h5>EUROPE, MIDDLE&nbsp;EAST &amp; AFRICA</h5>
                            <div className="row">
                                <div className="col-xs-5"><strong>Belgium</strong></div>
                                <div className="col-xs-7"><a href="tel:+32495284000">+32 4 361 70 00</a></div> 
                            </div>
                            <div className="row">
                                <div className="col-xs-5"><strong>France</strong></div>
                                <div className="col-xs-7"><a href="tel:+33146999003">+33 1 46 99 9003</a></div>
                            </div>
                            <div className="row">
                                <div className="col-xs-5"><strong>Italy</strong></div>
                                <div className="col-xs-7"><a href="tel:+393346311493">+39 334 631 14 93</a></div>
                            </div>
                            <div className="row">
                                <div className="col-xs-5"><strong>UK</strong></div>
                                <div className="col-xs-7"><a href="tel:+441372387266">+44 1 372 387 266</a></div>
                            </div>
                            <div className="row">
                                <div className="col-xs-5"><strong>Dubai</strong></div> 
                                <div className="col-xs-7"><a href="tel:+97143654231">+971 4 3654 231</a></div>
                            </div>
                            <div className="row">
                                <div className="col-xs-5"><strong>Iberica</strong></div>
                                <div className="col-xs-7"><a href="tel:+34914903933">+34 91 490 39 33</a></div>
                            </div>
                            <div className="row">
                                <div className="col-xs-5"><strong>Germany</strong></div>
                                <div className="col-xs-7"><a href="tel:+4989411194977">+49 89 4111 949 77</a></div>
                            </div>
                            <div className="row">
                                <br />
                                <br />
                            </div>
                            <div className="row">
                                <div className="col-xs-5"><strong>Global&nbsp;email</strong></div>
                                <div className="col-xs-7"><a href="mailto:support@evs.com">support@evs.com</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="row label-default">
                        <br />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer > 
                <Button onClick={onClose}><i className="fa fa-check"></i>Ok</Button> 
            </Modal.Footer>
        </Modal>
    )
}

export default AboutModal;