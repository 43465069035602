import React, { FC } from "react";
import { ProgressBar } from "react-bootstrap";
import { CreditOrder } from "../../../../@types"
import styles from "./CreditOrdersSummaryTableRow.module.scss"
import UtcToLocalDate from "../../../common/utils/UtcToLocalDate";

const CreditOrdersSummaryTableRow: FC<CreditOrder> = ({
  description,
  date,
  expirationDate,
  credits,
  currentBalance
}) => {
  const remainingCreditsPercent = (currentBalance / credits) * 100;

  return (
    <tr>
      <td>{description}</td>
      <td>
        <UtcToLocalDate date={date} />
      </td>
      <td>
        <UtcToLocalDate date={expirationDate} />
      </td>
      <td className={styles.root}>
        <ProgressBar bsStyle="success" now={remainingCreditsPercent} label={<div className="progress-bar-title">{currentBalance}/{credits}</div>} />
      </td>
    </tr>
  );
};

export default CreditOrdersSummaryTableRow;
