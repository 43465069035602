import React, { Component } from 'react';
import classnames from 'classnames';
import { toastr } from 'react-redux-toastr';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import _ from 'lodash';
import LocationAction from './LocationAction';
import LocationCustomParameters from './LocationCustomParameters';
import InputText from './editInline/InputText';
import SelectBox from './editInline/SelectBox';
import { getParametersFromProtocol } from './../../../utils/locationMapper';
import { getOverwritePoliciesFromProtocol } from './../../../utils/locationMapper';

class LocationRow extends Component {

    constructor(props) {
        super(props);

        let isAddAction = !this.props.item.name;
        let overwritePolicies = getOverwritePoliciesFromProtocol(this.props.protocolsById[props.item.protocol]);
        this.state = {
            editMode: isAddAction,
            copyLocation: isAddAction ? _.cloneDeep(this.props.item) : props.item,
            expanded: isAddAction,
            showErrors: false,
            validationErrors: {},
            labelValueProtocols: this.props.availableProtocols,
            labelValueOverwritePolicies: overwritePolicies,
            labelOverwritePolicy: props.item.overwritePolicy
        }
        this.handleEditLocation = this.handleEditLocation.bind(this);
        this.onUpdateProtocol = this.onUpdateProtocol.bind(this);
        this.onCancelEdit = this.onCancelEdit.bind(this);
        this.handleOnClickRow = this.handleOnClickRow.bind(this);
        this.handleSaveLocation = this.handleSaveLocation.bind(this);
        this.onUpdateProperty = this.onUpdateProperty.bind(this);
        this.onUpdateSpecificParameterProperty = this.onUpdateSpecificParameterProperty.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.onUpdateOverwritePolicy = this.onUpdateOverwritePolicy.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.item !== nextProps.item) {
          this.setState({
            copyLocation: nextProps.item
          })
        }      
      } 

    componentDidMount() {
        //if a new location has been added
        if (!this.props.item.name) {
            this.updateDetailHeight(this.state.expanded);
            this.locationName.focus();
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if ((this.state.expanded !== nextState.expanded))
            this.updateDetailHeight(nextState.expanded);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.copyLocation.protocol !== prevState.copyLocation.protocol) {
            this.updateDetailHeight(prevState.expanded);
        }
        if (this.state.editMode && !prevState.editMode) {
            this.locationName.focus();
        }
    }

    updateDetailHeight(nextExpandedState) {
        if (nextExpandedState) {
            this.refs.detail.style.maxHeight = `${this.refs.detail.scrollHeight}px`;
        } else if (!nextExpandedState) {
            this.refs.detail.style.maxHeight = '0px';
        }
    }

    handleOnClickRow(event) {
        if (event.target.className.indexOf('click-to-expand') !== -1) {
            this.setState({
                expanded: !this.state.expanded
            })
        }
    }

    handleEditLocation(event) {
        this.setState({
            editMode: true,
            copyLocation: _.cloneDeep(this.props.item),
            expanded: true
        });
    }

    handleSaveLocation() {
      this.props.onSave(this.state.copyLocation).then(result => {
            this.setState({
                editMode: false,
                copyLocation: this.props.item,
                validationErrors: {},
            });
        }, error => {
            if (error.validationErrors) {
                this.setState({
                    validationErrors: error.validationErrors
                });
                toastr.error('Error during saving location', error.validationErrors.name);
            }
        });
    }

    convertPluginMap = (selectedProtocol) => {
        let parameters = getParametersFromProtocol(selectedProtocol);
        let self = this;
        if (this.props.item.protocol === selectedProtocol.id) {
            parameters.forEach(parameter => {
                let oldValue = self.props.item.parameters.find(param => param.label === parameter.label);
                parameter.value = oldValue ? oldValue.value : parameter.value;
            })
        }
        return _.orderBy(parameters, ['index'], ['asc']);
    }

    onUpdateProtocol(event) {
        let newLocation = _.extend({}, this.state.copyLocation);
        newLocation[event.target.name] = event.target.value;
        let selectedProtocol = this.props.protocolsById[event.target.value];
        if (selectedProtocol) {
            newLocation.parameters = this.convertPluginMap(selectedProtocol);
            let overwritePolicies = getOverwritePoliciesFromProtocol(selectedProtocol);
            newLocation.overwritePolicy = overwritePolicies[0].name;
            this.setState({
                copyLocation: newLocation,
                labelValueOverwritePolicies: overwritePolicies,
                labelOverwritePolicy: overwritePolicies[0].id
            });
        }
    }

    onCancelEdit(event) {
        //reset edit
        let nextState = {editMode: false, expanded: false, validationErrors: {}};
        if (this.props.item.id)
            nextState.copyLocation = this.props.item;
        this.setState(nextState);

        this.props.onCancel(event, this.props.item);
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this.handleSaveLocation();
            return;
        }
    }

    onUpdateProperty(event) {
        let newLocation = _.extend({}, this.state.copyLocation);
        newLocation[event.target.name] = event.target.value;
        this.setState({
            copyLocation: newLocation
        });
    }

    onUpdateSpecificParameterProperty(event, isExtraParam) {
        let newLocation = _.extend({}, this.state.copyLocation);
        let params = (isExtraParam) ? newLocation.extraParameters : newLocation.parameters;
        let specificParam = params.find(param => param.label === event.target.name)
        specificParam.value = event.target.value;
        this.setState({
            copyLocation: newLocation
        });
    }

    onUpdateOverwritePolicy(event) {
        let newLocation = _.extend({}, this.state.copyLocation);
        newLocation.overwritePolicy = event.target.value;
        this.setState({
            copyLocation: newLocation,
            labelOverwritePolicy: event.target.value
        });
    }

    render() {
        let item = this.props.item;
        let editMode = this.state.editMode;
        let classParameters = classnames('custom-params', { 'expand': this.state.expanded });
        let classExpanded = classnames('flex-table-row custom-row', { 'expanded': this.state.expanded });
        let classStatus = classnames(
            { 'status-unknow': item.status.state === 'unknow' },
            { 'status-reachable': item.status.state === 'successful' },
            { 'status-unreachable': item.status.state === 'failed' }
        );
        let expandIconClass = classnames('fa fa-caret-down rotate click-to-expand', { 'down': this.state.expanded });
        let classInputError = classnames({ 'has-error': this.state.validationErrors.name });

        return (
            <div className={classExpanded} data-test-id={`row-group-body-${this.props.index}`} onClick={this.handleOnClickRow}>
                <div className={classStatus}>
                    <div className="flex-table-row-item column-status click-to-expand" data-test-id={`expand-location-${this.props.index}`}><i className={expandIconClass}></i></div>
                    <div className="flex-table-row-item click-to-expand">
                        <div className={classInputError}>
                            <Tooltip interactive disabled={editMode} html={(<span>{item.id}</span>)} size="big" arrow>
                                <InputText
                                    edit={editMode}
                                    name="name"
                                    type="text"
                                    locationName={this.state.copyLocation.name}
                                    value={this.state.copyLocation.name}
                                    inputRef={(input) => this.locationName = input}
                                    className="click-to-expand"
                                    showError={this.state.validationErrors.name}
                                    onChange={this.onUpdateProperty}
                                    onKeyPress={this.handleKeyPress} />
                            </Tooltip>
                        </div>
                    </div>
                    <div className="flex-table-row-item click-to-expand">
                        <SelectBox
                            edit={editMode}
                            name="protocol"
                            onChange={this.onUpdateProtocol}
                            locationName={this.state.copyLocation.name}
                            value={this.state.copyLocation.protocol}
                            items={this.state.labelValueProtocols}
                            className="input-title click-to-expand" />
                    </div>
                    <div className="flex-table-row-item click-to-expand">
                        <SelectBox
                            edit={editMode}
                            name="overwritePolicy"
                            locationName={this.state.copyLocation.name}
                            onChange={this.onUpdateOverwritePolicy}
                            value={this.state.copyLocation.overwritePolicy}
                            items={this.state.labelValueOverwritePolicies}
                            className="input-title click-to-expand capitalize" />
                    </div>
                    <LocationAction edit={editMode}
                        index={this.props.index}
                        onDelete={this.props.onDelete}
                        onCheck={this.props.onCheck}
                        onEdit={this.handleEditLocation}
                        onSave={this.handleSaveLocation}
                        onCancel={this.onCancelEdit} >
                    </LocationAction>
                </div>
                <div className={classParameters} ref="detail">
                    <LocationCustomParameters
                        edit={editMode}
                        locationName={this.state.copyLocation.name}
                        data={this.state.copyLocation.parameters}
                        extraData={this.state.copyLocation.extraParameters}
                        onChange={this.onUpdateSpecificParameterProperty}
                        onKeyPress={this.handleKeyPress} >
                    </LocationCustomParameters>
                </div>
            </div>
        )
    }
}

export default LocationRow;
