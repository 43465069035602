import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './user.css';
import UserActionLinks from './UserActionLinks';
import {userRoles} from './../../actions/userActions';

class UserItem extends Component {

        render() {
        let { email, role, latestSignInFmt, avatarUrl } = this.props.item;
        let classItem = classnames('list-group-item', { "active": this.props.isActive });
        let classImg = role === 'owner' ? 'owner-profile' :'';
        return (
            <div onClick={this.props.onClick} className={classItem} style={{ position: 'relative' }}>
                <div className="media">
                    <div className="media-left">
                        <div className="avatar">
                            <img alt="avatar" src={avatarUrl} className={classImg} />
                        </div>
                    </div>
                    <div className="media-body">
                        <h4 className="media-email">{email}</h4>
                        <h5 className='role'>{userRoles[role]}&nbsp;</h5>
                        {latestSignInFmt && <h5>Latest sign-in on {latestSignInFmt}</h5>}
                    </div>
                    <div className="media-right" style={{verticalAlign:'top'}}>
                        <UserActionLinks rights={this.props.rights} item={this.props.item} account={this.props.account} onEdit={this.props.onEdit} onDelete={this.props.onDelete} />
                    </div>
                </div>
            </div>
        )
    }
}

UserItem.propTypes = {
    item: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    rights: PropTypes.object.isRequired
}

export default UserItem;