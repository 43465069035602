import React, {FC} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {hideModal, showCreditOrderDlg} from "../../../actions/modalActions";
import {addCreditOrder} from "../../../actions/accountActions";
import {connect} from "react-redux";
import {Balance, CreditOrder} from "../../../@types";
import {UserRights} from "../../../utils/ability";
import Can from "../../common/abilities/Can";
import { useQueryClient } from 'react-query'

interface Props {
  intl: any;
  hideModal: () => void;
  account: any;
  showCreditOrderDlg: (parameter: any) => void;
  addCreditOrder: (model: CreditOrder) => Promise<any>;
  onCreditsRequested: (event: React.MouseEvent<HTMLElement>) => void;
  balance?: Balance;
  canAddCredit: boolean;
}

const FormSectionCredits: FC<Props> = ({
  hideModal,
  showCreditOrderDlg,
  onCreditsRequested,
  intl,
  account,
  addCreditOrder,
  balance,
  canAddCredit,
}) => {

  const queryClient = useQueryClient();

  const handleOpenInstanceModal = (event: any) => {
    event.preventDefault();
    if(canAddCredit)
      showCreditOrderDlg({
        title: intl.formatMessage({id: 'cloudAccountPage.form.createOrder.title'}),
        account,
        onSubmit: async (model: CreditOrder) => {
          await addCreditOrder(model);

          await queryClient.invalidateQueries('credits')
        },
        onClose: () => hideModal()
      });
  }

  return (
    <Can I={UserRights.DisplayCredits}>
      <fieldset key="fieldset-3">
        <legend style={{paddingRight: "0"}}>
          <FormattedMessage id="cloudAccountPage.form.sections.credits.legend"/>
          <div className="pull-right">
            <Can I={UserRights.AddCredits}>
              <button className="btn btn-default btn-xs"
                      onClick={handleOpenInstanceModal}>
                <i className="fa fa-plus"/>
                <FormattedMessage id="cloudAccountPage.form.sections.credits.buttonAddMoreCredits"/>
              </button>
            </Can>
            <button
              className="btn btn-default btn-xs"
              onClick={onCreditsRequested}
            >
              <span className="fa fa-envelope" style={{marginRight: "4px"}}/>
              <FormattedMessage id="cloudAccountPage.form.sections.credits.buttonRequestMoreCredits"/>
            </button>
          </div>
        </legend>
        <div className="form-group">
          <label className="col-sm-4 control-label" htmlFor="balance">
            <FormattedMessage id="cloudAccountPage.form.sections.credits.currentBalance"/>
          </label>
          <div className="col-sm-8">
            <input
              id="balance"
              name="balance"
              type="text"
              className="form-control"
              disabled
              value={balance ? balance.balance : ""}
            />
          </div>
        </div>
      </fieldset>
    </Can>
  );
};


export default connect((state: any) => ({
  canAddCredit: state.common.userRights.addCredits,
  }), {
  showCreditOrderDlg,
  hideModal,
  addCreditOrder,

})(injectIntl(FormSectionCredits));