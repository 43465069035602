import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import { OverlayTrigger, Tooltip} from 'react-bootstrap';
import '../../../assets/css/location-animation.css';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import CSSTransition from 'react-transition-group/CSSTransition';
import { orderByLocationColumn, addLocation, cancelEditLocation, deleteLocation, saveLocation, getLocations } from '../../../actions/locationActions';
import { showConfirmDlg, hideModal } from '../../../actions/modalActions';
import LocationRow from './LocationRow';
import LocationHeaders from './LocationHeaders';

class LocationGrid extends Component {

    constructor(props) {
        super(props);
        this.deleteLocation = this.deleteLocation.bind(this);        
        this.state = {
            addLocationActive: false
        }
    }

    deleteLocation(event, index) {
        event.stopPropagation();
        const {id:accountId} = this.props.account;
        let location = this.props.locations[index];

        //don't show confirm dlg if location has not been saved
        if (!location.id) {
            this.props.deleteLocation(location, accountId);
            return;
        }

        this.props.showConfirmDlg({
            title: 'Delete Location',
            htmlBody: `<p>Are you sure you want to delete location '${this.props.locations[index].name}'?</p>`,
            onConfirm: () => {
                this.props.deleteLocation(location, accountId).then(() => {
                    if (location.id) {
                        toastr.success('Success', `Location '${location.name}' has been deleted`);
                        this.props.hideModal();
                    }
                }, error => {
                    toastr.error('Delete location failed', error.fullError);
                    this.props.hideModal();
                })

            },
            onClose: () => this.props.hideModal() //nothing to do
        })
    }

    saveLocation(location) {
        //apply validation
        if (location.name === '')
            return Promise.reject({
                validationErrors: {
                    name: 'Location name is required'
                }
            });
        else if (this.props.locations.find(row => row.key !== location.key && row.name === location.name)) {
            return Promise.reject({
                validationErrors: {
                    name: 'Location name must be unique'
                }
            });
        }

        return this.props.saveLocation(location).then(result => {
            toastr.success('Success', `Location '${location.name}' has been saved`);
            this.setState({ addLocationActive: false });
        }, error => {            
            return Promise.reject(error);
        });
    }

    syncLocations = () => {
        return this.props.getLocations().then(result =>{
            this.setState({ addLocationActive: false,needToUpdate: false  });            
        }, error => {
            toastr.error('Refresh locations failed', error.fullError);
            return Promise.reject(error);
        });        
    }

    addLocation = () => {
      if (!this.state.addLocationActive) {
            this.setState({ addLocationActive: true });
            this.props.addLocation(this.props.defaultSelectedProtocolId);
        }
    }

    cancelEditLocation(event, index) {
        event.stopPropagation();
        //if this is a new location
        if (!this.props.locations[index].id) {
            this.setState({ addLocationActive: false });
            this.deleteLocation(event, index)
        }
    } 

    renderHeaders() {
        return <LocationHeaders data={this.props.headers} onClick={this.props.orderBy}></LocationHeaders>;
    }

    renderRows() {
        return (
            this.props.locations.map((row, index) => {
              return (
                <CSSTransition key={row.key} classNames="example" timeout={{ enter: 500, exit: 500 }}>
                  <LocationRow key={row.key}
                      item={row}
                      index={index}
                      protocolsById={this.props.protocolsById}
                      availableProtocols={this.props.availableProtocols}
                      onSave={location => this.saveLocation(location)}
                      onDelete={(e) => this.deleteLocation(e, index)}
                      onCancel={(e, location) => this.cancelEditLocation(e, index)}></LocationRow>
                </CSSTransition>
            )}
        ));
    }

    render() {
        let { isFetching, locations, needToUpdate } = this.props;
        let hasLocation = locations.length > 0;

        return (
            <div className="col-lg-7 col-lg-offset-1" style={{marginBottom:'70px'}}>
                <div>
                    {hasLocation &&
                        <div>
                            <div className="add-loc-container" >
                                {needToUpdate &&
                                    <OverlayTrigger placement="top" delayShow={500} overlay={<Tooltip id="tooltip">Refresh</Tooltip>}>
                                        <button className="btn btn-default btn-sync-locations" style={{ 'marginLeft': '7px'}} onClick={this.syncLocations}>Refresh</button>
                                    </OverlayTrigger>
                                }
                                <OverlayTrigger placement="top" delayShow={500} overlay={<Tooltip id="tooltip">Add New Location</Tooltip>}>
                                    <button className="btn btn-default btn-add-location" style={{ 'marginLeft': '7px'}}
                                        disabled={this.state.addLocationActive}
                                        onClick={this.addLocation}>
                                        <i className="fa fa-plus no-text" data-test-id="add-location" />
                                    </button>
                                </OverlayTrigger>
                                <div style={{ 'clear': 'both' }}></div>
                            </div>
                            <div className="flex-table" data-test-id="locations-table">
                                {this.renderHeaders()}
                                <TransitionGroup>
                                    {this.renderRows()}
                                </TransitionGroup>
                            </div>
                        </div>
                    }
                    {!hasLocation && !isFetching &&
                        <div className="col-lg-20 col-lg-offset-1">
                            <h4>
                                <i className="fa fa-warning warn-msg"></i>No location configured.                                                                
                                <button className="btn btn-primary" data-test-id="btn-add-new-location" style={{ 'marginLeft': '15px' }} onClick={() => this.addLocation()}><i className="fa fa-plus"></i>Add New Location</button>
                            </h4>
                        </div>}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    let { location } = state;
    return {
        needToUpdate: location.needToUpdate,
        locations: location.rows,
        protocolsById: location.protocolsById,
        headers: location.headers,
        isFetching: location.fetching,
        defaultSelectedProtocolId: location.defaultSelectedProtocolId,
        availableProtocols: location.labelValueProtocols,
        account: state.account.currentAccount,
    }
}

function mapDispatchToProps(dispatch) {
    return {        
        addLocation: bindActionCreators(addLocation, dispatch),
        getLocations : bindActionCreators(getLocations,dispatch),
        orderBy: bindActionCreators(orderByLocationColumn, dispatch),
        deleteLocation: bindActionCreators(deleteLocation, dispatch),
        saveLocation: bindActionCreators(saveLocation, dispatch),
        cancelEditLocation: bindActionCreators(cancelEditLocation, dispatch),
        showConfirmDlg: bindActionCreators(showConfirmDlg, dispatch),
        hideModal: bindActionCreators(hideModal, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationGrid);