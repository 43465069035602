import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getVersion } from '../../../actions/commonAction';
import './footer.css';
import evs_logo from '../../../assets/images/evs_small.png';

class Footer extends Component {

    componentWillMount() {
        this.props.getVersion();
    }


    render() {
        return (
            <footer className="footer">
                <div>
                    <p>EVS Broadcast Equipment All Rights Reserved {new Date().getFullYear()}</p>
                    <a className="term-of-use" rel="noopener noreferrer" href={window.location.origin + "/terms"} target="_blank">XtraMotion &amp; C-NEXT terms of use</a>
                    <div className="right-info">
                        <span className="label label-default">{this.props.currentVersion}</span>
                        <img id="evs_logo_small" src={evs_logo} alt="evs_logo" />
                    </div>
                </div>
            </footer>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentVersion: state.common.backendVersion.version
    };
}

export default connect(mapStateToProps, {getVersion})(Footer);