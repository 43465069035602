import _ from 'lodash';
import { createReducer} from './../../utils/reduxHelper';
import * as types from '../../actions/actionTypes';
import { createSelector } from 'reselect';

const initialState = {
    accounts : null,
    currentAccount : null,
    cloudAccountForm: null,
    authKeys: null
};

export const selectAuthKeyFromUrl = createSelector([
    (state, props) => state.account.authKeys,
    (state, props) => props.match.params.api_key],
    (authKeys, authKey) => authKeys === null ? null : authKeys.find(x => x.apiKey === authKey));

const getUserAccountsSucceeded = (state, action) => {
    return {
        ...state,
        accounts: _.orderBy(action.payload, [account => account.displayName.toLowerCase()])
    }
}

const getCloudAccountFormById = (state, action) => {
    return {
        ...state,
        cloudAccountForm: action.payload
    }
}

const setCurrentUserAccountSucceeded = (state, action) => {
    return {
        ...state,
        currentAccount : state.accounts.find(account => account.id === action.payload),
    }
}

const resetCloudAccountPage = (state, action) => {
    return {
        ...state,
        cloudAccountForm: null,
    }
}

const getAuthKeys = (state, action) => {
    return {
        ...state,
        authKeys: _.orderBy(action.payload,[keys => keys.active, keys => keys.name.toLowerCase()], ["desc", "asc"])
    }
}

const accountPageReducer = createReducer(initialState, {
    [types.REQUEST_GET_USER_ACCOUNTS_SUCCEEDED]: getUserAccountsSucceeded,
    [types.REQUEST_SET_CURRENT_USER_ACCOUNT_SUCCEEDED]: setCurrentUserAccountSucceeded,
    [types.REQUEST_GET_CLOUD_ACCOUNT_FORM_SUCCEEDED]: getCloudAccountFormById,
    [types.RESET_CLOUD_ACCOUNT_PAGE]: resetCloudAccountPage,
    [types.REQUEST_GET_AUTH_KEYS_SUCCEEDED]: getAuthKeys
})

export default accountPageReducer;