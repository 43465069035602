import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { toastr } from 'react-redux-toastr';
import AgentItem from './AgentItem';
import { showConfirmDlg, hideModal } from '../../actions/modalActions';
import { unlinkAgent } from './../../actions/agentActions';
import { injectIntl } from 'react-intl';

class AgentList extends Component {

    constructor(props) {
        super(props);
        this.state = {url: this.props.isAdmin ? '/app/admin/agents' : '/app/agents'}
    }

    handleClickItem(event, agent) {
        event.preventDefault();
        this.props.history.push(`${this.state.url}/${agent.uuid}`);
    }

    handleUnlinkAgent(event, agent) {
        let { intl } = this.props;
        event.preventDefault();
        event.stopPropagation();
        document.activeElement.blur();
        this.props.showConfirmDlg({
            title: intl.formatMessage({ id: 'agentsPage.confirmDlg.title' }),
            htmlBody: intl.formatHTMLMessage({ id: 'agentsPage.confirmDlg.text' }, { agentName: agent.name }),
            async: true,
            onConfirm: () => {
                return this.props.unlinkAgent(agent.uuid).then(() => {
                    this.props.history.push(this.state.url);
                    toastr.success(intl.formatMessage({ id: 'common.toastr.success' }), intl.formatMessage({ id: 'agentsPage.toastrAgentUnlinkSucceed' }, { agentName: agent.name }));
                });
            },
            onClose: () => this.props.hideModal() //nothing to do
        });
    }

    handleSendEmail(event, agent) {
        event.preventDefault();
        event.stopPropagation();
        window.location.href = this.props.intl.formatMessage({ id: 'agentsPage.sendEmailTitle' }, {
            owner: agent.owner,
            name: agent.name,
            hostname: agent.hostname,
            accountName: this.props.account.displayName
        });
    }

    render() {
        return (
            <div className="flex-list-container">
                <div className="list-group agent-list">
                    <Scrollbars ref="scrollbars" >
                        {this.props.agents.map(item =>
                            <AgentItem key={item.uuid}
                                rights={this.props.userRights}
                                isActive={this.props.match.params.agent_id === item.uuid}
                                onClick={(event) => this.handleClickItem(event, item)}
                                onUnlink={(event) => this.handleUnlinkAgent(event, item)}
                                onSendEmail={(event) => this.handleSendEmail(event, item)}
                                item={item}
                                isAdmin={this.props.isAdmin}
                            />
                        )}
                    </Scrollbars>
                </div>
            </div>
        )
    }
}

AgentList.propTypes = {
    agents: PropTypes.array.isRequired,
    account: PropTypes.object.isRequired,
    userRights: PropTypes.object.isRequired
}

export default connect(null, { showConfirmDlg, hideModal, unlinkAgent })(injectIntl(AgentList));