import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { initializeUsersPage, resetUsersPage } from '../../actions/userActions';
import UserList from './UserList';
import AddUser from './edit/AddUser';
import EditUser from './edit/EditUser';
import { FormattedHTMLMessage } from 'react-intl';
import axios from 'axios';

class UsersPage extends Component {

    cancelTokenSrc = axios.CancelToken.source();

    componentWillReceiveProps(nextProps) {
        if (nextProps.account.id !== this.props.account.id) {
            this.props.history.push('/app/users');
            this.initialize(nextProps.account.id);
        }
    }

    initialize(accountId) {
        this.props.initializeUsersPage(accountId, this.cancelTokenSrc.token);
    }

    componentDidMount() {
        this.initialize(this.props.account.id);
    }

    componentWillUnmount() {
        this.cancelTokenSrc.cancel();
        this.props.resetUsersPage();
    }

    render() {
        let { account, users } = this.props;

        return (
            <div className="container-fluid container-full-height">
                <h1><FormattedHTMLMessage id="usersPage.title" values={{ accountName: account.displayName }} /></h1>
                {users &&
                    <div className="row content">
                        <div className="col-md-offset-1 col-md-3 col-full-height" >
                            <UserList {...this.props} />
                        </div>
                        <div className="col-md-4">
                            <Route exact path={`/app/users/add`} component={AddUser} />
                            <Route exact path={`/app/users/:user_email/edit`} component={EditUser} />
                        </div>
                    </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        account: state.account.currentAccount,
        users: state.user.users,
        userRights: state.common.userRights,
    };
}

export default connect(mapStateToProps, { initializeUsersPage, resetUsersPage })(UsersPage);