import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import '../../../../assets/css/modal.css';

class ConfirmModal extends Component {

    constructor(props) {
        super(props);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.state = {
            submitInProgress: false
        }
    }

    resetInProgressState() {
        this.props.async && this.setState({submitInProgress: false})
    }

    handleConfirm() {
        this.props.async && this.setState({submitInProgress:true});
        return Promise.resolve(this.props.onConfirm()).then(() => {
            this.props.onClose();
        },() => this.resetInProgressState())
    }
    
    render() {
        let { title, htmlBody, onClose } = this.props;
        let classSubmitBtn = this.state.submitInProgress ? 'fa fa-spinner fa-spin' : 'fa fa-check';
        return (
            <Modal show={true} data-test-id="confirm-modal">
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <i className="fa fa-question-circle"></i>
                    <div className="question" dangerouslySetInnerHTML={{ __html: htmlBody }}></div>
                </Modal.Body>
                <Modal.Footer >
                    <Button
                        data-test-id="btn-submit-modal"
                        bsStyle="primary"
                        onClick={this.handleConfirm} disabled={this.state.submitInProgress}
                    >
                        <i className={classSubmitBtn}></i>Ok
                    </Button>
                    <Button
                        data-test-id="btn-cancel-modal"
                        onClick={onClose}
                        disabled={this.state.submitInProgress}
                    >
                        <i className="fa fa-times"></i>Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }



}

ConfirmModal.propTypes = {
    title: PropTypes.string.isRequired,
    htmlBody: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    async: PropTypes.bool
}

export default ConfirmModal;