import React, { Component } from 'react';
import { connect } from 'react-redux';
import Panel from 'react-bootstrap/lib/Panel';
import UserForm from './UserForm';
import { addUser } from './../../../actions/userActions';
import { toastr } from 'react-redux-toastr';
import { injectIntl } from 'react-intl';

class AddUser extends Component {

    handleAddUser(formValues) {
        let { intl } = this.props;
        return this.props.addUser(this.props.accountId,formValues.email, formValues.role).then(() => {
            this.backToUserPage(formValues.email);
            toastr.success(intl.formatMessage({ id: 'common.toastr.success' }),intl.formatMessage({ id: 'usersPage.toastrUserAddSucceed'},{userEmail:formValues.email}));
        });
    }

    backToUserPage(user_email) {
        this.props.history.push(`/app/users/${user_email}`);
    }

    render() {
        return (
            <Panel>
                <Panel.Heading><h3 className="panel-title">Add User</h3></Panel.Heading>
                <Panel.Body>
                    <UserForm 
                        editMode={false}
                        onSubmit={(values) => this.handleAddUser(values)}
                        onCancel={() => { this.backToUserPage() }} 
                    />
                </Panel.Body>
            </Panel>
        )
    }
}

function mapStateToProps(state) {
    return {
        accountId: state.account.currentAccount.id,
    };
}

export default connect(mapStateToProps, { addUser })(injectIntl(AddUser));