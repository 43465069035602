import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
    edit: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    locationName: PropTypes.string.isRequired,
};

export default class InputText extends Component {

    render() {
        let editMode = this.props.edit;
        let inputClass = classnames('form-control input-sm', {'has-error':this.props.showError});
        let refProperty = {};
        if (this.props.inputRef)
            refProperty.ref = this.props.inputRef; 
        return (
            <div className={this.props.className}>
                {editMode &&
                    <input type={this.props.type}
                        onChange={this.props.onChange}
                        onKeyPress={this.props.onKeyPress}
                        {...refProperty}
                        name={this.props.name}
                        data-test-id={this.props.locationName + "_" + this.props.name}
                        className={inputClass}
                        value={this.props.value} />
                }
                {!editMode && this.props.type !== 'password' && <span data-test-id={this.props.locationName + "_" + this.props.name}>{this.props.value}</span>}
                {!editMode && this.props.type === 'password' && <span data-test-id={this.props.locationName + "_" + this.props.name}>{this.props.value && '*****'}</span>}
            </div>
        )
    }
}

InputText.propTypes = propTypes;