import { createReducer } from './../../utils/reduxHelper';
import { createSelector } from 'reselect';
import * as types from '../../actions/actionTypes';

const initialState = {
    agents: null
};

export const selectAgentFromUrl = createSelector([
    (state, props) => state.agent.agents,
    (state, props) => props.match.params.agent_id],
    (agents, agentId) => agents === null ? null : agents.find(agent => agent.uuid === agentId));


const getAgentsSucceeded = (state, action) => {
    return {
        ...state,
        agents: action.payload
    }
}

const resetAgentPage = () => {
    return initialState;
}

const agentPageReducer = createReducer(initialState, {
    [types.REQUEST_GET_AGENTS_SUCCEEDED]: getAgentsSucceeded,
    [types.RESET_AGENTS_PAGE]: resetAgentPage
})

export default agentPageReducer;