import axiosInstance from './axiosConfig';

export default class LocationApi {

  static getLocationsByAccountId(accountId) {
    return axiosInstance.get(`/accounts/${accountId}/locations`);
  }

  static saveLocation(location, accountId) {
    if (location.id) {
      return axiosInstance.put(`/accounts/${accountId}/locations/${location.id}`, location);
    }
    else {
      return axiosInstance.post(`/accounts/${accountId}/locations`,location);
    }
  }

  static deleteLocation(location, accountId) {
    return axiosInstance.delete(`/accounts/${accountId}/locations/${location.id}`);
  }
};