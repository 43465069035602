import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedHTMLMessage } from "react-intl";
import {
  initializeLocationsConfiguration,
  clearLocations
} from "../../actions/locationActions";
import LocationsGrid from "./locationsGrid/LocationsGrid";
import "../../assets/css/styles.css";

class LocationsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false
    };
  }

  componentWillMount() {
    this.initialize(this.props.account.id);
  }

  initialize = accountId => {
    this.setState({ dataLoaded: false });
    const self = this;
    this.props.initializeLocationsConfiguration(accountId).then(
      () => {
        self.setState({ dataLoaded: true });
      },
      () => {
        self.setState({ dataLoaded: true });
      }
    );
  };

  componentWillUnmount() {
    this.props.clearLocations();
  }

  componentWillReceiveProps(nextProps) {
    //if account change (user account selector) or account refresh (user update its role)
    if (nextProps.account.id !== this.props.account.id) {
      this.props.history.push(this.state.url);
      this.initialize(nextProps.account.id);
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <div>
          <h1>
            <FormattedHTMLMessage
              id="locationsPage.title"
              values={{ accountName: this.props.account.displayName }}
            />
          </h1>
          {this.state.dataLoaded && (
            <div className="row table-container">
              <LocationsGrid />
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.account.currentAccount
  };
}

export default connect(
  mapStateToProps,
  { initializeLocationsConfiguration, clearLocations }
)(LocationsPage);
