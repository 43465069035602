import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import _ from 'lodash';
import {compose} from 'redux';
import {connect} from 'react-redux';
import './cloud-account.css';
import style from "./CloudAccountForm.module.scss"

const tabIds = ['technical', 'commercial'];

class CloudAccountForm extends Component {

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onChangeSubscription: PropTypes.func,
    readOnly: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
  }

  static defaultProps = {
    children: PropTypes.element.isRequired,
  }

  state = {
    currentTabIndex: tabIds[0],
    hasErrorValidation: false
  }

  setHasErrorValidation = value => {
    this.setState({
      hasErrorValidation: value
    });
  }

  render() {
    let {handleSubmit, onSubmit, submitting, onCancel} = this.props;
    let classSubmitBtn = submitting ? 'fa fa-spinner fa-spin' : 'fa fa-check';
    return (
      <form id="cloudAccountForm" name="cloudAccountForm" className={style.container} onSubmit={handleSubmit(onSubmit)}
            autoComplete="off">
        <div className={style.divParent}>
          {this.props.children.map(child => React.cloneElement(child, {setHasErrorValidation: this.setHasErrorValidation, ...this.props}))}
          {!this.props.readOnly && <div className={style.divFooter}>
            <div className="col-sm-offset-8 col-sm-4 text-right">
              <button type="submit" id="btnSave" className={style.btnSave}
                      disabled={this.props.readOnly || submitting || this.state.hasErrorValidation}><i
                className={classSubmitBtn} aria-hidden="true"></i>Save
              </button>
              {onCancel && (
                <button type="button" id="btnCancel" onClick={(e) => {
                  e.preventDefault();
                  onCancel()
                }} disabled={submitting} className="btn btn-default"><i className="fa fa-remove" aria-hidden="true"></i>Cancel
                </button>
              )}
            </div>
          </div>
          }
        </div>
      </form>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    form: props.name,
    children: props.children,
    hasErrorValidation: props.hasErrorValidation
  }
}

function selectInvalidTab(error, type) {
  let property = error[`${type}Contact`];
  if (property && property.email) {
    document.getElementById(`contacts-tab-${type}`).click();
    return true;
  }
  return false;
}

export default compose(
  connect(mapStateToProps),
  reduxForm({
    enableReinitialize: true,
    onSubmitFail: error => {
      let activeElementId = document.querySelector('#contacts li.active a').id;
      let activeTypeStr = activeElementId.substring(activeElementId.lastIndexOf("-") + 1);
      let orderedTabs = activeTypeStr === tabIds[0] ? tabIds : _.reverse(tabIds);
      orderedTabs.some(tabId => {
        return selectInvalidTab(error, tabId)
      });
      return false;
    }
  })
)(CloudAccountForm);
