import React from 'react';
import './errorPage.css';

const AccountNotFoundPage = () =>
<div className="container center">
    <div className="error-container">
        <div className="error-text">
            <h1>Your user was not associated with a C-Next Cloud Account.</h1>
            <p className="error-detail">
                Please contact your administrator for assistance.
            </p>
        </div>
    </div>
</div>

export default AccountNotFoundPage;