import React, { FC, FormEventHandler, useCallback, useEffect, useState } from 'react';
import { Col, ControlLabel, FormGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import * as validation from '../../../utils/formValidationUtils';
import { connect } from 'react-redux';
import { SlotBackend, XtraMotionVersionBackend } from '../../../@types';
import { compose } from 'redux';

type Props = {
  handleSubmit: (form:any) => FormEventHandler<HTMLFormElement> | undefined;
  onSubmit: () => void;
  intl: any;
  xtraMotionVersions: Array<XtraMotionVersionBackend>
  initialValues: SlotBackend
}

const XtraMotionSlotCreationForm:FC<Props> = ({ handleSubmit, onSubmit, intl, xtraMotionVersions, initialValues }) => {

  const [compatibility, setCompatibility] = useState<string>("");
  const [compatibilityColor, setCompatibilityColor] = useState<string>("");

  const updateCompatibility = useCallback(
    (version: string) => {
      const xtraMotionVersion = xtraMotionVersions.find(
        (xv) => xv.version === version
      );
      setCompatibilityColor(xtraMotionVersion ? "" : "text-warning");
      setCompatibility(
        xtraMotionVersion
          ? "Compatible with " + xtraMotionVersion.compatibility
          : intl.formatMessage({
              id: "xtraMotionPage.addSlotForm.noXtraMotionVersionSelected",
            })
      );
    },
    [intl, xtraMotionVersions]
  );

  useEffect(() => {
    updateCompatibility(initialValues?.version ?? "");
  }, [initialValues?.version, updateCompatibility]);

  const validateXtraMotionVersion = (version: string) => {
    if (version === "") {
      return "No XtraMotion version selected";
    }
    return xtraMotionVersions.some((xv) => xv.version === version)
      ? undefined
      : "Unsupported XtraMotion version";
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
      <FormGroup>
        <Col componentClass={ControlLabel} sm={4}>
          {intl.formatMessage({ id: 'xtraMotionPage.addSlotForm.lblFriendlySlotName' })}
        </Col>
        <Col sm={7}>
          <Field className="form-control" name="name" data-test-id="form-slot-name" autoFocus component="input" type="text" validate={[validation.required, validation.minLength(3)]} />
        </Col>
      </FormGroup>
      <FormGroup>
        <Col componentClass={ControlLabel} sm={4}>
          {intl.formatMessage({ id: 'xtraMotionPage.addSlotForm.lblAwsRegion' })}
        </Col>
        <Col sm={7}>
          <Field className="form-control" name="region" data-test-id="form-slot-region" component="select" validate={[validation.required]} disabled={initialValues}>
            <option value="">{intl.formatMessage({ id: 'xtraMotionPage.addSlotForm.selectDefaultOption' })}</option>
            {(window as any).env.awsRegions.map(({ label, value }: { label: string, value: string }) => (
              <option key={value} value={value}>{label} - {value}</option>
            ))}
          </Field>
        </Col>
      </FormGroup>
      <FormGroup>
        <Col componentClass={ControlLabel} sm={4}>
          {intl.formatMessage({ id: 'xtraMotionPage.addSlotForm.lblXtraMotionVersion' })}
        </Col>
        <Col sm={7}>
          <Field className="form-control" name="version" data-test-id="form-slot-version" component="select" validate={[validateXtraMotionVersion]} onChange={(event: React.FormEvent<HTMLInputElement>) => updateCompatibility(event.currentTarget.value)}>
            <option value="">{intl.formatMessage({ id: 'xtraMotionPage.addSlotForm.selectXtraMotionVersion' })}</option>
            {xtraMotionVersions?.map(({ version }) => (
              <option key={version} value={version}>{version}</option>
            ))}
          </Field>
        </Col>
        <Col componentClass={ControlLabel} style={{textAlign: 'left'}} sm={1}>
          <OverlayTrigger placement="top" delayShow={500} overlay={<Tooltip style={{zIndex: 20}} id="tooltip">{compatibility}</Tooltip>}>
            <i style={{marginLeft: '-10px'}} className={`fa fa-question-circle-o ${compatibilityColor}`} aria-hidden="true"></i>          
          </OverlayTrigger>
        </Col>
      </FormGroup>
    </form>
  )
}

export default compose<any>(
  connect((state: any) => ({
    xtraMotionVersions: state.xtramotion.xtraMotionVersions,
  })),
  reduxForm({ form: "XtraMotionSlotCreationForm" })
)(injectIntl(XtraMotionSlotCreationForm));
