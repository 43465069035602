import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AgentActionLinks from './AgentActionLinks';
import './agent.css';
import { FormattedMessage } from 'react-intl';

class AgentItem extends Component {

    static propTypes = {
        item: PropTypes.object.isRequired,
        onUnlink: PropTypes.func.isRequired,
        rights: PropTypes.object.isRequired
    }

    render() {
        let { name, location, latestActivityInFmt, status, linkedAccount, displayName } = this.props.item;
        let classItem = classnames('list-group-item', { "active": this.props.isActive });
        return (
            <div onClick={this.props.onClick} className={classItem} style={{ position: 'relative' }}>
                <div className="media">
                    <div className={`border-status ${status}`}></div>
                    <div className="media-body">
                        <h4>{name}</h4>
                        <h5 className='role'>{location}&nbsp;</h5>
                        {latestActivityInFmt && <h5>Latest activity on {latestActivityInFmt}</h5>}
                        {this.props.isAdmin && <h5>Cloud account: {linkedAccount ? displayName : <FormattedMessage id='agentsPage.form.notLinked'/> }</h5>}
                    </div>
                    <div className="media-right" style={{ verticalAlign: 'top' }}>
                        <AgentActionLinks rights={this.props.rights} item={this.props.item} onUnlink={this.props.onUnlink} onSendEmail={this.props.onSendEmail} />
                    </div>
                </div>
            </div>
        )
    }
}

export default AgentItem;