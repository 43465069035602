import { CancelToken } from "axios";
import { useQuery } from "react-query";
import {
  Balance,
  CreditOrder,
  CreditOrders,
  OperationSummary
} from "../@types";
import creditOrdersApi from "../api/creditOrdersApi";
import OperationsApi from "../api/operationsApi";
import { toastr } from "react-redux-toastr";
import Moment from "moment";

export type CreditsResponse = [CreditOrder[], OperationSummary[], Balance];

const batchCreditsQueries = async (
  accountId: string,
  showFulfilledOrders: boolean,
  operationsLimit?: number,
  cancelToken?: CancelToken
): Promise<CreditsResponse> => {
  const [creditOrders, operations] = await Promise.all<
    CreditOrders,
    OperationSummary[]
  >([
    creditOrdersApi.getCreditOrders(accountId, cancelToken),
    OperationsApi.getOperationsSummary(accountId, operationsLimit, cancelToken)
  ]);

  return [
    (showFulfilledOrders
      ? [...creditOrders.expiredOrders, ...creditOrders.orders]
      : creditOrders.orders.filter(
          (order: CreditOrder) => order.currentBalance > 0
        )).sort((creditOrder1 , creditOrder2) => Moment(creditOrder1.expirationDate).diff( Moment(creditOrder2.expirationDate).toDate())),
    operations,
    { date: creditOrders.date, balance: creditOrders.balance }
  ];
};

export function useCredits(
  enabled: boolean,
  accountId: string,
  showFulfilledOrders: boolean,
  operationsLimit?: number
) {
  return useQuery<CreditsResponse, Error>({
    queryKey: ["credits", accountId, showFulfilledOrders, operationsLimit],
    queryFn: () =>
      batchCreditsQueries(accountId, showFulfilledOrders, operationsLimit),
    enabled,
    refetchOnWindowFocus: false,
    onError: (error: Error) =>
      toastr.error(
        "Failed to retrieve credit orders and operations data",
        error.message
      )
  });
}
