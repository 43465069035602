import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Panel from 'react-bootstrap/lib/Panel';
import { FormattedMessage } from 'react-intl';
import CustomInput from '../common/inputs/CustomInput';
import { selectAgentFromUrl } from './../../reducers/pages/agentPageReducer';

class AgentDetails extends Component {

    render() {
         if (!this.props.initialValues)
             return null;

        return (
            <Panel>
                <Panel.Heading><h3 className="panel-title">{<FormattedMessage id='agentsPage.form.title' />}</h3></Panel.Heading>
                <Panel.Body>
                <form id="agentForm" name="agentForm" className="form-horizontal" autoComplete="off">
                    <div className="col-sm-12">
                        <Field name='name' id="name" type="text" disabled={true} component={CustomInput} label={<FormattedMessage id='agentsPage.form.name' />} />
                        <Field name="location" id="location" type="text" disabled={true} component={CustomInput} label={<FormattedMessage id='agentsPage.form.location' />} />
                        <Field name="owner" id="owner" type="text" disabled={true} component={CustomInput} label={<FormattedMessage id='agentsPage.form.owner' />} />
                        <Field name="hostname" id="hostname" type="text" disabled={true} component={CustomInput} label={<FormattedMessage id='agentsPage.form.hostname' />} />
                        <Field name="uuid" id="uuid" type="text" disabled={true} component={CustomInput} label={<FormattedMessage id='agentsPage.form.uuid' />} />
                        <Field name="serialNumber" id="serialNumber" type="text" disabled={true} component={CustomInput} label={<FormattedMessage id='agentsPage.form.serialNumber' />} />
                        <Field name="version" id="version" type="text" disabled={true} component={CustomInput} label={<FormattedMessage id='agentsPage.form.version' />} />
                        <Field name="expirationDateFmt" id="expirationDateFmt" type="text" disabled={true} component={CustomInput} label={<FormattedMessage id='agentsPage.form.expirationDate' />} />
                        <Field name="latestActivityInFmt" id="latestActivityInFmt" type="text" disabled={true} component={CustomInput} label={<FormattedMessage id='agentsPage.form.lastestConnection' />} />
                        {this.props.isAdmin ? <Field name="displayName" id="displayName" type="text" disabled={true} component={CustomInput} label={<FormattedMessage id='agentsPage.form.linkedAccount' />} /> : null}
                    </div>
                    <div className="col-sm-12 text-right">
                        <button type="button" id="btnCancel" onClick={(e) => { e.preventDefault(); this.props.history.push('/app/agents') }} className="btn btn-default">
                            <i className="fa fa-times" aria-hidden="true"></i>
                            Close
                            </button>
                    </div>
                </form>
                </Panel.Body>
            </Panel>
        );
    }
}

AgentDetails.propTypes = {
    item: PropTypes.object.isRequired,
}

function mapStateToProps(state, props) {
    return {
        initialValues: selectAgentFromUrl(state, props)
    };
}

AgentDetails = reduxForm({
    form: 'agentForm',
    enableReinitialize: true
})(AgentDetails);

export default connect(mapStateToProps, null)(AgentDetails);