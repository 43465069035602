import classNames from 'classnames';
import keycode from 'keycode';
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import RootCloseWrapper from 'react-overlays/lib/RootCloseWrapper';

import {
  bsClass,
  getClassSet,
  prefix,
  splitBsPropsAndOmit
} from 'react-bootstrap/lib/utils/bootstrapUtils';
import createChainedFunction from 'react-bootstrap/lib/utils/createChainedFunction';
import ValidComponentChildren from 'react-bootstrap/lib/utils/ValidComponentChildren';
import Scrollbars from 'react-custom-scrollbars';

const propTypes = {
  open: PropTypes.bool,
  pullRight: PropTypes.bool,
  onClose: PropTypes.func,
  labelledBy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func,
  rootCloseEvent: PropTypes.oneOf(['click', 'mousedown'])
};

const defaultProps = {
  bsRole: 'menu',
  pullRight: false
};

// based on https://github.com/react-bootstrap/react-bootstrap/blob/v0.32.4/src/DropdownMenu.js
class ScrollableDropdownMenu extends React.Component {
  constructor(props) {
    super(props);

    this.handleRootClose = this.handleRootClose.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  getFocusableMenuItems() {
    const node = ReactDOM.findDOMNode(this);
    if (!node) {
      return [];
    }

    return Array.from(node.querySelectorAll('[tabIndex="-1"]'));
  }

  getItemsAndActiveIndex() {
    const items = this.getFocusableMenuItems();
    const activeIndex = items.indexOf(document.activeElement);

    return { items, activeIndex };
  }

  focusNext() {
    const { items, activeIndex } = this.getItemsAndActiveIndex();
    if (items.length === 0) {
      return;
    }

    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    items[nextIndex].focus();
  }

  focusPrevious() {
    const { items, activeIndex } = this.getItemsAndActiveIndex();
    if (items.length === 0) {
      return;
    }

    const prevIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    items[prevIndex].focus();
  }

  handleKeyDown(event) {
    switch (event.keyCode) {
      case keycode.codes.down:
        this.focusNext();
        event.preventDefault();
        break;
      case keycode.codes.up:
        this.focusPrevious();
        event.preventDefault();
        break;
      case keycode.codes.esc:
      case keycode.codes.tab:
        this.props.onClose(event, { source: 'keydown' });
        break;
      default:
    }
  }

  handleRootClose(event) {
    this.props.onClose(event, { source: 'rootClose' });
  }

  renderView(props) {
    return (
      <ul
        className="dropdown-menu scrollableDropdown"
        {...props}
      />
    )
  }

  render() {
    const {
      open,
      pullRight,
      labelledBy,
      onSelect,
      className,
      rootCloseEvent,
      children,
      ...props
    } = this.props;

    const [bsProps] = splitBsPropsAndOmit(props, ['onClose']);

    const classes = {
      ...getClassSet(bsProps),
      [prefix(bsProps, 'right')]: pullRight
    };

    return (
        <RootCloseWrapper
          disabled={!open}
          onRootClose={this.handleRootClose}
          event={rootCloseEvent}
        >
          <div className={classNames(className, classes)}>
            <Scrollbars autoHeight autoHeightMax={400} renderView={this.renderView}>
              {ValidComponentChildren.map(children, child =>
                React.cloneElement(child, {
                  onKeyDown: createChainedFunction(
                    child.props.onKeyDown,
                    this.handleKeyDown
                  ),
                  onSelect: createChainedFunction(child.props.onSelect, onSelect)
                })
              )}
            </Scrollbars>
          </div>
        </RootCloseWrapper>
    );
  }
}

ScrollableDropdownMenu.propTypes = propTypes;
ScrollableDropdownMenu.defaultProps = defaultProps;

export default bsClass('dropdown-menu', ScrollableDropdownMenu);