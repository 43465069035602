import { FC, useCallback, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Alert, Panel, Form, FormGroup, Col, ControlLabel, Button, ProgressBar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import debounce from 'lodash.debounce';
import { startServer, stopServer, updateServerScheduler } from "../../../../actions/xtraMotionActions";
import { showConfirmDlg, hideModal } from '../../../../actions/modalActions';
import Moment from 'moment';
import { UserRights } from '../../../../utils/ability';
import styles from './InstanceStatus.module.scss';
import Can from '../../../common/abilities/Can';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import { InstanceStateBackend, XtraMotionServiceStateBackend, SlotBackend, XtraMotionVersionBackend } from '../../../../@types';
import { clamp } from '../../../../utils/mathUtils';
import UtcToLocalTime from "../../../common/utils/UtcToLocalTime";

const DEFAULT_NB_MINUTES_COUNTDOWN = 5*60; // 5 hours
const MAX_VALUE_COUNTDOWN = 23*60; // 23 hours
const MIN_VALUE_COUNTDOWN = 1*30; // 30 minutes
const DEFAULT_NB_MINUTES_INCREMENT = 30; // 30 minutes

const convertCountDownToUtc = (nbMinutesCountDown:number):Moment.Moment => moment().set({'second': 0, 'millisecond': 0}).add(nbMinutesCountDown,'minutes').utc();
const getCurrentDatetimeUtc = () => moment().set({'second': 0, 'millisecond': 0}).utc();

const ProgressBarStates = {
  [`${InstanceStateBackend.Pending}_${XtraMotionServiceStateBackend.Unknown}`]: {
    value: 25,
    label: 'server starting...',
    style: 'warning',
    animated: true,
  },
  [`${InstanceStateBackend.Running}_${XtraMotionServiceStateBackend.Unknown}`]: {
    value: 50,
    label: 'server started',
    style: 'warning',
    animated: true,
  },
  [`${InstanceStateBackend.Running}_${XtraMotionServiceStateBackend.Stopped}`]: {
    value: 50,
    label: 'server started/service stopped',
    style: 'danger',
    animated: false,
  },
  [`${InstanceStateBackend.Running}_${XtraMotionServiceStateBackend.Starting}`]: {
    value: 75,
    label: 'service starting...',
    style: 'warning',
    animated: true,
  },
  [`${InstanceStateBackend.Pending}_${XtraMotionServiceStateBackend.Starting}`]: {
    value: 75,
    label: 'service starting... (server pending)',
    style: 'warning',
    animated: true,
  },
  [`${InstanceStateBackend.Pending}_${XtraMotionServiceStateBackend.Started}`]: {
    value: 100,
    label: 'service started (server pending)',
    style: 'success',
    animated: false,
  },
  [`${InstanceStateBackend.Running}_${XtraMotionServiceStateBackend.Started}`]: {
    value: 100,
    label: 'service started',
    style: 'success',
    animated: false,
  },
  [`${InstanceStateBackend.Stopping}_${XtraMotionServiceStateBackend.Unknown}`]: {
    value: 50,
    label: 'server stopping...',
    style: 'warning',
    animated: true,
  },
  [`${undefined}_${undefined}`]: {
    value: 100,
    label: 'No server linked',
    style: 'danger',
    animated: false,
  },
  [`${InstanceStateBackend.Unknown}_${XtraMotionServiceStateBackend.Unknown}`]: {
    value: 100,
    label: 'Server linked',
    style: 'danger',
    animated: false,
  },
  [`${InstanceStateBackend.Stopped}_${XtraMotionServiceStateBackend.Unknown}`]: {
    value: 100,
    label: 'server stopped',
    style: 'danger',
    animated: false,
  },
  [`${InstanceStateBackend.Stopping}_${XtraMotionServiceStateBackend.Starting}`]: {
    value: 50,
    label: 'server stopping...',
    style: 'warning',
    animated: true,
  },
  [`${InstanceStateBackend.Stopping}_${XtraMotionServiceStateBackend.Started}`]: {
    value: 50,
    label: 'server stopping...',
    style: 'warning',
    animated: true,
  },
  [`${InstanceStateBackend.Stopped}_${XtraMotionServiceStateBackend.Starting}`]: {
    value: 100,
    label: 'server stopped',
    style: 'danger',
    animated: false,
  },
  [`${InstanceStateBackend.Stopped}_${XtraMotionServiceStateBackend.Started}`]: {
    value: 100,
    label: 'server stopped',
    style: 'danger',
    animated: false,
  },
}

interface Props {
  startServer: (stopDate: Moment.Moment) => void;
  stopServer: () => void;
  updateServerScheduler: (stopDate: string) => void;
  intl: any;
  showConfirmDlg: (parameter: any) => void;
  hideModal: () => void;
  slot: SlotBackend;
  isFetching: boolean;
  xtraMotionVersions: Array<XtraMotionVersionBackend>
}

const InstanceStatus:FC<Props> = ({
  startServer,
  stopServer,
  updateServerScheduler,
  intl,
  showConfirmDlg,
  hideModal,
  isFetching,
  slot : {
    id,
    name,
    instance,
    version,
  },
  xtraMotionVersions,
}) => {

  const [nbMinutesCountDown, setNbMinutesCountDown] = useState<number>(DEFAULT_NB_MINUTES_COUNTDOWN);
  const [dismissUpdateCountDown, setDismissUpdateCountDown] = useState<boolean>(false);
  const [pendingServerRequest, setPendingServerRequest] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const {
    state: instanceStatus,
    startDate: instanceStartDate,
    stopDate: instanceStopDate,
    serviceState: serviceStatus,
    scheduledStopDate: scheduledInstanceStopDate
  } = instance ?? {};

  // useEffect(() => {
  //   updateCountDownFromScheduledStopDate(scheduledInstanceStopDate);
  // }, [scheduledInstanceStopDate]);

  useEffect(() => {
    if (!isFetching)
      updateCountDownFromScheduledStopDate(scheduledInstanceStopDate);
  }, [isFetching]);

  const updateCountDownFromScheduledStopDate = (scheduledInstanceStopDate:string|undefined) => {
    if (scheduledInstanceStopDate && !dismissUpdateCountDown && instanceStatus !== InstanceStateBackend.Stopped && instanceStatus !== undefined) {
      const scheduledStopTimeUtc = moment(scheduledInstanceStopDate).utc();
      const currentTimeUtc = getCurrentDatetimeUtc();

      const duration = scheduledStopTimeUtc.isAfter(currentTimeUtc)
        ? moment.duration(scheduledStopTimeUtc.diff(currentTimeUtc))
        : moment.duration(0);

      setNbMinutesCountDown(duration.asMinutes());
    }
  }

  useEffect(() => {
    if (instanceStatus === InstanceStateBackend.Stopped || instanceStatus == undefined) {
      setNbMinutesCountDown(DEFAULT_NB_MINUTES_COUNTDOWN)
    }
  }, [instanceStatus]);

  const debounceUpdateServerScheduler = useCallback(debounce(async (nextValue:string) => {
    await updateServerScheduler(nextValue);
    queryClient.invalidateQueries('xtramotion');
    setDismissUpdateCountDown(false);
    }, 1000), []);


  const displayStartedSince = () => {
    return (instanceStartDate && !instanceStopDate) ?
        ( <><UtcToLocalTime date={Moment(instanceStartDate).toString()} />{` (${Moment(instanceStartDate).fromNow()})`}</>) :
        '-';
  }

  const displayCountDown = () => {
    const time = moment().startOf('day').minutes(nbMinutesCountDown);
    return (
      <div data-test-id="countdown" style={{display: 'inline-block'}}>
        <span>{time.format('H')}</span>
        <span className={styles.countDownUnit}>h</span>
        <span>{time.format('mm')}</span>
        <span className={styles.countDownUnit}>m</span>
      </div>
    )
  }

  const onClickStartServerBtn = async () => {
    try {
      setPendingServerRequest(true);
      await startServer(convertCountDownToUtc(nbMinutesCountDown));
    }
    finally {
      setPendingServerRequest(false);
    }
  }

  const onClickStopServerBtn = () => {
    showConfirmDlg({
        title: intl.formatMessage({ id: 'xtraMotionPage.confirmStopServerDlg.title' }),
        htmlBody: '<p>' + intl.formatMessage({ id: 'xtraMotionPage.confirmStopServerDlg.message' }) + '</p>',
        async: true,
        onConfirm: async () => {
          await stopServer();
        },
        onClose: () => hideModal()
    })
  }

  const onClickUpdateCountDown = (incNbMinutes:(1 | -1)) => {
    setDismissUpdateCountDown(true);
    let inc = 0;
    switch (incNbMinutes) {
      case 1: {
        inc = DEFAULT_NB_MINUTES_INCREMENT - (nbMinutesCountDown % DEFAULT_NB_MINUTES_INCREMENT);
        break;
      }
      case -1: {
        inc = -((nbMinutesCountDown % DEFAULT_NB_MINUTES_INCREMENT) || DEFAULT_NB_MINUTES_INCREMENT);
        break;
      }
    }

    const updatedCountDownValue = clamp(nbMinutesCountDown + inc,MIN_VALUE_COUNTDOWN, MAX_VALUE_COUNTDOWN);
    if (updatedCountDownValue !== nbMinutesCountDown) {
      setNbMinutesCountDown(updatedCountDownValue);
      if (instanceStatus === InstanceStateBackend.Running || instanceStatus === InstanceStateBackend.Pending) {
        debounceUpdateServerScheduler(convertCountDownToUtc(updatedCountDownValue));
      } else {
        setDismissUpdateCountDown(false);
      }
    } else {
      setDismissUpdateCountDown(false);
    }
  }

  const supportedVersion = xtraMotionVersions.some(xv => xv.version === version);

  const progressbarValues = ProgressBarStates[
    `${instanceStatus}_${serviceStatus}`
  ] ?? {
    value: 100,
    label: "Invalid state. Contact support!",
    style: "danger",
    animated: true,
  };

  return (
    <div data-test-id="panel-instance-status" className={styles.root}>
      <h3><span className="text-info">{name}</span></h3>
      <Panel>
      <Panel.Body>
        <Form horizontal>
          <FormGroup>
            <Col componentClass={ControlLabel} sm={3}>
              <FormattedMessage id='xtraMotionPage.instanceStatusPanel.lblStatus' />
            </Col>
            <Col sm={6} className="control-label">
              <ProgressBar active={progressbarValues.animated} bsStyle={progressbarValues.style} now={progressbarValues.value} label={<div className="progress-bar-title">{progressbarValues.label}</div>} />
            </Col>
          </FormGroup>
          <FormGroup>
              <Col componentClass={ControlLabel} sm={3}>
                <FormattedMessage id='xtraMotionPage.instanceStatusPanel.lblStartedSince' />
              </Col>
              <Col sm={8}>
                  <ControlLabel type="text"><span data-test-id="startedSinceValue">{displayStartedSince()}</span></ControlLabel>
              </Col>
          </FormGroup>
          <FormGroup>
              <Col componentClass={ControlLabel} sm={3}>
                <FormattedMessage id='xtraMotionPage.instanceStatusPanel.lblSchedulerStop' />
              </Col>
              <Col sm={9} style={{display: 'flex',alignItems: 'baseline'}}>
                  { instanceStatus !== InstanceStateBackend.Stopping ? (
                  <>
                    <ControlLabel type="text" style={{fontSize: '20px',lineHeight: '20px',padding: 0}}>{displayCountDown()}</ControlLabel>
                    <Can I={UserRights.StartStopXmo}>
                      <span style={{margin: '0 7px',position: 'relative',top: '-2px'}}>
                      <OverlayTrigger placement="top" delayShow={500} overlay={<Tooltip id="tooltip">-{DEFAULT_NB_MINUTES_INCREMENT}m</Tooltip>}>
                          <Button
                            data-test-id="btnDecCountdown"
                            bsSize="xsmall"
                            className={styles.customBtn}
                            onClick={() => onClickUpdateCountDown(-1)}>
                            <i className="fa fa-minus no-text"/>
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" delayShow={500} overlay={<Tooltip id="tooltip">+{DEFAULT_NB_MINUTES_INCREMENT}m</Tooltip>}>
                          <Button
                            data-test-id="btnIncCountdown"
                            bsSize="xsmall"
                            className={styles.customBtn}
                            style={{marginLeft: 0}}
                            onClick={() => onClickUpdateCountDown(1)}>
                            <i className="fa fa-plus no-text"/>
                          </Button>
                        </OverlayTrigger>
                      </span>
                    </Can>
                    <ControlLabel data-test-id="fmtScheduledInstanceStopDate" type="text" className={styles.schedulerTimeLeft}>(at&nbsp;<UtcToLocalTime date={convertCountDownToUtc(nbMinutesCountDown).toString()} />)</ControlLabel>
                  </>)
                  : <ControlLabel type="text">-</ControlLabel>
                }
              </Col>
          </FormGroup>
          <Can I={UserRights.StartStopXmo}>
            {!supportedVersion &&
              <Alert bsStyle="danger" style={{marginBottom: '0px'}}>
                <i className="fa fa-info-circle" style={{ marginRight: '7px' }}></i>
                <span>{`${intl.formatMessage({ id: 'xtraMotionPage.slotItem.unsupportedXtraMotionVersion' })} (${version}).`}</span>
              </Alert>
            }
            <div style={{textAlign: 'right'}}>
              {supportedVersion && (instanceStatus === InstanceStateBackend.Pending || (instanceStatus === undefined || instanceStatus === InstanceStateBackend.Stopped)) &&
              <Button
                data-test-id="btnInstanceAction"
                bsStyle="info"
                disabled={instanceStatus === InstanceStateBackend.Pending || pendingServerRequest}
                onClick={onClickStartServerBtn}>
                {!pendingServerRequest && <i className="fa fa-play"></i>}
                {pendingServerRequest && <i className="fa fa-spinner fa-spin"></i>}
                <FormattedMessage id="xtraMotionPage.startServer" />
              </Button>
              }
              {(instanceStatus === InstanceStateBackend.Running || instanceStatus === InstanceStateBackend.Stopping) && <Button
                data-test-id="btnInstanceAction"
                bsStyle="info"
                disabled={instanceStatus === InstanceStateBackend.Stopping}
                onClick={onClickStopServerBtn}>
                <i className="fa fa-stop"></i>
                <FormattedMessage id="xtraMotionPage.stopServer" />
              </Button>}
            </div>
          </Can>
        </Form>
      </Panel.Body>
    </Panel>
    </div>
  )
}

export default connect(
  (state: any) => ({
    xtraMotionVersions: state.xtramotion.xtraMotionVersions,
  }),
  {
    startServer,
    stopServer,
    updateServerScheduler,
    showConfirmDlg,
    hideModal,
  }
)(injectIntl(InstanceStatus));