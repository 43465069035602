import { FC, useState } from "react";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import "../../../../assets/css/modal.css";
import { connect } from "react-redux";
import { isInvalid, isSubmitting, submit } from "redux-form";
import XtraMotionSlotCreationForm from "../../../xtraMotion/forms/XtraMotionSlotCreationForm";
import { toastr } from "react-redux-toastr";
import { injectIntl } from "react-intl";
import { getErrorDescription } from "../../../../api/axiosConfig";
import { SlotBackend } from "../../../../@types";

type Props = {
  title: string;
  slot: SlotBackend;
  submit: (model: any) => void;
  submitting: boolean;
  isInvalid: boolean;
  intl: any;
  onClose: () => void;
  onSubmit: (values: any) => void;
};

const UpdateXtraMotionSlotModal: FC<Props> = ({
  submit,
  submitting,
  isInvalid,
  intl,
  onClose,
  onSubmit,
  title,
  slot,
}) => {
  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);

  const handleSubmit = async (values: any) => {
    setSubmitInProgress(true);
    try {
      await onSubmit(values);
      setSubmitInProgress(false);
      onClose();
      toastr.success(
        intl.formatMessage({
          id: "xtraMotionPage.EditSlotDlg.updateSlotSucceed",
        })
      );
    } catch (ex) {
      setSubmitInProgress(false);
      toastr.error(
        intl.formatMessage({
          id: "xtraMotionPage.EditSlotDlg.updateSlotFailed",
        }),
        getErrorDescription(ex)
      );
    }
  };

  let classSubmitBtn = submitInProgress
    ? "fa fa-spinner fa-spin"
    : "fa fa-check";
  return (
    <Modal show={true} data-test-id="modal-add-slot">
      <Modal.Header closeButton onHide={onClose}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <XtraMotionSlotCreationForm
          initialValues={slot}
          onSubmit={handleSubmit}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-test-id="btn-submit-modal"
          bsStyle="primary"
          onClick={() => submit("XtraMotionSlotCreationForm")}
          disabled={submitting || isInvalid}
        >
          <i className={classSubmitBtn}></i>
          {intl.formatMessage({
            id: "common.button.save",
          })}
        </Button>
        <Button onClick={onClose} data-test-id="btn-cancel-modal">
          <i className="fa fa-times"></i>
          {intl.formatMessage({
            id: "common.button.cancel",
          })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default connect(
  (state) => {
    return {
      submitting: isSubmitting("XtraMotionSlotCreationForm")(state),
      isInvalid: isInvalid("XtraMotionSlotCreationForm")(state),
    };
  },
  { submit }
)(injectIntl(UpdateXtraMotionSlotModal));
