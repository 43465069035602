import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import { toastr } from 'react-redux-toastr';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import UserItem from './UserItem';
import { deleteUser, refreshUserSession } from './../../actions/userActions';
import { showConfirmDlg, hideModal } from '../../actions/modalActions';

class UserList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addNewUser: false
        }
    }

    handleClickItem(event, user) {
        event.preventDefault();
        this.props.history.push(`/app/users/${user.email}`);
    }

    handleEditUser(event, user) {
        event.preventDefault();
        event.stopPropagation();
        this.props.history.push(`/app/users/${user.email}/edit`);
    }

    componentDidMount() {
        setTimeout(() => this.refs.scrollbars.forceUpdate(), 0);
    }

    handleDeleteUser(event, user) {
        //fix remove focus on click
        let { intl } = this.props;
        document.activeElement.blur();
        this.props.showConfirmDlg({
            title: <FormattedMessage id="usersPage.confirmDlg.title" />,
            htmlBody: '<p>' + intl.formatMessage({ id: 'usersPage.confirmDlg.text' }, { userEmail: user.email }) + '</p>',
            async: true,
            onConfirm: () => {
                let isCurrentUser = this.props.currentUserEmail === user.email;
                let promiseDeleteUser = this.props.deleteUser(this.props.account.id, user.email);
                let promiseRefreshUserSession = isCurrentUser ? promiseDeleteUser.then(result => {
                    return this.props.refreshUserSession().then(() => { }, error => {
                        this.props.history.push(error === 'no_account' ? '/accountnotfound' : '/app/users')
                    });
                }) : promiseDeleteUser.then(() => this.props.history.push('/app/users'));

                return promiseRefreshUserSession.then(() => {
                    toastr.success(
                        intl.formatMessage({ id: 'common.toastr.success' }),
                        intl.formatMessage({ id: 'usersPage.toastrUserDelSucceed' }, { userEmail: user.email })
                    )
                });
            },
            onClose: () => this.props.hideModal() //nothing to do
        });
    }

    render() {
        return (

            <div className="flex-list-container">
                <div style={{ height: '35px', maxHeight: '35px', padding: '15px' }}>
                    {this.props.userRights && this.props.userRights.addUser &&
                        <OverlayTrigger placement="top" delayShow={500} overlay={<Tooltip id="tooltip">Add User</Tooltip>}>
                            <Link to={`/app/users/add`} style={{ float: 'right' }} className="btn btn-default btn-add-location" disabled={this.state.addNewUser}>
                                <i className="fa fa-plus no-text" />
                            </Link>
                        </OverlayTrigger>
                    }
                </div>
                <div className="list-group">
                    <Scrollbars ref="scrollbars" >
                        {this.props.users.map((item, index) =>
                            <UserItem key={index}
                                onClick={(event) => this.handleClickItem(event, item)}
                                isActive={this.props.match.params.user_email === item.email}
                                item={item}
                                rights={this.props.userRights}
                                account={this.props.account}
                                onEdit={(event) => this.handleEditUser(event, item)}
                                onDelete={(event) => this.handleDeleteUser(event, item)}
                            />
                        )}
                    </Scrollbars >
                </div>

            </div>
        )
    }
}

UserList.propTypes = {
    users: PropTypes.array.isRequired,
    account: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    userRights: PropTypes.object.isRequired,
    intl: intlShape.isRequired
}

function mapStateToProps(state) {
    return {
        currentUserEmail: state.keycloak.userInfo.email
    };
}

export default connect(mapStateToProps, { deleteUser, showConfirmDlg, hideModal, refreshUserSession })(injectIntl(UserList));