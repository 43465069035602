import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomNavDropdown from './CustomNavDropdown';
import Navbar from 'react-bootstrap/lib/Navbar';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import { setCurrentUserAccount } from '../../../actions/accountActions';
import classnames from 'classnames';

class AccountsDropdown extends Component {

    handleAccountSelection(selectedAccount) {
        this.props.setCurrentUserAccount(this.props.userEmail, selectedAccount.id);
    }

    renderDropdownAccount(currentAccount, accounts, userEmail) {
        return (
            <CustomNavDropdown id="basic-nav-dropdown" title={currentAccount.displayName}>
                {accounts.map(account => {
                    let myClassname = classnames('', {"fa fa-check margin-right": currentAccount.id === account.id});
                    return <MenuItem
                        key={account.id} onSelect={() => this.handleAccountSelection(account)}><i
                        className={myClassname}
                        style={{'minWidth': '14px', 'display': 'inline-block'}}></i>{account.displayName}
                    </MenuItem>;
                })}
            </CustomNavDropdown>
        )
    }

    renderLabelAccount(currentAccount) {
        return (
            <Navbar.Text>
                {currentAccount.displayName}
            </Navbar.Text>
        )
    }

    render() {
        let { currentAccount, accounts, userEmail } = this.props;
        if (!currentAccount || !accounts.length)
            return null;
        return (
            accounts.length === 1 ?
                this.renderLabelAccount(currentAccount) :
                this.renderDropdownAccount(currentAccount, accounts, userEmail)
        )
    }
}

function mapStateToProps(state) {
    return {
        accounts: state.account.accounts,
        currentAccount: state.account.currentAccount,
        userEmail: state.keycloak.userInfo.email
    };
}

export default connect(mapStateToProps, { setCurrentUserAccount })(AccountsDropdown);