import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineReducers } from 'redux';
import keycloakReducer from './keycloakReducers';
import commonReducer from './commonReducer';
import modalReducer from './modalReducer';
import usersPageReducer from './pages/usersPageReducer';
import accountPageReducer from './pages/accountPageReducer';
import agentPageReducer from './pages/agentPageReducer';
import accountsMgmtPageReducer from './pages/accountsMgmtPageReducer';
import spinnerReducer from './spinnerReducer';
import locationsReducer from './pages/locationsPageReducer';
import xtraMotionReducer from './pages/xtraMotionPageReducer'
import { reducer as formReducer } from 'redux-form';

const rootReducer = combineReducers({
    keycloak: keycloakReducer,
    common: commonReducer,
    toastr: toastrReducer,
    modal: modalReducer,
    user: usersPageReducer,
    account: accountPageReducer,
    agent: agentPageReducer,
    form: formReducer,
    accountsMgmtPage: accountsMgmtPageReducer,
    spinner: spinnerReducer,
    location: locationsReducer,
    xtramotion: xtraMotionReducer
});

export default rootReducer;