import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CloudAccountActionLinks from './CloudAccountActionLinks';
import Moment from 'moment';

export default class CloudAccountItem extends Component {

    static propTypes = {
        item: PropTypes.object.isRequired,
        rights: PropTypes.object.isRequired,
        onSendEmail: PropTypes.func.isRequired,
        onManageUsers: PropTypes.func.isRequired,
        onManageAgents: PropTypes.func.isRequired,
        onManageAccount: PropTypes.func.isRequired,
        onDisable: PropTypes.func.isRequired
    }

    static defaultProps = {
        item: {}
    }

    render() {
        let { displayName, linkedAgentsCount, latestAgentActivity, subscription: { expirationDate, maximumLinkedAgent }, status } = this.props.item;
        let classItem = classnames('list-group-item selectable', { "active": this.props.isActive });
        return (
            <div onClick={this.props.onClick} className={classItem} style={{ position: 'relative' }}>
                <div className="media">
                    <div className={`border-status ${status}`}></div>
                    <div className="media-body" style={{ position: 'relative' }}>
                        <h4>{displayName}</h4>
                        <h5 className='role'>Linked Agents: {linkedAgentsCount} out of {maximumLinkedAgent}&nbsp;</h5>
                        {latestAgentActivity && <h5>Latest activity on {Moment(latestAgentActivity).utc().format(window.env.dateFormat)}</h5>}
                        {expirationDate && <h5>Subscription expires on {Moment(expirationDate).utc().format(window.env.dateFormat)}</h5>}
                        <div className="media-right" style={{ verticalAlign: 'top', position: 'absolute', top: '0px', right: '0px' }}>
                            <CloudAccountActionLinks
                                rights={this.props.rights}
                                item={this.props.item}
                                onDisable={this.props.onDisable}
                                onManageAccount={this.props.onManageAccount}
                                onManageAgents={this.props.onManageAgents}
                                onManageUsers={this.props.onManageUsers}
                                onSendEmail={this.props.onSendEmail}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}