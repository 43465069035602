import React from 'react';
import { connect } from 'react-redux';
import './LoginPage.scss';
import login__background from './../../assets/images/login_bg.png';
import { loginToKeycloak } from './../../actions/keycloakActions';
import EvsViaLogo from './../../assets/images/evs-via_login.png';



class LoginPage extends React.Component {

  constructor(props) {
    super(props);
    document.body.classList.add('js-loading');
  }
  
  handleImageLoaded() {
    document.getElementById('root').classList.add('is-ready')
    document.body.classList.remove('js-loading');
  }
  
  render() {
    const { loginToKeycloak } = this.props;
    return (
      <div id="loginpage" className="loginpage">
        <main className='login' role='main'>
        <div className='login__background'>
          <img 
            src={login__background} 
            alt='EVS Login'
            onLoad={this.handleImageLoaded.bind(this)}
            onError={this.handleImageLoaded.bind(this)}
          />
        </div>

        <a href="https://www.evs.com" className="login__logo">
          <svg xmlns="http://www.w3.org/2000/svg" className="logo-evs" viewBox="0 0 431.5 140.8">
            <path className="st0" d="M20.1 20H127v20.2H20.1zM20.1 60.3H127v20.2H20.1zM20.1 100.7H127v20.2H20.1zM411.4 40.2V20H304.5v60.5h86.7v20.2h-86.7v20.1h106.9V60.3h-86.8V40.2zM215.7 99.4L169.9 20h-24.8l58.2 100.8h24.8L286.2 20h-24.7z"/>
          </svg>
        </a>

        <div className="login__wrapper">
          <img src={EvsViaLogo} alt="IPD-Via" />

          <form id="login-form" action="" className="login__form form js-form">
            <button type="button" className="login_btn" onClick={loginToKeycloak}>
              <span className="login_btn__inner">Sign in <i className="evs-icon evs-icon-angle-right"></i></span>
            </button>
          </form>

        </div>

      </main>

      </div>
    )
  }
}

export default connect(null, { loginToKeycloak })(LoginPage);
