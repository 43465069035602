import React, { Component } from 'react';
import { connect } from 'react-redux';
import { initializeSettingsPage } from './../../actions/settingsActions';

class SettingsPage extends Component {

    componentDidMount() {
        this.props.initializeSettingsPage();
    }

    render() {
        return (
            <div className="container-fluid"><h1>Settings</h1></div>
        )
    }
}

export default connect(null, {initializeSettingsPage})(SettingsPage);