export const REQUEST_GET_VERSION_SUCCEEDED = 'REQUEST_GET_VERSION_SUCCEEDED';

export const KEYCLOAK_UPDATE_SUCCEEDED = 'KEYCLOAK_UPDATE_SUCCEEDED';
export const KEYCLOAK_GET_USERINFO_SUCCEEDED = 'KEYCLOAK_GET_USERINFO_SUCCEEDED';

export const REQUEST_GET_USER_ACCOUNTS_SUCCEEDED = 'REQUEST_GET_USER_ACCOUNTS_SUCCEEDED';
export const REQUEST_GET_CURRENT_USER_ACCOUNT_SUCCEEDED = 'REQUEST_GET_CURRENT_USER_ACCOUNT_SUCCEEDED';
export const REQUEST_SET_CURRENT_USER_ACCOUNT_SUCCEEDED = 'REQUEST_SET_CURRENT_USER_ACCOUNT_SUCCEEDED';
export const REQUEST_GET_USERS_SUCCEEDED = 'REQUEST_GET_USERS_SUCCEEDED';
export const REQUEST_POST_USER_SUCCEEDED = 'REQUEST_POST_USER_SUCCEEDED';
export const REQUEST_DELETE_USER_SUCCEEDED = 'REQUEST_DELETE_USER_SUCCEEDED';
export const REQUEST_UPDATE_USER_SUCCEEDED = 'REQUEST_UPDATE_USER_SUCCEEDED';
export const REQUEST_GET_USER_RIGHTS_SUCCEEDED = 'REQUEST_GET_USER_RIGHTS_SUCCEEDED';

export const REQUEST_GET_CLOUD_ACCOUNT_FORM_SUCCEEDED = 'REQUEST_GET_CLOUD_ACCOUNT_FORM_SUCCEEDED';
export const REQUEST_GET_SUBSCRIPTION_SUCCEEDED = 'REQUEST_GET_SUBSCRIPTION_SUCCEEDED';


export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

// accountMgmtPageReducer
export const REQUEST_GET_ALL_CLOUD_ACCOUNTS_SUCCEEDED = 'REQUEST_GET_ALL_CLOUD_ACCOUNTS_SUCCEEDED';
export const RESET_CLOUD_ACCOUNT_MANAGEMENT_PAGE = 'RESET_CLOUD_ACCOUNT_MANAGEMENT_PAGE';

// agentsPage
export const REQUEST_GET_AGENTS_SUCCEEDED = 'REQUEST_GET_AGENTS_SUCCEEDED';
export const RESET_AGENTS_PAGE = 'RESET_AGENTS_PAGE';
export const REQUEST_COUNT_AGENTS_SUCCEEDED = 'REQUEST_COUNT_AGENTS_SUCCEEDED';

// cloud account page
export const RESET_CLOUD_ACCOUNT_PAGE = 'RESET_CLOUD_ACCOUNT_PAGE';

// users page
export const RESET_USERS_PAGE = 'RESET_USERS_PAGE;'

//spinner
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';

//Authkey page
export const REQUEST_GET_AUTH_KEYS_SUCCEEDED = 'REQUEST_GET_AUTH_KEYS_SUCCEEDED';

//location page
export const REQUEST_GET_PROTOCOLS_SUCCEEDED = 'REQUEST_GET_PROTOCOLS_SUCCEEDED';
export const REQUEST_GET_LOCATIONS = 'REQUEST_GET_LOCATIONS';
export const REQUEST_GET_LOCATIONS_SUCCEEDED = 'REQUEST_GET_LOCATIONS_SUCCEEDED';
export const REQUEST_GET_LOCATIONS_FAILED = 'REQUEST_GET_LOCATIONS_FAILED';
export const ADD_LOCATION = 'ADD_LOCATION';
export const CANCEL_EDIT_LOCATION = 'CANCEL_EDIT_LOCATION';
export const REQUEST_SAVE_LOCATIONS_SUCCEEDED = 'REQUEST_SAVE_LOCATIONS_SUCCEEDED';
export const REQUEST_SAVE_LOCATIONS_FAILED = 'REQUEST_SAVE_LOCATIONS_FAILED';
export const CLEAR_ALL_LOCATIONS = 'CLEAR_ALL_LOCATIONS';
export const REQUEST_DELETE_LOCATION_SUCCEEDED = 'REQUEST_DELETE_LOCATION_SUCCEEDED';
export const TOGGLE_ORDERBY_LOCATION_COLUMN = 'TOGGLE_ORDERBY_LOCATION_COLUMN';

//xtraMotion page
export const SET_SELECTED_SLOT_ID = 'SET_SELECTED_SLOT';
export const REQUEST_GET_XTRAMOTION_VERSIONS_SUCCEEDED = 'REQUEST_GET_XTRAMOTION_VERSIONS_SUCCEEDED';

//casl ability
export const SET_USER_ABILITIES = 'SET_USER_ABILITIES';