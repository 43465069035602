import * as types from './actionTypes';
import AgentsApi from './../api/agentsApi';
import UtilApi from './../api/utilApi';
import Moment from 'moment';
import _ from 'lodash';
import { showSpinner, hideSpinner } from './spinnerActions';

const getAgentsSucceeded = payload => ({
    type: types.REQUEST_GET_AGENTS_SUCCEEDED,
    payload
})

const convertAgent = agent => ({
    ...agent,
    name: agent.name || agent.hostname,
    status: agent.status.toLowerCase(),
    latestActivityInFmt: Moment(agent.latestActivity).utc().format(window.env.dateFormat),
    expirationDateFmt: Moment(agent.expirationDate).utc().format(window.env.dateFormat)
})

//TODO sort in a way which don't need "zzzzzzz"
const convertAgentsResult = agents => _.orderBy(agents.map(agent => convertAgent(agent)), [agent => agent.linkedAccount ? agent.linkedAccount.toLowerCase() : "zzzzzzz", agent => agent.name ? agent.name.toLowerCase() : agent.hostname ? agent.hostname.toLowerCase() : ""]);

export const getAgents = (accountId, cancelToken = null) => async dispatch => {
    try {
        let { data: agents } = await AgentsApi.getAgents(accountId, cancelToken);
        dispatch(getAgentsSucceeded(convertAgentsResult(agents)));
    }
    catch (error) {
        //catch to avoid error in console
    };
}

export const unlinkAgent = uuid => {
    return async function (dispatch) {
        await AgentsApi.unlinkAgent(uuid);
    }
}

export const initializeAgentsPage = (accountId, cancelToken = null) => async dispatch => {
    dispatch(showSpinner());
    //request interval pooling & agents list
    let [poolingIntervalResult, agentsResult] = await Promise.all([
        UtilApi.getParameterByKey('pollingAgentsIntervalAfterXSeconds'),
        AgentsApi.getAgents(accountId, cancelToken)
    ]);
    //update store and return pooling value
    dispatch(getAgentsSucceeded(convertAgentsResult(agentsResult.data)));
    dispatch(hideSpinner());
    return { poolingInterval: parseInt(poolingIntervalResult.data.value * 1000, 10) }; //in millis
}

export const resetAgentsPage = () => ({
    type: types.RESET_AGENTS_PAGE
})