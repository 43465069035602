import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './react-datetime.css';
import Datetime from 'react-datetime';
import Moment from 'moment';
import TetherComponent from 'react-tether';

const isDate = value => (value && /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i.test(value)); 

class PopoverDateFilter extends Component {
    
    static propTypes = {
        selectedItem: PropTypes.string,
        isValidDate: PropTypes.func
    }

    state = {
        selectedValue: Moment(this.props.selectedItem)
    }

    componentWillReceiveProps = nextProps => {
        if (nextProps.selectedItem !== this.props.selectedItem) {
            this.setState({selectedValue: Moment(nextProps.selectedItem)});
        }
    }    

    handleClick = selectedValue => {
        if(!selectedValue){
            this.props.setError('Date cannot be empty');
        }else if(!Moment.isMoment(selectedValue) || !isDate(selectedValue.format('DD-MM-YYYY'))){
            this.props.setError('Date must be valid');
        }else{
            this.props.setError('');
            this.setState({selectedValue});
            this.props.onChange(selectedValue.toISOString());
        }
    }

    isValidDate = date => date.isAfter(Moment()); 

    render() {
        return (         
                <TetherComponent attachment="top left" targetAttachment="bottom left" constraints={[{ to: 'scrollParent', }, { to: 'window', pin: ['bottom'] }]} >
                    <Datetime 
                        closeOnSelect
                        dateFormat='DD-MM-YYYY'
                        timeFormat={false}
                        ref='datecalendar'
                        id="date"
                        className="date-time-picker"
                        value={this.state.selectedValue}
                        onChange={date => this.handleClick(date)}
                        isValidDate={date => this.props.isValidDate(date)} />
                </TetherComponent>
        );
    }
}



export default PopoverDateFilter;