import React, { FC, FormEventHandler } from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {FormattedMessage, injectIntl} from 'react-intl';
import * as validation from '../../../utils/formValidationUtils';
import CustomDatePicker from "../../common/inputs/CustomDatePicker";
import Moment from "moment";
import CustomInputNoError from "../../common/inputs/CustomInputNoError";

export const CreditOrderFormId = 'CreditOrderForm';

type Props = {
  handleSubmit: (form: any) => FormEventHandler<HTMLFormElement> | undefined;
  onSubmit: () => void;
}

const CreditOrderForm: FC<Props> = ({handleSubmit, onSubmit}) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-horizontal" autoComplete="off">
      <fieldset style={{marginTop: '10px'}} key="fieldset-2">
        <div>
          <Field className="form-control" name="id" setHasErrorValidation={()=>{}}
                 data-test-id="form-order-id" autoFocus component={CustomInputNoError} type="text"
                 customProps={{required: true}}
                 label={<FormattedMessage id='cloudAccountPage.form.createOrder.id'/>}
                 validate={[validation.required]}/>
          <Field className="form-control" data-test-id="form-order-credits" setHasErrorValidation={()=>{}}
                 name="credits" component={CustomInputNoError} type="number" customProps={{required: true}}
                 label={<FormattedMessage id='cloudAccountPage.form.createOrder.credits'/>}
                 validate={[validation.required, validation.number, validation.minValue(1)]}/>
        </div>
      </fieldset>
    </form>

  )
}

export default connect()(reduxForm({
  form: CreditOrderFormId,
})(injectIntl(CreditOrderForm)))