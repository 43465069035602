import React, {FC} from 'react';
import {OperationSummary} from "../../../../@types";
import UtcToLocalTime from "../../../common/utils/UtcToLocalTime";

const CreditOperationSummaryRow:FC<OperationSummary>  = ({name, date, consumedCredits}) => (
  <tr>
    <td>{name}</td>
    <td><UtcToLocalTime date={date}/></td>
    <td>{consumedCredits}</td>
  </tr>
)

export default CreditOperationSummaryRow;