import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const propTypes = {
    edit: PropTypes.bool.isRequired,
};

class LocationAction extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            checkInProgress: false
        }
    }
    
    render() {
        let classBtn = 'btn btn-default btn-xs';

        return (
            <div className="flex-table-row-item column-action click-to-expand">
                {!this.props.edit &&
                    <div>
                        <OverlayTrigger placement="top" delayShow={500} overlay={<Tooltip id="tooltip">Edit Location</Tooltip>}>
                            <button className={classBtn} data-test-id={`edit-location-${this.props.index}`} onClick={this.props.onEdit} disabled={this.props.disable} ><i className="fa fa-pencil no-text"></i></button>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" delayShow={500} overlay={<Tooltip id="tooltip">Delete Location</Tooltip>}>
                            <button className={classBtn} data-test-id={`delete-location-${this.props.index}`} onClick={this.props.onDelete} ><i className="fa fa-trash-o no-text"></i></button>
                        </OverlayTrigger>
                    </div>
                }
                {this.props.edit &&
                    <div>
                        <OverlayTrigger placement="top" delayShow={500} overlay={<Tooltip id="tooltip">Save</Tooltip>}>
                            <button className={classBtn} data-test-id={`save-location-${this.props.index}`} onClick={this.props.onSave} ><i className="fa fa-floppy-o no-text"></i></button>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" delayShow={500} overlay={<Tooltip id="tooltip">Cancel</Tooltip>}>
                            <button className={classBtn} data-test-id={`cancel-location-${this.props.index}`} onClick={this.props.onCancel} ><i className="fa fa-remove no-text"></i></button>
                        </OverlayTrigger>
                    </div>
                }

            </div>
        )
    }
}

LocationAction.propTypes = propTypes;

export default LocationAction;