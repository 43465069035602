import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    edit: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired
};

export default class SelectBox extends Component {

    renderSelect() {
        let options = this.props.items.map( item => {return <option key={item.id} value={item.id}>{item.name}</option>})
        return (
            <select 
                name={this.props.name} 
                onChange={this.props.onChange}
                className="form-control input-sm" 
                data-test-id={this.props.locationName + "_" + this.props.name}
                value={this.props.value} >
                {options}
            </select>
        )
    }

    renderName() {
        let item = this.props.items.find(item => item.id === this.props.value);
        return item ? <span data-test-id={this.props.locationName + "_" + this.props.name}>{item.name}</span> : '';
    }

    render() {
        let editMode = this.props.edit;
        return (
            <div className={this.props.className}>
                { editMode && this.renderSelect() }
                { !editMode &&  this.renderName()  }
            </div>
        )
    }

}

SelectBox.propTypes = propTypes;