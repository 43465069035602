import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import CustomInput from '../../../common/inputs/CustomInput';
import CustomDropdown from '../../../common/inputs/CustomDropdown';
import * as formatter from '../../../../utils/formFormatterUtils';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

class FormSectionSubscription extends Component {

    render() {
        return (
            <fieldset>
                <legend><FormattedMessage id='cloudAccountPage.form.legendSubscription' /></legend>
                {this.props.readOnly &&
                    <div>
                        <Field name="subscription.type" id="subscription-type" disabled={true} type="text" component={CustomInput} label={<FormattedMessage id='cloudAccountPage.form.subscriptionType' />} />
                        <Field name="linkedAgentsCount" id="linkedAgentsCount" disabled={true} type="text" component={CustomInput} format={value => this.props.formatMaximumLinkedAgent} label={<FormattedMessage id='cloudAccountPage.form.nbLinkedAgents' />} />
                    </div>
                }
                {!this.props.readOnly &&
                    <div>
                        <Field name="subscription.type" id="subscription-type" type="text" component={CustomDropdown} label={<FormattedMessage id='cloudAccountPage.form.subscriptionType' />} >
                            <option value="Annual">Annual</option>
                        </Field>
                        <Field name="subscription.maximumLinkedAgent" id="maximum-linked-agent" parse={value => parseInt(value, 10)} component={CustomDropdown} label={<FormattedMessage id='cloudAccountPage.form.nbMaxLinkedAgent' />} >
                            <option value="10">10</option>
                            <option value="50">50</option>
                        </Field>
                        <Field name="subscription.expirationDate" format={formatter.formatDate} id="subscription-expirationdate" disabled={true} type="text" component={CustomInput} label={<FormattedMessage id='cloudAccountPage.form.subscriptionExpirationDate' />} />
                    </div>
                }
            </fieldset>
        )
    }
}

FormSectionSubscription.propTypes = {
    readOnly: PropTypes.bool.isRequired
}

FormSectionSubscription.defaultProps = {
    readOnly: false
}

const selector = formValueSelector('cloudAccountDetail');

function mapStateToProps(state) {
    let { linkedAgentsCount, subscription } = selector(state, 'linkedAgentsCount', 'subscription.maximumLinkedAgent');
    let formatMaximumLinkedAgent = (linkedAgentsCount !== undefined && subscription !== undefined) ?
        formatter.formatNbLinkedAccounts(linkedAgentsCount, subscription.maximumLinkedAgent) : '';

    return {
        formatMaximumLinkedAgent
    };
}

export default connect(mapStateToProps, null)(FormSectionSubscription);