import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';
import CustomInput from '../../common/inputs/CustomInput';
import Alert from 'react-bootstrap/lib/Alert';
import CustomDropdown from '../../common/inputs/CustomDropdown';
import * as validation from '../../../utils/formValidationUtils';
import { userRoles } from '../../../actions/userActions';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';

class UserForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            allowUserRoles: _.pickBy(userRoles, function (value, key) {
                return ['administrator', 'operator', 'user'].indexOf(key) > -1;
            })
        }
    }

    state = {
        hasErrorValidation: false
    }

    setHasErrorValidation = value => {
        this.setState({
            hasErrorValidation: value
        });
    }

    componentDidMount() {
        if (this.textInputEmail) {
          this.textInputEmail.getRenderedComponent().setFocus();
        }
    }

    render() {
        let { onSubmit, submitting, handleSubmit, onCancel, editMode, intl } = this.props;
        let classSubmitBtn = submitting ? 'fa fa-spinner fa-spin' : 'fa fa-check';
        let defaultSelectOption = intl.formatMessage({ id: 'usersPage.form.roleSelection' });

        return (
            <form id="userForm" name="userForm" className="form-horizontal" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="col-sm-12">
                    <Alert bsStyle="info">
                        <i className="fa fa-info-circle" style={{ marginRight: '7px' }}></i>
                        <FormattedMessage id="usersPage.formInfo" />
                    </Alert>
                    <div style={{ minHeight: '125px' }}>
                        <Field name="email" ref={ref => this.textInputEmail = ref} withRef id="email" type="text" disabled={editMode} setHasErrorValidation={this.setHasErrorValidation} component={CustomInput} label={<FormattedMessage id="usersPage.form.email" />} placeholder="john.smith@gmail.com" validate={[validation.required, validation.email]} />
                        <Field name="role" component={CustomDropdown} label={<FormattedMessage id="usersPage.form.role" />} validate={[validation.required]} >
                            <option value="">{defaultSelectOption}</option>
                            {Object.keys(this.state.allowUserRoles).map(key => <option key={key} value={key}>{this.state.allowUserRoles[key]}</option>)}
                        </Field>
                    </div>
                    <div className="form-group form-group-footer">
                        <div className="col-sm-12 text-right">
                            <button type="submit" id="btnSave" style={{ marginRight: '7px' }} disabled={submitting || this.state.hasErrorValidation} className="btn btn-primary"><i className={classSubmitBtn} aria-hidden="true"></i>Save</button>
                            <button type="button" id="btnCancel" onClick={(e) => { e.preventDefault(); onCancel() }} disabled={submitting} className="btn btn-default"><i className="fa fa-remove" aria-hidden="true"></i>Cancel</button>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}


UserForm = reduxForm({
    form: 'userForm',
    enableReinitialize: true
})(injectIntl(UserForm));

export default UserForm;