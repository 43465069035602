import * as types from './actionTypes';
import Keycloak from 'keycloak-js';
import store from '../store/configureStore';


const updateKeycloakSucceeded = payload => ({
    type: types.KEYCLOAK_UPDATE_SUCCEEDED,
    payload
})

const getUserInfoSucceeded = payload => ({
    type: types.KEYCLOAK_GET_USERINFO_SUCCEEDED,
    payload
})

export function initKeycloak(params) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            let kc = getState().keycloak.kc;

            if (kc && kc.authenticated)
                resolve(kc.authenticated);
            else {
                kc = Keycloak(window.env.keycloak);
                if (params.redirectUri)
                    kc.redirectUri = `${window.location.origin}${params.redirectUri}`;

                kc.init(params).success(authenticated => {
                    dispatch(updateKeycloakSucceeded(kc));
                    //retrieve user info
                    if (authenticated) {
                        dispatch(getUserInfo()).then(result => {
                             resolve(authenticated);         
                        });
                    }
                    else {
                        resolve(authenticated);
                    }
                   
                }).error(error => {
                    reject(error);
                })
            }
        });

    }
}

export function loginToKeycloak(history) {
    return function (dispatch, getState) {
        getState().keycloak.kc.login().success(authenticated => {
            dispatch(updateKeycloakSucceeded());
        });
    };
}

export function logoutFromKeycloak(redirectUri) {
    return function (dispatch, getState) {
        getState().keycloak.kc.logout({ redirectUri: redirectUri }).success(authenticated => {
            dispatch(updateKeycloakSucceeded());
        });
    }
}

export function redirectToUserAccount() {
    window.location.href = store.getState().keycloak.kc.createAccountUrl();
}

function getUserInfo() {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            getState().keycloak.kc.loadUserInfo()
                .success(userInfo => {
                    dispatch(getUserInfoSucceeded(userInfo));
                    resolve(userInfo);
                })
                .error(error => {
                    reject(error);
                });
        });

    }
}