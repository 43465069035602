import axiosInstance from './axiosConfig';

export default class AccountsApi {

    static getAccounts(cancelToken) {
        return axiosInstance.get(`/accounts`,{cancelToken});
    }

    static saveCloudAccount(accountId,account, action = null) {
        return axiosInstance.put(`/accounts/${accountId}`,account, {action:action });
    }

    static disableAccount(accountId) {
        return axiosInstance.delete(`/accounts/${accountId}`);
    }

    static getAccountById( accountId, cancelToken) {
        return axiosInstance.get(`/accounts/${accountId}`,{cancelToken});
    }

    static updateAccount(account) {
        return axiosInstance.put(`/accounts/${account.id}`,account);
    }

    static saveAccount(account) {
        return axiosInstance.post(`/accounts`,account);
    }

    static getSubscriptionFromAccountId(accountId) {
        return axiosInstance.get(`/accounts/${accountId}/subscription`);
    }
};