import axiosInstance from './axiosConfig';

export default class UsersApi {

    static getUsers(accountId, cancelToken) {
        return axiosInstance.get(`/accounts/${accountId}/users`,{cancelToken});
    }

    static addUser(accountId, email, role) {
        return axiosInstance.post(`/accounts/${accountId}/users`, {
            email: email,
            role: role
        },{
            action:'Add User'
        });
    }

    static updateUser(accountId, email, body, action = null) {
        return axiosInstance.patch(`/accounts/${accountId}/users/${email}`, body,{
            action:action
        });
    }

    static getUserRights(accountId, email) {
        return axiosInstance.get(`/accounts/${accountId}/users/${email}/rights`);
    }

    static deleteUser(accountId, email, action = null) {
        return axiosInstance.delete(`/accounts/${accountId}/users/${email}`,{
            action:action
        });
    }
}

