import * as types from './actionTypes';
import * as modalTypes from './modalTypes';

export function showConfirmDlg(payload) {
    return {
        type: types.SHOW_MODAL,
        modalType: modalTypes.CONFIRM_MODAL,
        payload
    }
}

export function showAboutDlg(payload) {
    return {
        type: types.SHOW_MODAL,
        modalType: modalTypes.ABOUT_MODAL,
        payload
    }
}

export function showXtraMotionSlotDlg(payload) {
    return {
        type: types.SHOW_MODAL,
        modalType: modalTypes.XTRA_MOTION_SLOT_MODAL,
        payload
    }
}

export function showUpdateXtraMotionSlotDlg(payload) {
    return {
        type: types.SHOW_MODAL,
        modalType: modalTypes.UPDATE_XTRA_MOTION_SLOT_MODAL,
        payload
    }
}

export function showCreditOrderDlg(payload) {
    return {
        type: types.SHOW_MODAL,
        modalType: modalTypes.CREDIT_ORDER_MODAL,
        payload
    }
}

export function hideModal() {
    return {
        type: types.HIDE_MODAL
    }
}