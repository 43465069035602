import { createReducer} from './../utils/reduxHelper';
import * as types from '../actions/actionTypes';

const initialState = {
    backendVersion: '',
    userRights: null,
    abilities: null,
}

const getVersionSucceeded = (state, action) => {
    return { ...state, 
        backendVersion: action.payload}
}

const getUserRights = (state, action) => {
    return { ...state, 
        userRights: action.payload}
}

const setUserAbilities = (state, action) => {
    return { ...state, 
        abilities: action.payload}
}

const commonReducer = createReducer(initialState, {
    [types.REQUEST_GET_VERSION_SUCCEEDED]: getVersionSucceeded,
    [types.REQUEST_GET_USER_RIGHTS_SUCCEEDED]: getUserRights,
    [types.SET_USER_ABILITIES]: setUserAbilities,
})

export default commonReducer;