import * as types from './actionTypes';
import UsersApi from './../api/usersApi';
import AccountApi from './../api/accountsApi';
import CreditOrdersApi from './../api/creditOrdersApi';
import axiosInstance from './../api/axiosConfig';
import { showSpinner, hideSpinner } from './spinnerActions';
import {updateCurrentUserAccount} from './userActions';
import { setUserAccountCookie } from '../utils/userAccountUtils';
import { defineRules } from '../utils/ability';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';

export const getAccountsSucceeded = payload => ({
    type: types.REQUEST_GET_USER_ACCOUNTS_SUCCEEDED,
    payload
})

const setCurrentUserAccountSucceeded = payload => ({
    type: types.REQUEST_SET_CURRENT_USER_ACCOUNT_SUCCEEDED,
    payload
})

const getUserRightsSucceeded = payload => ({
    type: types.REQUEST_GET_USER_RIGHTS_SUCCEEDED,
    payload
})

const getCloudAccountFormSucceeded = payload => ({
    type: types.REQUEST_GET_CLOUD_ACCOUNT_FORM_SUCCEEDED,
    payload
})

const getAllCloudAccountsSucceeded = payload => ({
    type: types.REQUEST_GET_ALL_CLOUD_ACCOUNTS_SUCCEEDED,
    payload
})

const setUserAbilities = payload => ({
    type: types.SET_USER_ABILITIES,
    payload
})

export const resetCloudAccountMgmtPage = () => ({
    type: types.RESET_CLOUD_ACCOUNT_MANAGEMENT_PAGE
})

export const setCurrentUserAccount = (userEmail, accountId) => {
    return function (dispatch) {
        //set accountId header to axios default config
        Object.assign(axiosInstance.defaults, { headers: { 'Account-Id': accountId } });
        return UsersApi.getUserRights(accountId, userEmail).then(({ data: rights }) => {
            dispatch(getUserRightsSucceeded(rights));
            dispatch(setUserAbilities(defineRules(rights)));
            setUserAccountCookie(userEmail, accountId);
            dispatch(setCurrentUserAccountSucceeded(accountId));
        });
    }
}

export const saveCloudAccount = (cloudAccount) => {
    return async (dispatch,getState) => {
        const userEmail = getState().keycloak.userInfo.email;
        let updatedCloudAccount = await AccountApi.updateAccount(cloudAccount);
        await dispatch(updateCurrentUserAccount(userEmail));
        dispatch(getCloudAccountFormSucceeded(updatedCloudAccount.data));
    }
}

export const getUserAccounts = () => async dispatch => dispatch(getAccountsSucceeded((await AccountApi.getAccounts()).data));

export const initializeCloudAccountPage = (accountId, cancelToken = null) => {
    return async dispatch => {
        dispatch(showSpinner());
        try {
            let account = (await AccountApi.getAccountById(accountId, cancelToken)).data;
            dispatch(getCloudAccountFormSucceeded(account));
        }
        catch (error) {
            if (!axios.isCancel(error)){
                toastr.error("Init Cloud Account Page failed",error.message)
            }
        }
        finally {
            dispatch(hideSpinner());
        }
    }
}

export const resetCloudAccountPage = () => ({
    type: types.RESET_CLOUD_ACCOUNT_PAGE
})

export const initializeCloudAccountMgmtPage = (cancelToken = null) => {
    return async dispatch => {
        dispatch(showSpinner());
        let accounts = (await AccountApi.getAccounts(cancelToken)).data;
        dispatch(getAllCloudAccountsSucceeded(accounts));
        dispatch(hideSpinner());
    }
}

export function addCreditOrder(creditOrder) {
  return async (dispatch, getState) => {
    const { id:accountId } = getState().account.currentAccount;
    return CreditOrdersApi.addCreditOrder(accountId, creditOrder);
  }
}