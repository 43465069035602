import * as types from './actionTypes';
import UtilApi from '../api/utilApi';
import { toastr } from 'react-redux-toastr';

const getVersionSucceeded = payload => ({
    type: types.REQUEST_GET_VERSION_SUCCEEDED,
    payload
})

export function getVersion() {
    return function (dispatch) {
        return UtilApi.getVersion().then(result => {
            dispatch(getVersionSucceeded(result.data));
        }).catch(error => {
            toastr.error(error.code,error.fullError);
        });
    }
}