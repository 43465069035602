import axiosInstance from './axiosConfig';

export default class AgentsApi {

    static getAgents(accountId, cancelToken) {
        return axiosInstance.get(`/agents`, { params: { account: accountId },cancelToken });
    }

    static unlinkAgent(uuid) {
        return axiosInstance.delete(`/agents/${uuid}/link`)
    }
}