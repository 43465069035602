import React from 'react';
import { connect } from 'react-redux';
import ConfirmModal from './types/ConfirmModal';
import AboutModal from './types/AboutModal';
import XtraMotionSlotModal from './types/XtraMotionSlotModal';
import CreditOrderModal from "./types/CreditOrderModal";
import UpdateXtraMotionSlotModal from './types/UpdateXtraMotionSlotModal';

const MODAL_COMPONENTS = {
    'CONFIRM_MODAL': ConfirmModal,
    'ABOUT_MODAL': AboutModal,
    'XTRA_MOTION_SLOT_MODAL': XtraMotionSlotModal,
    'UPDATE_XTRA_MOTION_SLOT_MODAL': UpdateXtraMotionSlotModal,
    'CREDIT_ORDER_MODAL' : CreditOrderModal,
}

const ModalRoot = ({modalType, payload}) => {
    //return null for init cycle
    if (!modalType)
        return null;
    const SelectedModal = MODAL_COMPONENTS[modalType];
    return <SelectedModal {...payload} />
}

export default connect(state => state.modal)(ModalRoot);