import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { initializeAuthKeysMgmtPage, removeAuthKey } from './../../actions/authKeyAction';
import axios from 'axios';
import { Scrollbars } from 'react-custom-scrollbars';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import AddAuthKey from './add/AddAuthKey';
import { Route } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { showConfirmDlg, hideModal } from '../../actions/modalActions';
import AuthKeyDetail from './AuthKeyDetail';

class AuthKeysMgmtPage extends Component {

    cancelTokenSrc = axios.CancelToken.source();
    
    componentDidMount() {
        this.props.initializeAuthKeysMgmtPage(this.props.account.id, this.cancelTokenSrc.token);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.account.id !== this.props.account.id) {
            this.props.initializeAuthKeysMgmtPage(nextProps.account.id, this.cancelTokenSrc.token);
        }
    }

    componentWillUnmount() {
        this.cancelTokenSrc.cancel();
    }

    handleAddNewAuthKey(event){
        event.preventDefault();
        this.props.history.push(`/app/authkeys/add`);
    }

    handleRemoveAuthKey(event, authKey, name) {
        event.stopPropagation();
        let { intl, account } = this.props;
        //fix remove focus on click
        document.activeElement.blur();
        this.props.showConfirmDlg({
            title: 'Delete Auth Key',
            htmlBody: `<p>Are you sure you want to revoke the auth key '${name}'?</p>`,
            async: true,
            onConfirm: () => {
                let promiseDeleteAuthKey = this.props.removeAuthKey(account.id, authKey);
                let promiseRefreshUserSession = promiseDeleteAuthKey.then(() => this.props.history.push('/app/authkeys'));

                return promiseRefreshUserSession.then(() => {
                    toastr.success(
                        intl.formatMessage({ id: 'common.toastr.success' }),
                        `Auth Key '${name}' revoked`
                    )
                });
            },
            onClose: () => this.props.hideModal() //nothing to do
        });
    }

    handleClickItem(event, authKey) {
        event.preventDefault();
        this.props.history.push(`/app/authkeys/${authKey}`);
    }

    render() {
        return (
        <div className="container-fluid container-full-height">
                <h1><FormattedHTMLMessage id="authKeysMgmtPage.title" values={{ accountName: this.props.account.displayName }} /></h1>
                <div className="row content">
                    <div className="col-md-offset-1 col-md-3 col-full-height" >
                        <div className="flex-list-container">
                            <div style={{ height: '35px', maxHeight: '35px', padding: '15px' }}>
                                <OverlayTrigger key='1' placement="top" delayShow={500} overlay={<Tooltip id="tooltip">Add Auth Key</Tooltip>}>
                                    <button style={{ float: 'right' }} className='btn btn-default btn-add-location' onClick={(event) => this.handleAddNewAuthKey(event)} data-test-id='btn-add'>
                                        <i className="fa fa-plus no-text" />
                                    </button>
                                </OverlayTrigger>
                            </div>
                            {this.props.authKeys !== null && this.props.authKeys.length > 0 &&
                                <div className='list-group'>
                                    <Scrollbars ref="scrollbars" >
                                        {this.props.authKeys.map(item =>
                                            <div key={item.apiKey} className='list-group-item' style={{ position: 'relative' }} onClick={event=> this.handleClickItem(event, item.apiKey)}>
                                                <div className="media">
                                                    <div className={`border-status ${item.active?'online':'offline'}`}></div>
                                                    <div className="media-body">
                                                    {item.name && <h4>{item.name}</h4>}
                                                        <h5>{item.apiKey}&nbsp;</h5>
                                                    </div>
                                                    {item.active &&
                                                    <div className="media-right" style={{verticalAlign:'top'}}>
                                                        <OverlayTrigger key="2" placement="top" delayShow={500} overlay={<Tooltip id="tooltip">Revoke Auth Key</Tooltip>}>
                                                            <button className='btn btn-link user-action' onClick={(event) => this.handleRemoveAuthKey(event, item.apiKey, item.name)} data-test-id='btn-remove'><i className="fa fa-trash-o"></i></button>
                                                        </OverlayTrigger>
                                                    </div>
                                                    }
                                                </div>
                                            </div>                                  
                                        )}
                                    </Scrollbars >
                                </div>
                            }
                        </div>
                    </div>
                <div className="col-md-4">
                    <Route exact path={`/app/authkeys/add`} component={AddAuthKey} />
                    <Route exact path={`/app/authkeys/:api_key`} component={AuthKeyDetail} />
                </div>
            </div>
        </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        account: state.account.currentAccount,
        authKeys: state.account.authKeys,
        userRights: state.common.userRights,
    };
}

export default connect(mapStateToProps, { initializeAuthKeysMgmtPage, removeAuthKey, showConfirmDlg, hideModal })(injectIntl(AuthKeysMgmtPage));
