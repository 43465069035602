import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import CustomInput from './../../common/inputs/CustomInput';
import * as validation from '../../../utils/formValidationUtils';
import { injectIntl } from 'react-intl';

class AuthKeyForm extends Component {

    state = {
        hasErrorValidation: false
    }
    

    setHasErrorValidation = value => {
        this.setState({
            hasErrorValidation: value
        });
    }

    componentDidMount() {
      if (this.textInputEmail) {
        this.textInputEmail.getRenderedComponent().setFocus();
      }
    }

    render() {
        let { onSubmit, submitting, handleSubmit, onCancel } = this.props;
        let classSubmitBtn = submitting ? 'fa fa-spinner fa-spin' : 'fa fa-check';

        return (
            <form id="authKeyForm" name="authKeyForm" className="form-horizontal" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="col-sm-12">
                    <div style={{ minHeight: '125px' }}>
                        <Field name="authKeyName" ref={ref => this.textInputEmail = ref} withRef id="authKeyName" type="text" setHasErrorValidation={this.setHasErrorValidation} component={CustomInput} label='Name' validate={[validation.required, validation.maxLength(32)]} data-test-id='name' />
                    </div>
                    <div className="form-group form-group-footer">
                        <div className="col-sm-12 text-right">
                            <button type="submit" id="btnSave" style={{ marginRight: '7px' }} disabled={submitting || this.state.hasErrorValidation} className="btn btn-primary"><i className={classSubmitBtn} aria-hidden="true"></i>Save</button>
                            <button type="button" id="btnCancel" onClick={(e) => { e.preventDefault(); onCancel() }} disabled={submitting} className="btn btn-default"><i className="fa fa-remove" aria-hidden="true"></i>Cancel</button>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}


AuthKeyForm = reduxForm({
    form: 'authKeyForm',
    enableReinitialize: true
})(injectIntl(AuthKeyForm));

export default AuthKeyForm;