export const required = value => value && value !== -1 ? undefined : 'May not be empty';
export const maxLength = max => value => value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const minLength = min => value => value && value.length < min ? `Must be ${min} characters or more` : undefined;
export const minValue = min => value => value !== undefined && value < min ? `Must be at least ${min}` : undefined;
export const maxValue = max => value => value && value > max ? `Maximum ${max} characters` : undefined;
export const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined;
export const email = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined;
export const noSpecialChars = value => value && /^[A-Za-z0-9- +]+$/i.test(value) ? undefined : 'Special or accentuated characters are not allowed';
export const noSpecialCharsOrEmpty = value => (!value || /^[A-Za-z0-9- +]+$/i.test(value)) ? undefined : 'Special or accentuated characters are not allowed';
export const atLeastOneChar = value => value && /^[0-9]*$/i.test(value) ? 'May not contain only digit' : undefined;
export const atLeastOneCharOrEmpty = value => (value && /^[0-9]*$/i.test(value)) ? 'May not contain only digit': undefined;
export const isDate = value => (value && /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i.test(value)) ? 'Must be a valid date': undefined;