import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showAboutDlg, hideModal } from '../../../actions/modalActions';
import MenuItem from 'react-bootstrap/lib/MenuItem';

class AboutItemMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openAbout: false
        }
    }


openAboutDlg() {
        this.props.showAboutDlg({
            productname: 'EVS | VIA',
            version: this.props.currentVersion,
            onClose: () => this.props.hideModal()
        }); 
    }

     render() {
        return (
            <MenuItem eventKey={3.2} onClick={(e) => this.openAboutDlg()}><i className="fa fa-info-circle"></i>About</MenuItem>
        )
    }
};

function mapStateToProps(state) {
    return {
        currentVersion: state.common.backendVersion.version
    };
}

export default connect(mapStateToProps, {showAboutDlg,hideModal})(AboutItemMenu);