import React from 'react';
import './errorPage.css';
import { connect } from 'react-redux';
import { hideSpinner } from './../../../actions/spinnerActions';

class UnauthorizedPage extends React.Component {
    componentWillMount(){
        this.props.hideSpinner();
    }
    render() {
        return (
            <div>
                <div className="container center">
                    <div className="error-container">
                        <i className="fa fa-ban error-icon" aria-hidden="true"></i>
                        <div className="error-text">
                            <h1>Unauthorized</h1>
                            <p className="error-detail">
                                You're not authorized to view this page.
                                <a href="/app/users" className="error-link">
                                    <i className="fa fa-arrow-circle-right" aria-hidden="true"></i>
                                    Go to users page
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, { hideSpinner })(UnauthorizedPage);