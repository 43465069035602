import Cookies from 'universal-cookie';

export function setUserAccountCookie(userEmail, accountId) {
  const cookies = new Cookies();
  cookies.set(`${userEmail}_selected_account_id`, accountId, { path: '/', maxAge: 60 * 60 * 24 * 10 }); //10 jours
}

export function getUserAccountFromUrl() {
  const { search } = window.location;
  if (!search)
      return null;

  let urlParams = parse_query_string(search.substring(1));
  return urlParams.accountId;
}

function parse_query_string(query) {
  var vars = query.split("&");
  var query_string = {};
  for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      // If first entry with this name
      if (typeof query_string[pair[0]] === "undefined") {
          query_string[pair[0]] = decodeURIComponent(pair[1]);
          // If second entry with this name
      } else if (typeof query_string[pair[0]] === "string") {
          var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
          query_string[pair[0]] = arr;
          // If third or later entry with this name
      } else {
          query_string[pair[0]].push(decodeURIComponent(pair[1]));
      }
  }
  return query_string;
}