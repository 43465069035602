import axiosInstance from './axiosConfig';

export default class AuthKeysApi {

    static getAuthKeys(accountId, cancelToken) {
        return axiosInstance.get(`/apikeys`, { params: { account: accountId },cancelToken });
    }

    static createAuthKey(accountId, name, cancelToken) {
        return axiosInstance.post(`/apikeys`, {'accountId': accountId, 'name': name}, cancelToken);
    }

    static removeAuthKey(authKey, cancelToken) {
        return axiosInstance.delete(`/apikeys/${authKey}`, cancelToken);
    }
}