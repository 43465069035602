import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class CloudAccountActionLinks extends Component {

    static propTypes = {
        item: PropTypes.object.isRequired,
        onDisable: PropTypes.func.isRequired,
        onManageAccount: PropTypes.func.isRequired,
        onManageAgents: PropTypes.func.isRequired,
        onManageUsers: PropTypes.func.isRequired,
        onSendEmail: PropTypes.func.isRequired,
        rights: PropTypes.object.isRequired
    }

    handleDisableOnClick = (event, disableIcon, callback) => {
        if (disableIcon) {
            event.preventDefault();
            event.stopPropagation();
        }
        else
            callback(event);
    }

    render() {
        let disabledTrashIcon = !(this.props.rights.disableCloudAccount);
        let disabledAgentIcon = !(this.props.item.linkedAgentsCount);
        return (
            <div className="container-agent-actions">
                <OverlayTrigger key="1" placement="top" delayShow={500} overlay={<Tooltip id="tooltip">Disable this Cloud Account</Tooltip>}>
                    <button className="btn btn-link user-action" disabled={disabledTrashIcon} onClick={event => this.handleDisableOnClick(event, disabledTrashIcon,this.props.onDisable)} ><i className="fa fa-trash"></i></button>
                </OverlayTrigger>
                <OverlayTrigger key="2" placement="top" delayShow={500} overlay={<Tooltip id="tooltip">Manage Account Information</Tooltip>}>
                    <button className="btn btn-link user-action" onClick={this.props.onManageAccount} ><i className="fa fa-cloud"></i></button>
                </OverlayTrigger>
                {this.props.rights.displayAgentsByCloudAccount &&
                    <OverlayTrigger key="3" placement="top" delayShow={500} overlay={<Tooltip id="tooltip">Manage this Account's Agents</Tooltip>}>
                        <button className="btn btn-link user-action" disabled={disabledAgentIcon} onClick={event => this.handleDisableOnClick(event, disabledAgentIcon,this.props.onManageAgents)} ><i className="fa fa-truck"></i></button>
                    </OverlayTrigger>
                }
                {this.props.rights.displayUsersByCloudAccount &&
                    <OverlayTrigger key="4" placement="top" delayShow={500} overlay={<Tooltip id="tooltip">Manage this Account's Users</Tooltip>}>
                        <button className="btn btn-link user-action" onClick={this.props.onManageUsers} ><i className="fa fa-users"></i></button>
                    </OverlayTrigger>
                }
                <OverlayTrigger key="5" placement="top" delayShow={500} overlay={<Tooltip id="tooltip">Contact the Owner</Tooltip>}>
                    <button className="btn btn-link user-action" onClick={this.props.onSendEmail} ><i className="fa fa-envelope"></i></button>
                </OverlayTrigger>
            </div>
        );
    }
}

export default CloudAccountActionLinks;