import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { initializeAgentsPage, getAgents, resetAgentsPage } from '../../actions/agentActions';
import AgentList from './AgentList';
import AgentDetails from './AgentDetails';
import { FormattedHTMLMessage } from 'react-intl';
import axios from 'axios';

class AgentsPage extends Component {

    constructor(props) {
        super(props);
        let isAdmin = this.props.location.pathname.indexOf('/app/admin') !== -1
        this.state = {
            url: isAdmin ? '/app/admin/agents' : '/app/agents',
            isAdmin
        }
    }

    componentDidMount() {
        this.initialize(this.state.isAdmin ? null : this.props.account.id);
    }

    componentWillReceiveProps(nextProps) {
        //if account change (user account selector) or account refresh (user update its role)
        if (nextProps.account.id !== this.props.account.id) {
            this.cancelTokenSrc.cancel();
            this.props.history.push(this.state.url);
            this.initialize(this.state.isAdmin ? null : nextProps.account.id);
        }
    }

    async initialize(accountId) {
        this.cancelTokenSrc = axios.CancelToken.source();
        let { poolingInterval } = await this.props.initializeAgentsPage(accountId, this.cancelTokenSrc.token);
        this.poolingInterval = poolingInterval;
        this.startPolling();
    }

    startPolling() {
        setTimeout(async () => {
            await this.props.getAgents(this.state.isAdmin ? null : this.props.account.id, this.cancelTokenSrc.token);
            this.startPolling();
        }, this.poolingInterval);
    }

    componentWillUnmount() {
        this.cancelTokenSrc.cancel();
        this.props.resetAgentsPage();
    }

    render() {
        let msgTitle = this.state.isAdmin ? 'admin.agentMgmtPage.title' : 'agentsPage.title';
        return (
            <div className="container-fluid container-full-height">
                <h1><FormattedHTMLMessage id={msgTitle} values={{ accountName: this.props.account.displayName }} /></h1>
                {this.props.agents &&
                    <div className="row content">
                        <div className="col-md-offset-1 col-md-3 col-full-height" >
                            <AgentList {...this.props} isAdmin={this.state.isAdmin} />
                        </div>
                        <div className="col-md-5">
                            <Route exact path={`/app/agents/:agent_id`} render={() => (
                                <AgentDetails {...this.props} isAdmin={false} />
                            )} />
                            <Route exact path={`/app/admin/agents/:agent_id`} render={() => (
                                <AgentDetails {...this.props} isAdmin={true} />
                            )} />
                        </div>
                    </div>
                }
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        agents: state.agent.agents,
        account: state.account.currentAccount,
        userRights: state.common.userRights,
        poolingInterval: state.agent.poolingInterval
    };
}

export default connect(mapStateToProps, { initializeAgentsPage, getAgents, resetAgentsPage })(AgentsPage);