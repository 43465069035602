import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import store from './../store/configureStore';
import createAxiosMocks from './axiosMocks';
import {hideSpinner} from './../actions/spinnerActions';

const axiosInstance = axios.create({
    baseURL: window.env.baseUrl,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
})

//create mocks
createAxiosMocks(axiosInstance);

//add request interceptor
axiosInstance.interceptors.request.use(config => {
    // Do something before request is sent
    let state = store.getState();
    if (state.keycloak && state.keycloak.kc) {
        return new Promise((resolve, reject) => {
            return state.keycloak.kc.updateToken().success(function () {
                config.headers = config.headers || {};
                config.headers.Authorization = 'Bearer ' + state.keycloak.kc.token;
                config.headers.pragma = 'no-cache'
                config.headers["cache-control"] = 'no-cache';
                resolve(config);
            }).error(function () {
                reject('Failed to refresh token');
            });
        });
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});


//add response interceptor
axiosInstance.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    //don't catch cancel request
    if (!axios.isCancel(error) && !(error.config && error.config.byPassCatchError)) {
        let title = error.config && error.config.action ? `${error.config.action} failed` : "Error occured";
        let description = (error.response && error.response.data && error.response.data.description) || error.message
        toastr.error(title, description);
        //remove spinner also
        store.dispatch(hideSpinner());
    }
    return Promise.reject(error);
});

export function getErrorDescription(error) {
    return (error.response && error.response.data && error.response.data.description) || error.message;
}


export default axiosInstance;