import React, {Component} from 'react';
import PropTypes from 'prop-types';

const labelRequired = {
  color: 'red',
  position: 'absolute',
  top: '0',
  right: '4px'
}

class CustomInputNoError extends Component {
  static propTypes = {
    setHasErrorValidation: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.state = {
      input: this.props.input,
    }
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.meta.error !== this.props.meta.error) {
      if(nextProps.meta.error){
        this.props.setHasErrorValidation(true);
      }else{
        this.props.setHasErrorValidation(false);
      }
    }
  }

  setFocus() {
    this.input.focus();
  }

  render(){
    let requiredField = this.props.customProps && this.props.customProps.required;
    const inputType = this.props.type || "text";
    return (
      <div className='form-group'>
        <label className="col-sm-4 control-label" htmlFor="name">
          {this.props.label}
          {requiredField && <span style={labelRequired}>*</span>}
        </label>
        <div className="col-sm-8">
          <input {...this.props.input} {...this.props.customAttrs} type={inputType} ref={node => this.input = node} className="form-control" disabled={this.props.disabled}/>
        </div>
      </div>
    )
  }
}

export default CustomInputNoError;
