import Moment from 'moment';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { createReducer } from './../../utils/reduxHelper';
import * as types from '../../actions/actionTypes';

const initialState = {
    cloudAccountByIds: {},
};

const createDefaultAccount = () => ({
    id: '',
    displayName: '',
    owner: '',
    subscription: {
        type: 'annual',
        maximumLinkedAgent: 10,
        expirationDate: Moment().add(1, 'years').format(),
        features:{
            xmo: {
                isActive: false,
            }
        }
    },
    linkedAgentsCount:0,
    createdOn: Moment().format(),
    latestAgentActivity :'',
    latestUserActivity: '',
    commercialContact: {
        email:''
    },
    technicalContact: {
        email:''
    }
})

export const cloudAccountsSelector = createSelector([state => state.accountsMgmtPage.cloudAccountByIds], cloudAccountByIds => {
    let cloudAccountList = Object.keys(cloudAccountByIds).map(key => cloudAccountByIds[key]);
    return _.orderBy(cloudAccountList, [user => user.displayName.toLowerCase()], ['asc']);
});

export const cloudAccountFromUrl = createSelector([
    (state, props) => state.accountsMgmtPage.cloudAccountByIds,
    (state, props) => props.match.params.cloudaccountid],
    (cloudAccountByIds, cloudAccountId) => cloudAccountId === 'add' ? createDefaultAccount() : cloudAccountByIds[cloudAccountId]);

const getAllCloudAccountsSucceeded = (state, action) => {
    return {
        ...state,
        cloudAccountByIds: action.payload.reduce((previous, current) => { previous[current.id] = current; return previous }, {})
    }
}

const resetAllCloudAccounts = () => {
    return initialState;
}

const accountsMgmtPageReducer = createReducer(initialState, {
    [types.REQUEST_GET_ALL_CLOUD_ACCOUNTS_SUCCEEDED]: getAllCloudAccountsSucceeded,
    [types.RESET_CLOUD_ACCOUNT_MANAGEMENT_PAGE]: resetAllCloudAccounts
})

export default accountsMgmtPageReducer;