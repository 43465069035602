import React, { Component } from 'react';
import { connect } from 'react-redux';
import Panel from 'react-bootstrap/lib/Panel';
import UserForm from './UserForm';
import { toastr } from 'react-redux-toastr';
import { updateUser, refreshUserSession } from './../../../actions/userActions';
import { injectIntl } from 'react-intl';

class EditUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user:null
        }
    }

    initialize(requestedEmail) {
        let user = this.props.selectedUser.find(user => user.email === requestedEmail);
        this.setState({user:user});
    }

    componentWillMount() {
        this.initialize(this.props.match.params.user_email);
    }

    componentWillReceiveProps(nextProps) {
        let requestedEmail = nextProps.match.params.user_email;
        if (requestedEmail !== this.props.match.params.user_email) {
            this.initialize(requestedEmail);
        }
    }
    
    handleEditUser(formValues) {
        let { intl } = this.props;
        let isCurrentUser = (formValues.email === this.props.currentUserEmail);
        let promiseUpdateUser = this.props.updateUser(this.props.accountId,formValues.email, {role:formValues.role},!isCurrentUser,'Update User');
        let promiseRefreshUserSession = isCurrentUser ? promiseUpdateUser.then(() => this.props.refreshUserSession()) : promiseUpdateUser;
        return promiseRefreshUserSession.then(() => {
            this.backToUserPage();
            toastr.success(intl.formatMessage({ id: 'common.toastr.success' }),intl.formatMessage({ id: 'usersPage.toastrUserUpdateSucceed' },{userEmail:formValues.email}));
        })
    }

    backToUserPage() {
        this.props.history.push(`/app/users/${this.props.match.params.user_email}`);
    }

    render() {
        return (
            <Panel>
                <Panel.Heading><h3 className="panel-title">Edit User</h3></Panel.Heading>
                <Panel.Body>
                    <UserForm 
                        initialValues={this.state.user} 
                        editMode={true}
                        onSubmit={(values) => this.handleEditUser(values)}
                        onCancel={() => { this.backToUserPage() }} 
                    />
                </Panel.Body>
            </Panel>
        )
    }
}

function mapStateToProps(state) {
    return {
        accountId: state.account.currentAccount.id,
        selectedUser: state.user.users,
        currentUserEmail: state.keycloak.userInfo.email
    };
}

export default connect(mapStateToProps, { updateUser, refreshUserSession })(injectIntl(EditUser));