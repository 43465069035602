import React, {Component} from 'react';
import PropTypes from 'prop-types';

const labelRequired = {
    color: 'red',
    position: 'absolute',
    top: '0',
    right: '4px'
}

class CustomCheckbox extends Component {
    static propTypes = {
        setHasErrorValidation: PropTypes.func
    }

    componentWillReceiveProps = nextProps => {
        if (nextProps.meta.error !== this.props.meta.error) {
            if(nextProps.meta.error){
                this.props.setHasErrorValidation(true);
            }else{
                this.props.setHasErrorValidation(false);
            }
        }
    }

    render(){
        let requiredField = this.props.customProps && this.props.customProps.required;
        return (
            <div className={this.props.meta.error ? 'form-group has-error has-feedback' : 'form-group'}>
              <label className="col-sm-4 control-label" htmlFor="name">
                  {this.props.label}
                  {requiredField && <span style={labelRequired}>*</span>}
              </label>
              <div className="col-sm-8">
                <div className="checkbox">
                  <label>
                  <input type="checkbox" disabled={this.props.disabled} checked={this.props.input.value} onChange={this.props.input.onChange} />
                  </label>
                  { this.props.meta.error && <span className="help-block">{this.props.meta.error}</span> }
                </div>
              </div>
            </div>
        )
    }
}

export default CustomCheckbox;
