import { createReducer } from './../../utils/reduxHelper';
import _ from 'lodash';
import * as types from '../../actions/actionTypes';
import { createLocation } from './../../utils/locationMapper';

const initialState = {
  fetching: false,
  protocolsById: null,
  needToUpdate:false,
  headers: [
      {
          label: '',
          className: 'column-status',
      },
      {
          label: 'Location Name',
          orderBy: 'asc',
          orderByProperty: 'name',
          className: 'default-column'
      },
      {
          label: 'Transfer Plug-in',
          orderBy: 'none',
          orderByProperty: 'protocol',
          className: 'default-column'
      },
      {
          label: 'Overwrite Policy',
          orderBy: 'none',
          orderByProperty: 'overwritePolicy',
          className: 'default-column'
      },
      {
          label: 'Actions',
          className: 'column-action'
      }
  ],
  rows: []
}

function getProtocolsSucceeded(state, action) {
  let lvProtocols = _.orderBy(action.payload,[protocol => protocol.name.toLowerCase()], 'name')
  const subFolderProp = window.env.subFolderPropertyName;
  lvProtocols = lvProtocols.map(item => {
      item.configuration[subFolderProp] = {
          description: "Destination subfolder under the root of the location endpoint",
          required: false,
          sensitive: false
      };
      return item;
  });
  return {
      ...state,
      labelValueProtocols: _.map(lvProtocols,protocol => ({id: protocol.id,name: protocol.name})),
      defaultSelectedProtocolId: lvProtocols[0].id,
      protocolsById: action.payload.reduce((previous, current) => {
          previous[current.id] = current;
          return previous;
      }, {})
  }
}

function addLocation(state, action) {
  return {
      ...state,
      rows: [createLocation(action.protocolId, state.protocolsById), ...state.rows],      
  }
}

function cancelEditLocation(state, action) {
  let location = state.rows.find(row => row.id === action.locationId);
  if (location) {
      return {
          ...state,
          rows: state.rows.map((row, index) => row.id === location.id ? { ...row, editable: false, expanded: false } : row)          
      }
  }

  return state;
}

function getLocationsRequested(state, action) {
  return {
      ...state,
      fetching: true
  };
}

function getLocationsSucceeded(state, {payload:{items:locations}}) {
  //keep only location with at least one endpoint
  let locationsWithEndpoint = locations.filter(location => location.endpoints && location.endpoints.length > 0);
  const defaultColumnOrderByProperty = 'name';
  const defaultColumnSort = 'asc';
  return {
      ...state,
      fetching: false,
      needToUpdate: false,
      rows: _.orderBy(locationsWithEndpoint.map(location => createLocation(location, state.protocolsById)), [defaultColumnOrderByProperty], [defaultColumnSort]),
      headers: updateHeaderOrder(defaultColumnOrderByProperty, defaultColumnSort, state.headers),
  };
}

function updateHeaderOrder(columnProperty, order, headers) {
  return headers.map(header => {
      if (header.orderBy)
          header.orderBy = (header.orderByProperty === columnProperty ? order : 'none');
      return header;
  })
}

function getLocationsFailed(state, action) {
  return {
      ...state,
      fetching: false
  };
}

const saveLocationSucceeded = (state, {key,location}) => {
  let newRows = state.rows.map((row) => {
      if (row.key === key) {
          return createLocation(location, state.protocolsById);
      } else {
          return row;
      }
  });

  let test = {
      ...state,
      rows: newRows      
  }
  return test;
}

const saveLocationFailed = (state,{error}) => {    
    return {
        ...state,
        needToUpdate: error.errorMsg === 409
    }
}

const clearAllLocations = state => ({
  ...state,
  rows: []
})

const deleteLocationSucceeded = (state, action) => ({
  ...state,
  rows: state.rows.filter(row => row.key !== action.key)  
});

const toggleOrderByLocation = (state, action) => {
  let newState = { ...state };
  let header = newState.headers.find(header => header.label === action.payload);
  let nextOrder = header.orderBy === 'none' ? 'asc' : header.orderBy === 'asc' ? 'desc' : 'asc';
  //update header
  newState.headers = updateHeaderOrder(header.orderByProperty, nextOrder, newState.headers);
  //update order list
  newState.rows = _.orderBy(newState.rows, [header.orderByProperty], [nextOrder]);
  return newState;
}

const locationReducer = createReducer(initialState, {
  [types.REQUEST_GET_PROTOCOLS_SUCCEEDED]: getProtocolsSucceeded,
  [types.REQUEST_GET_LOCATIONS]: getLocationsRequested,
  [types.REQUEST_GET_LOCATIONS_SUCCEEDED]: getLocationsSucceeded,
  [types.REQUEST_GET_LOCATIONS_FAILED]: getLocationsFailed,
  [types.REQUEST_SAVE_LOCATIONS_SUCCEEDED]: saveLocationSucceeded,
  [types.REQUEST_SAVE_LOCATIONS_FAILED]: saveLocationFailed,
  [types.CLEAR_ALL_LOCATIONS]: clearAllLocations,
  [types.TOGGLE_ORDERBY_LOCATION_COLUMN]: toggleOrderByLocation,
  [types.ADD_LOCATION]: addLocation,
  [types.CANCEL_EDIT_LOCATION]: cancelEditLocation,
  [types.REQUEST_DELETE_LOCATION_SUCCEEDED]: deleteLocationSucceeded  
})

export default locationReducer;