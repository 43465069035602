import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { injectIntl, intlShape } from 'react-intl';

class UserActionLinks extends Component {

    static propTypes = {
        rights: PropTypes.object.isRequired,
        item: PropTypes.object.isRequired,
        onEdit: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        intl: intlShape.isRequired
    }

    sendEmail(event) {
        event.preventDefault();
        window.location.href = this.props.intl.formatMessage({ id: 'usersPage.sendEmail.title' }, { userEmail: this.props.item.email });
    }

    sendChangeOwnerEmail() {
        window.location.href = this.props.intl.formatMessage({ id: 'usersPage.sendEmail.titleOwner' }, { accountName: this.props.account.displayName });
    }

    render() {
        let isOwner = this.props.item.role === 'owner';
        let displayEditBtn = isOwner ? this.props.rights.updateOwner : this.props.rights.updateUserRoleExceptOwner;
        let displayRemoveBtn = isOwner ? this.props.rights.removeOwner : this.props.rights.removeUserExceptOwner;
        let changeOwnerRight = isOwner ? this.props.rights.changeOwner : false;
        let classBtn = 'btn btn-link user-action';

        return (
            <div className="container-user-actions">
                {displayRemoveBtn &&
                    <OverlayTrigger key="2" placement="top" delayShow={500} overlay={<Tooltip id="tooltip">Delete User</Tooltip>}>
                        <button className={classBtn} onClick={this.props.onDelete} ><i className="fa fa-trash-o"></i></button>
                    </OverlayTrigger>
                }
                {displayEditBtn &&
                    <OverlayTrigger key="1" placement="top" delayShow={500} overlay={<Tooltip id="tooltip">Edit User</Tooltip>}>
                        <button className={classBtn} onClick={this.props.onEdit} disabled={this.props.disable} ><i className="fa fa-pencil"></i></button>
                    </OverlayTrigger>
                }
                <OverlayTrigger key="0" placement="top" delayShow={500} overlay={<Tooltip id="tooltip">Send Email</Tooltip>}>
                    <button className={classBtn} onClick={(event) => { this.sendEmail(event) }} disabled={this.props.disable} ><i className="fa fa-envelope"></i></button>
                </OverlayTrigger>
                {changeOwnerRight &&
                    <OverlayTrigger key="3" placement="top" delayShow={500} overlay={<Tooltip id="tooltip">Send Email - Change Owner</Tooltip>}>
                        <button className={classBtn} onClick={(event) => { this.sendChangeOwnerEmail(event) }} disabled={this.props.disable} >
                            <div className="change-owner" style={{ marginRight: '10px' }}>
                                <i className="fa fa-envelope"></i>
                                <i className="fa fa-user"></i>
                            </div>
                        </button>
                    </OverlayTrigger>
                }
            </div>
        )
    }
}

export default injectIntl(UserActionLinks);
