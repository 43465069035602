export default {
    'en-US': {
        common: {
            toastr: {
                success: 'Success',
                error: 'Error'
            },
            button: {
                save: "Save",
                cancel: "Cancel"
            }
        },
        locationsPage: {
          title: 'Locations Settings for Cloud Account <b>{accountName}</b>'
        },
        xtraMotionPage: {
            title: 'XtraMotion',
            startServer: 'Start',
            stopServer: 'Stop',
            serviceNotActivated: 'Service not activated for this ',
            confirmStopServerDlg: {
                title: "Stop Server",
                message: "Are you sure you want to stop this server?"
            },
            confirmRemoveServerDlg: {
                title: "Remove Service",
                message: "<p>Are you sure you want to remove '{slotName}'?</p>",
                removeSlotSucceed: "Removed service successfully",
                removeSlotFailed: "Failed to remove service"
            },
            confirmRenewCodeDlg: {
                title: 'Renew connection code',
                message: 'Are you sure you want to renew the connection code for \'{slotName}\'?',
                renewCodeSucceed: 'Renewed connection code successfully',
                renewCodeFailed: 'Failed to renew connection code'
            },
            addSlotDlg: {
                title: "Add Service",
                addSlotSucceed: "Added service successfully",
                addSlotFailed: "Failed to add service"
            },
            EditSlotDlg: {
                title: "Edit Service",
                updateSlotSucceed: "Updated service successfully",
                updateSlotFailed: "Failed to update service"
            },
            slotList: {
                title: 'Your Services ({nbSlots})'
            },
            slotItem: {
                region: 'Region:',
                connectionCode: 'Connection code:',
                renewCode: 'Renew connection code',
                xtraMotionVersion: 'XtraMotion version:',
                unsupportedXtraMotionVersion: 'Unsupported XtraMotion version'
            },
            slotCode: {
                copyToClipboard: 'Copy to clipboard',
                codeCopied: 'Copied: {code}'
            },
            instanceStatusPanel:{
                lblStatus: 'Status',
                lblStartedSince: 'Started since',
                lblSchedulerStop: 'Auto Stop in'
            },
            addSlotForm: {
                lblFriendlySlotName: 'Friendly Service name *',
                lblAwsRegion: 'AWS Region *',
                selectDefaultOption: 'Select a region',
                lblXtraMotionVersion: 'XtraMotion version *',
                selectXtraMotionVersion: 'Select a version',
                noXtraMotionVersionSelected: 'No XtraMotion version selected'
            },
            historicPanel: {
                title: 'History of Operations',
                columns: {
                    instance: 'Service',
                    startDate: 'Start date',
                    stopDate: 'End date',
                    duration: 'Duration',
                    points: 'EVS Credits',
                    startedBy: 'Started by'
                },
                placeholder: "No operation"
            }
        },
        usersPage: {
            title: 'User Management for Cloud Account <b>{accountName}</b>',
            confirmDlg: {
                title: 'Delete User',
                text: 'Are you sure you want to delete the user \'{userEmail}\'?'
            },
            toastrUserDelSucceed: 'User \'{userEmail}\' was deleted',
            toastrUserUpdateSucceed: 'User \'{userEmail}\' was updated',
            toastrUserAddSucceed: 'User \'{userEmail}\' was added',
            formInfo:'The user will be notified by email. Make sure you enter a valid address.',
            sendEmail:{
                title:'mailto:{userEmail}?subject=EVS VIA - EVS',
                titleOwner:'mailto:ServiceDeskEvs@evs.com?subject=EVS VIA - Cloud Account {accountName} - Change Owner Request'
            },
            form:{
                email:'Email',
                emailPlaceholder:'john.smith@gmail.com',
                role:'Role',
                roleSelection:'Select the Role'
            }
        },
        agentsPage: {
            title: 'Agent Management for Cloud Account <b>{accountName}</b>',
            confirmDlg: {
                title: 'Unlink Agent',
                text: '<p>Are you sure to unlink this Connected Agent ({agentName}) ?</p><p>Make sure you also review the list of users allowed to link or unlink agent in order to prevent unwanted agent linking. This can be done by changing user\'s role or revoking user\'s access.</p>'
            },
            toastrAgentUnlinkSucceed: 'Unlink message was sent to \'{agentName}\' agent ',
            sendEmailTitle:'mailto:{owner}?subject=Connected Agent {name} ({hostname}) - Account {accountName}',
            form:{
                title:'Agent Information',
                name:'Name',
                location:'Location',
                owner:'Owner',
                hostname:'Host Name',
                uuid:'UUID',
                serialNumber:'Serial Number',
                version:'Agent S/W Version',
                expirationDate:'Agent Licence Expiration (UTC)',
                lastestConnection: 'Latest Connection (UTC)',
                linkedAccount: 'Cloud Account',
                notLinked: 'Not linked'
            }
        },
        settingsPage: {
            title: 'Settings'
        },
        cloudAccountMgmtPage: {
            sendEmailtoOwner:'mailto:{owner}?subject=EVS VIA - Cloud Account {name}',
            confirmDlg: {
                title: 'Disable Cloud Account',
                text: '<p>Are you sure you want to disable the cloud account \'{accountName}\'?</p>'
            },
            toastrAccountDisableSucceed:'Cloud Account \'{accountName}\' was disabled',
        },
        cloudAccountPage: {
            title: 'Cloud Account',
            sendEmailSubscription:'mailto:support@evs.com?subject=EVS VIA - Cloud Account {name} - Change Subscription',
            sendEmailRequestMoreCredits: 'mailto:ServiceDeskEvs@evs.com?subject=EVS VIA - Cloud Account \'{name}\' - Request more EVS Credits',
            toastrFormSaveSucceed: 'Cloud Account was updated',
            form:{
                legendAccount: 'Cloud Account',
                detailLegendAccount: 'Account Details',
                owner: 'Owner',
                subscriptionType: 'Subscription Type',
                subscriptionExpirationDate: 'Subscription expires on',
                nbLinkedAgents: 'Linked Agents',
                createdOn: 'Created On',
                latestAgentActivity: 'Latest Agent Activity',
                latestUserActivity: 'Latest User Activity',
                accountId: 'Account Id',
                displayName:'Display Name',
                legendSubscription: 'C-Next Subscription Information',
                buttonSubscription:'Request a change to your subscription',
                type:'Type',
                expirationDate:'Expiration Date',
                nbMaxLinkedAgent: 'Maximum Linked Agents',
                technicalContact:'Technical Contact',
                commercialContact:'Commercial Contact',
                email:'Email',
                legendServices: 'On Demand Services',
                sections: {
                    credits:{
                        legend: 'EVS Credits',
                        buttonRequestMoreCredits: 'Request More EVS Credits',
                        currentBalance: 'Current Balance',
                        buttonAddMoreCredits: 'Add EVS Credits'
                    }
                },
                features: {
                    xmo: 'XtraMotion'
                },
                createOrder :{
                  addOrder: 'Add an EVS Credit Order',
                  id : 'Order Id',
                  date: 'Order Date',
                  credits: 'EVS Credits',
                  addCreditOrderSucceed: 'Add EVS Credit Order successful',
                  addCreditOrderFailed: 'Failed to add an EVS Credit Order',
                  title: 'Add an EVS Credit Order'
                }
            },
            operationsSummaryTable:{
                title: "History of EVS Credit Operations",
                columns:{
                    operationName: "Operations",
                    date: "Date",
                    creditsConsumed: "Credits Consumed",
                },
                loadingCreditOperations: "Loading EVS Credit Operations...",
                buttonCsv: "Download CSV",
                showLast: "Last",
                showAll: "Show All",
                noData: "No Operation",
            },
            creditOrdersSummaryTable: {
                title: "History of EVS Credit Orders",
                columns: {
                    order: "Order",
                    date: "Date",
                    expirationDate: "Expiration Date",
                    remainingCredits: "Remaining EVS Credits",
                    usage: "Usage"
                },
                loadingCreditOrders: "Loading EVS Credit Orders...",
                downloadCsv: "Download CSV",
                downloadCsvFailed: "Failed to download report of credit orders.",
                showFulfilledOrders: "Show Fulfilled Orders",
                noData: "No Order",
            }
        },
        authKeysMgmtPage: {
            title: 'Auth Keys Management for Cloud Account <b>{accountName}</b>',
            toastrAuthKeyAddSucceed: 'Auth Key \'{name}\' created',
            form:{
                detailTitle : 'Auth Key Information',
                name: 'Name',
                createdBy: 'Created by',
                createdOn: 'Created on',
                revokedBy: 'Revoked by',
                revokedOn: 'Revoked on',
                authKey: 'Auth key'
            }
        },
        admin: {
            cloudAccountMgmtPage: {
                title: 'Cloud Accounts Management'
            },
            agentMgmtPage: {
                title: 'Agents Management'
            },
            userMgmtPage: {
                title: 'Administrative User Management'
            }
        }
    },
    'fr-FR': {}
}