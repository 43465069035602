import * as types from "./actionTypes";
import XtraMotionApi from "../api/xtraMotionApi";
import {toastr} from 'react-redux-toastr';
import axios from 'axios';

const setSelectedSlotId = payload => ({
    type: types.SET_SELECTED_SLOT_ID,
    payload
});

const getXtraMotionVersionsSucceeded = payload => ({
    type: types.REQUEST_GET_XTRAMOTION_VERSIONS_SUCCEEDED,
    payload
});

export function startServer(scheduledStopDateTime) {
    return (dispatch, getState) => {
        const { id: accountId } = getState().account.currentAccount;
        const { selectedSlotId } = getState().xtramotion;
        return XtraMotionApi.startInstanceForSlot(accountId, selectedSlotId, scheduledStopDateTime)
            .then(() => {
                toastr.success("Server starting...");
            },error => {
                if (error.response.status === 304) {
                    toastr.info("Server already starting..."); 
                } else {
                    toastr.error("Error occured", (error.response && error.response.data && error.response.data.description) || error.message);
                }
            });
    }
}

export function stopServer() {
    return (dispatch, getState) => {
        const { id: accountId } = getState().account.currentAccount;
        const { selectedSlotId } = getState().xtramotion;
        return XtraMotionApi.stopInstanceForSlot(accountId, selectedSlotId)
            .then(() => {
                toastr.success("Server stopping...");
            });
    }
}

export function updateServerScheduler(scheduledStopDateTime) {
    return (dispatch, getState) => {
        const { id: accountId } = getState().account.currentAccount;
        const { selectedSlotId } = getState().xtramotion;
        return XtraMotionApi.updateInstanceStopDateTime(accountId, selectedSlotId, scheduledStopDateTime)
            .then(() => {
                toastr.success("Server stopDate updated...");
            });
    }
}

export function setSelectedSlotById(instanceId) {
    return dispatch => {
        dispatch(setSelectedSlotId(instanceId));
    }
}

export function renewCode(slot) {
    return async (dispatch, getState) => {
        const { id: accountId } = getState().account.currentAccount;
        return await XtraMotionApi.renewCode(accountId, slot.id);
    }
}

export function removeSlot(slot) {
    return async (dispatch, getState) => {
        const { id: accountId } = getState().account.currentAccount;
        const result = await XtraMotionApi.removeSlot(accountId, slot.id);
        dispatch(setSelectedSlotId(null));
        return result;
    }
}

export function addSlot(slot) {
    return async (dispatch, getState) => {
        const { id: accountId } = getState().account.currentAccount;
        const createdSlot = await XtraMotionApi.addSlot(accountId, slot);
        dispatch(setSelectedSlotId(createdSlot.id));
        return createdSlot;
    }
}

export function updateSlot(slotId, slot) {
    return async (dispatch, getState) => {
        const { id: accountId } = getState().account.currentAccount;
        const updatedSlot = await XtraMotionApi.updateSlot(accountId, slotId, slot);
        dispatch(setSelectedSlotId(updatedSlot.id));
        return updatedSlot;
    }
}

export function initializeXtraMotionPage(accountId, cancelToken) {
  return async (dispatch) => {
    try {
      //const { id: accountId } = getState().account.currentAccount;
      const xtraMotionVersions = await XtraMotionApi.getXtraMotionVersions(
        accountId,
        cancelToken
      );
      dispatch(getXtraMotionVersionsSucceeded(xtraMotionVersions.data));
    } catch (error) {
      if (!axios.isCancel(error)) {
        toastr.error("Init XtraMotion Page failed", error.message);
      }
    }
  };
}
