import { AxiosResponse } from "axios";
import React, { FC, useCallback } from "react";
import {
  ToggleButton,
  ToggleButtonGroup
} from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { FormattedMessage } from "react-intl";
import { toastr } from "react-redux-toastr";
import { OperationSummary } from "../../../../@types";
import OperationsApi from "../../../../api/operationsApi";
import { UserRights } from "../../../../utils/ability";
import { saveAs } from "../../../../utils/saveAs";
import Can from "../../../common/abilities/Can";
import styles from "./CreditOperations.module.scss";
import commonStyles from "../CreditPanel.module.scss";
import CreditOperationSummaryRow from "./CreditOperationSummaryRow";
import { QueryStatus } from "react-query";

type Props = {
  accountId: string;
  creditOperations?: OperationSummary[];
  status: QueryStatus;
  operationsLimit?: number;
  setOperationsLimit: (operationsLimit?: number) => void;
};

const filterValues = [undefined, 10, 50];

const CreditOperationsSummaryTable: FC<Props> = ({
  accountId,
  creditOperations: operations,
  status,
  operationsLimit,
  setOperationsLimit
}) => {
  const downloadCsvReport = useCallback(() => {
    OperationsApi.getOperationsCsvReport(accountId)
      .then((response: AxiosResponse) => {
        saveAs(response, "creditOperationsReport.csv");
      })
      .catch((error: Error) => {
        toastr.error(
          "Failed to download report of credit operations.",
          error.message
        );
      });
  }, [accountId]);

  return (
    <div className={styles.root}>
      <fieldset key="order-summary-fieldset">
        <legend className={commonStyles.legendWithBtn}>
          <span><FormattedMessage id='cloudAccountPage.operationsSummaryTable.title' /></span>
          <div className="pull-right">
            <i className="fa fa-filter" style={{marginRight: 8}} aria-hidden="true"></i>
            <ToggleButtonGroup
              name="options"
              value={filterValues.indexOf(operationsLimit)}
              onChange={(index: number) => setOperationsLimit(filterValues[index])}
            >
              {filterValues.map((filter, index) => (
                <ToggleButton key={index} value={index} bsSize="xsmall">
                  <FormattedMessage
                    id={`cloudAccountPage.operationsSummaryTable.${
                      filter === undefined ? "showAll" : "showLast"
                    }`}
                  >
                    {(txt: string) =>
                      `${txt} ${filter === undefined ? "" : filter}`
                    }
                  </FormattedMessage>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
        </legend>
      </fieldset>
      <div className={commonStyles.tableContainer}>
        <div className={commonStyles.innerTableContainer}>
          <Scrollbars>
            <table className={commonStyles.customTable}>
              <thead className={commonStyles.thead}>
                <tr>
                  {["operationName", "date", "creditsConsumed"].map(
                    columnName => (
                      <th key={columnName}>
                        <FormattedMessage
                          id={`cloudAccountPage.operationsSummaryTable.columns.${columnName}`}
                        />
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {status === "loading" && (
                  <h3 className={commonStyles.tablePlaceholder}>
                    <FormattedMessage
                      id={`cloudAccountPage.operationsSummaryTable.loadingCreditOperations`}
                    />
                  </h3>
                )}
                {(status === "error" || (operations && !operations.length)) && (
                  <h3 className={commonStyles.tablePlaceholder}>
                    <FormattedMessage
                      id={`cloudAccountPage.operationsSummaryTable.noData`}
                    />
                  </h3>
                )}
                {operations &&
                  operations.map((operationSummary) => (
                    <CreditOperationSummaryRow
                      key={`${operationSummary.id}`}
                      {...operationSummary}
                    />
                  ))}
              </tbody>
            </table>
          </Scrollbars>
        </div>
      </div>
      <Can I={UserRights.DownloadCreditsReport}>
          <div className={commonStyles.downloadContainer} >
            <button
              className={commonStyles.downloadButton}
              onClick={downloadCsvReport}
            >
              <i className="fa fa-download"/>
              <FormattedMessage id="cloudAccountPage.operationsSummaryTable.buttonCsv" />
            </button>
          </div>
      </Can>
    </div>
  );
};

export default CreditOperationsSummaryTable;
