import axios, { CancelToken } from "axios";
import React, { FC, useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import {
  initializeCloudAccountPage,
  resetCloudAccountPage,
  saveCloudAccount
} from "../../actions/accountActions";
import { updateCurrentUserAccount } from "../../actions/userActions";
import { useCredits } from "../../hooks/useCredits";
import { UserRights } from "../../utils/ability";
import Can from "../common/abilities/Can";
import CloudAccountForm from "../common/forms/CloudAccountForm";
import style from "./CloudAccountPage.module.scss";
import CreditOrdersSummaryTable from "./creditPanel/creditOrders/CreditOrdersSummaryTable";
import FormSectionCredits from "./formSections/FormSectionCredits";
import FormSectionDetails from "./formSections/FormSectionDetails";
import FormSectionServices from "./formSections/FormSectionServices";
import FormSectionSubscription from "./formSections/FormSectionSubscription";
import CreditOperationsSummaryTable from "./creditPanel/creditOperations/CreditOperationsSummaryTable";

interface Props {
  currentAccountId: string;
  canEditForm?: boolean;
  canEditSubscription?: boolean;
  canDisplayCredits: boolean;
  cloudAccountForm: any;
  intl: any; // update react-intl and use the hook useIntl()
  initializeCloudAccountPage: (
    accountId: string,
    cancelToken?: CancelToken
  ) => Promise<void>;
  saveCloudAccount: (cloudAccount: any) => Promise<void>;
  updateCurrentUserAccount: (userEmail: string) => Promise<string>;
  resetCloudAccountPage: () => { type: string };
}

const CloudAccountPage: FC<Props> = (props) => {
  useEffect(() => {
    const cancelTokenSrc = axios.CancelToken.source();
    if (props.currentAccountId) {
      props.initializeCloudAccountPage(
        props.currentAccountId,
        cancelTokenSrc.token
      );
    }
    // cleanup
    return () => {
      cancelTokenSrc.cancel();
      props.resetCloudAccountPage();
    };
  }, [props.currentAccountId]);

  const handleSubmitForm = async (form: any) => {
    let { intl } = props;
    try {
      await props.saveCloudAccount(form);
      toastr.success(
        intl.formatMessage({ id: "common.toastr.success" }),
        intl.formatMessage({ id: "cloudAccountPage.toastrFormSaveSucceed" })
      );
      return Promise.resolve();
    } catch (error) {
      toastr.error("Error", "Update Cloud Account Failed");
      return Promise.reject(error);
    }
  };

  const handleChangeSubscription = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    window.location.href = props.intl.formatMessage(
      { id: "cloudAccountPage.sendEmailSubscription" },
      { name: props.cloudAccountForm.displayName }
    );
  };

  const handleCreditsRequested = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    window.location.href = props.intl.formatMessage(
      { id: "cloudAccountPage.sendEmailRequestMoreCredits" },
      { name: props.cloudAccountForm.displayName }
    );
  };

  const displayForm =
    props.cloudAccountForm !== null && props.canEditForm !== null;

  const [operationsLimit, setOperationsLimit] = useState<number>();
  const [showFulfilledOrders, setShowFulfilledOrders] = useState(false);
  const {
    status,
    data: [creditOrders, creditOperations, balance] = []
  } = useCredits(
    props.canDisplayCredits,
    props.currentAccountId,
    showFulfilledOrders,
    operationsLimit
  );

  return (
    <div className="container-fluid container-full-height d-flex">
      <h1>
        <FormattedMessage id="cloudAccountPage.title" />
      </h1>
      <div className={style.content}>
        {displayForm && (
          <>
            <CloudAccountForm
              name="cloudAccountForm"
              initialValues={props.cloudAccountForm}
              readOnly={!props.canEditForm}
              readOnlySubscription={!props.canEditSubscription}
              onChangeSubscription={handleChangeSubscription}
              onSubmit={handleSubmitForm}
            >
              <FormSectionDetails />
              <FormSectionSubscription />
              <FormSectionServices />
              <FormSectionCredits
                onCreditsRequested={handleCreditsRequested}
                balance={balance}
              />
            </CloudAccountForm>
            <Can I={UserRights.DisplayCredits}>
              <div className={style.summaryTables}>
                <CreditOrdersSummaryTable
                  accountId={props.currentAccountId}
                  creditOrders={creditOrders}
                  status={status}
                  showFulfilledOrders={showFulfilledOrders}
                  setShowFulfilledOrders={setShowFulfilledOrders}
                />
                <CreditOperationsSummaryTable
                  accountId={props.currentAccountId}
                  creditOperations={creditOperations}
                  status={status}
                  operationsLimit={operationsLimit}
                  setOperationsLimit={setOperationsLimit}
                />
              </div>
            </Can>
          </>
        )}
      </div>
    </div>
  );
};

export default connect(
  (state: any) => ({
    currentAccountId: state.account.currentAccount.id,
    canEditForm:
      state.common.userRights.updateCloudAccountInformationExceptSubscription,
    canEditSubscription: state.common.userRights.updateCloudAccountSubscription,
    canDisplayCredits: state.common.userRights.displayCredits,
    cloudAccountForm: state.account.cloudAccountForm,
    currentUserEmail: state.keycloak.userInfo.email,
    form: state.form["cloudAccountForm"]
  }),
  {
    initializeCloudAccountPage,
    saveCloudAccount,
    updateCurrentUserAccount,
    resetCloudAccountPage
  }
)(injectIntl(CloudAccountPage));
