import * as types from '../../actions/actionTypes';
import { createReducer } from '../../utils/reduxHelper';
import { createSelector } from 'reselect';

const initialState = {
  selectedSlotId: undefined,
  xtraMotionVersions : null,
};

export const selectedSlotSelector = createSelector(
  [
    (state) => state.xtramotion.slots,
    (state) => state.xtramotion.selectedSlotId
  ],
  (slots, selectedSlotId) => {
    return slots.find((slot) => slot.id === selectedSlotId);
  }
);

const setSelectedSlotId = (state, { payload: selectedSlotId }) => ({
  ...state,
  selectedSlotId
});

const getXtraMotionVersionsSucceeded = (state, { payload: xtraMotionVersions }) => ({
  ...state,
  xtraMotionVersions
});

const xtraMotionReducer = createReducer(initialState, {
  [types.SET_SELECTED_SLOT_ID]: setSelectedSlotId,
  [types.REQUEST_GET_XTRAMOTION_VERSIONS_SUCCEEDED]: getXtraMotionVersionsSucceeded,
});

export default xtraMotionReducer;
