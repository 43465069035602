import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import CustomInput from '../../common/inputs/CustomInput';
import * as validation from './../../../utils/formValidationUtils';

const FormSectionDetails = props => {
    let {setHasErrorValidation} = props;
    return (
        <fieldset key="fieldset-1">
            <legend><FormattedMessage id='cloudAccountPage.form.detailLegendAccount' /></legend>
            <Field name="displayName" id="displayName" type="text" setHasErrorValidation={setHasErrorValidation} customProps={{ required: true }} component={CustomInput} label={<FormattedMessage id='cloudAccountPage.form.displayName' />} disabled={props.readOnly} validate={[validation.required, validation.maxLength(64), validation.noSpecialChars, validation.atLeastOneChar]} />
            <Field name="id" id="id" type="text" setHasErrorValidation={setHasErrorValidation} component={CustomInput} label={<FormattedMessage id='cloudAccountPage.form.accountId' />} disabled="true" />
            <Field name="technicalContact.email" id="technicalContactEmail" setHasErrorValidation={setHasErrorValidation} type="text" component={CustomInput} label={<FormattedMessage id='cloudAccountPage.form.technicalContact' />} validate={[validation.email]} />
            <Field name="commercialContact.email" id="commercialContactEmail" type="text" setHasErrorValidation={setHasErrorValidation}  component={CustomInput} label={<FormattedMessage id='cloudAccountPage.form.commercialContact' />} validate={[validation.email]} />
        </fieldset>
    )
}

FormSectionDetails.propTypes = {
    readOnly:PropTypes.bool.isRequired
}

FormSectionDetails.defaultProps = {
    readOnly:false
}

export default FormSectionDetails;