import * as types from '../actions/actionTypes';
import { createReducer } from './../utils/reduxHelper';

const initialState = {
  kc: null,
  userInfo: null
}

const updateKeycloakSucceeded = (state, action) => {
  return {
    ...state,
    kc: action.payload
  }
}

const getUserInfoSucceeded = (state, action) => {
  return {
    ...state,
    userInfo: action.payload
  }
}

const keycloakReducer = createReducer(initialState, {
  [types.KEYCLOAK_UPDATE_SUCCEEDED]: updateKeycloakSucceeded,
  [types.KEYCLOAK_GET_USERINFO_SUCCEEDED]: getUserInfoSucceeded,
})

export default keycloakReducer;