import React, { Component } from 'react';
import { connect } from 'react-redux';
import Transition from 'react-transition-group/Transition';
import classnames from 'classnames';
import './spinner.css';

const duration = 500;

const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
}

const transitionStyles = {
    entering: {visibility:'visible', opacity: 1 },
    entered: { opacity: 1 },
    exited:{visibility:'hidden'}
};

class Spinner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            debounceVisible: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.visible !== this.props.visible) {
            if (this.props.debounce && nextProps.visible) {
                let that = this;
                setTimeout(() => {
                    if (that.props.visible === true)
                        that.setState({ debounceVisible: true });
                }, this.props.debounce);
            } else {
                this.setState({ debounceVisible: nextProps.visible })
            }
        }
    }

    render() {
        let classText = classnames('text', { "animated-ellipsis": this.props.animatedEllipsis });
        return (
            <Transition timeout={500} in={this.state.debounceVisible} >
                {state =>
                    <div className="loading-container" style={{ ...defaultStyle, ...transitionStyles[state] }}>
                        <div className="loading__msg">
                            <i className="fa fa-spinner fa-spin text"></i>
                            <div style={{display:'inline-block'}}>
                                <span className="text">{this.props.text}</span>
                                <span className={classText}></span>
                            </div>
                        </div>
                    </div>}
            </Transition>
        )
    }
}
 
export default connect(state => state.spinner)(Spinner);