import * as types from '../actions/actionTypes';
import { createReducer } from './../utils/reduxHelper';


const initialState = {
    visible:false,
    text: 'Loading data',
    animatedEllipsis: true,
    debounce: 500
}

function showSpinner(state = initialState, action) {
    return {
        ...state,
        text: action.payload || initialState.text,
        visible:true
    }
}

function hideSpinner(state, action) {
    return {
        ...state,
        visible:false
    }
}

const spinnerReducer = createReducer(initialState, {
    [types.SHOW_SPINNER]: showSpinner,
    [types.HIDE_SPINNER]: hideSpinner
})

export default spinnerReducer;