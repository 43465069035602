import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideSpinner } from './../../../actions/spinnerActions';

class NotFoundPage extends Component {
    componentWillMount(){
        this.props.hideSpinner();
    }

    render() {
        return (
            <div>
                <div className="container center">
                    <h1><i className="fa fa-ban" aria-hidden="true" style={{'marginRight': '10px'}}></i>404. Not Found</h1>
                    <p className="error-detail">The requested URL was not found on this server.</p>
                    <a href="/">Go to home page</a>
                </div>
            </div>
        )
    }
}

export default connect(null, { hideSpinner })(NotFoundPage);