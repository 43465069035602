// @ts-nocheck
import { CancelToken } from "axios";
import {Balance, CreditOrderRequest, CreditOrders} from "../@types";
import axiosInstance from "./axiosConfig";
import moment from "moment";

export default {
  getCreditOrders: async (accountId: string, cancelToken?: CancelToken) => 
    await axiosInstance.get<CreditOrders>(
      `/accounts/${accountId}/credits/orders`,
      { cancelToken }
    ).then(response => response.data),

  getCreditOrdersCsvReport: (accountId: string, cancelToken?: CancelToken) => {
    return axiosInstance.get(`/accounts/${accountId}/credits/orders/report`, {
      cancelToken,
      responseType: "blob",
      headers: {
        Accept: "text/csv"
      },
      byPassCatchError: true
    });
  },

  addCreditOrder: async (accountId: string, creditOrder: CreditOrderRequest) => 
    axiosInstance.post(`/accounts/${accountId}/credits/orders`, {
      credits: creditOrder.credits,
      id : creditOrder.id,
    }, {
      byPassCatchError: true
    })
  
};
