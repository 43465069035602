import { AxiosResponse } from "axios";
import React, { FC, useCallback } from "react";
import {
  ButtonToolbar,
  ToggleButton,
  ToggleButtonGroup
} from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { FormattedMessage, injectIntl } from "react-intl";
import { QueryStatus } from "react-query";
import { toastr } from "react-redux-toastr";
import { CreditOrder } from "../../../../@types";
import CreditOrdersApi from "../../../../api/creditOrdersApi";
import { UserRights } from "../../../../utils/ability";
import { saveAs } from "../../../../utils/saveAs";
import Can from "../../../common/abilities/Can";
import styles from "./CreditOrdersSummaryTable.module.scss";
import commonStyles from "../CreditPanel.module.scss";
import CreditOrdersSummaryTableRow from "./CreditOrdersSummaryTableRow";

type Props = {
  accountId: string;
  creditOrders?: CreditOrder[];
  status: QueryStatus;
  intl: any; // update react-intl and use the hook useIntl()
  showFulfilledOrders: boolean;
  setShowFulfilledOrders: (showFulfilledOrders: boolean) => void;
}

const CreditOrdersSummaryTable: FC<Props> = ({
  accountId,
  creditOrders,
  status,
  showFulfilledOrders,
  setShowFulfilledOrders,
  intl
}) => {
  const downloadCsvReport = useCallback(() => {
    CreditOrdersApi.getCreditOrdersCsvReport(accountId)
      .then((response: AxiosResponse) => {
        saveAs(response, "creditOrdersReport.csv");
      })
      .catch((error: Error) => {
        toastr.error(
          intl.formatMessage({id:"cloudAccountPage.creditOrdersSummaryTable.downloadCsvFailed"}),
          error.message
        );
      });
  }, [accountId]);

  return (
    <div className={styles.root}>
      <fieldset key="order-summary-fieldset" >
        <legend className={commonStyles.legendWithBtn}>
          <span><FormattedMessage id='cloudAccountPage.creditOrdersSummaryTable.title' /></span>
          <div className="pull-right">
            <ToggleButtonGroup
              type="checkbox"
              value={showFulfilledOrders ? [1] : []}
              onChange={(options: number[]) =>
                setShowFulfilledOrders(options.includes(1))
              }
            >
              <ToggleButton value={1} bsSize="xsmall">
                <i className="fa fa-filter" style={{marginRight: 4}} aria-hidden="true"></i>
                <FormattedMessage id="cloudAccountPage.creditOrdersSummaryTable.showFulfilledOrders" />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </legend>
      </fieldset>

      <div className={commonStyles.tableContainer}>
        <div className={commonStyles.innerTableContainer}>
          <Scrollbars>
            <table className={commonStyles.customTable}>
              <thead className={commonStyles.thead}>
                <tr>
                  {[
                    "order",
                    "date",
                    "expirationDate",
                    "remainingCredits"
                  ].map((columnName) => (
                    <th key={columnName}>
                      <FormattedMessage
                        id={`cloudAccountPage.creditOrdersSummaryTable.columns.${columnName}`}
                      />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {status === "loading" && (
                  <h3 className={commonStyles.tablePlaceholder}>
                    <FormattedMessage
                      id={`cloudAccountPage.creditOrdersSummaryTable.loadingCreditOrders`}
                    />
                  </h3>
                )}
                {(status === "error" || (creditOrders && !creditOrders.length)) && (
                  <h3 className={commonStyles.tablePlaceholder}>
                    <FormattedMessage
                      id={`cloudAccountPage.creditOrdersSummaryTable.noData`}
                    />
                  </h3>
                )}
                { creditOrders &&
                  creditOrders.map((creditOrder) => (
                    <CreditOrdersSummaryTableRow
                      key={creditOrder.id}
                      {...creditOrder}
                    />
                  ))}
              </tbody>
            </table>
          </Scrollbars>
        </div>
      </div>

      <Can I={UserRights.DownloadCreditsReport}>
        <div className={commonStyles.downloadContainer}>
          <button
            className={commonStyles.downloadButton}
            onClick={downloadCsvReport}
          >
            <i className="fa fa-download"/>
            <FormattedMessage id="cloudAccountPage.creditOrdersSummaryTable.downloadCsv" />
          </button>
        </div>
      </Can>
    </div>
  );
};

export default injectIntl(CreditOrdersSummaryTable);
