import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class AgentActionLinks extends Component {

    static propTypes = {
        item: PropTypes.object.isRequired,
        onUnlink: PropTypes.func.isRequired,
        onSendEmail: PropTypes.func.isRequired,
        rights: PropTypes.object.isRequired,
    }

    handleUnlink = (event, isDisable) => {
        event.stopPropagation();
        if (!isDisable)
            this.props.onUnlink(event);
    }

    render() {
        let disabledUnlinkIcon = !(this.props.item.linkedAccount && this.props.rights.unlinkAgentFromCloud);
        return (
            <div className="container-agent-actions" style={{ width: "90px" }}>
                <OverlayTrigger key="2" placement="top" delayShow={500} overlay={<Tooltip id="tooltip">Unlink Agent</Tooltip>}>
                    <button className="btn btn-link user-action" disabled={disabledUnlinkIcon} onClick={event => this.handleUnlink(event, disabledUnlinkIcon)} ><i className="fa fa-chain-broken"></i></button>
                </OverlayTrigger>
                <OverlayTrigger key="1" placement="top" delayShow={500} overlay={<Tooltip id="tooltip">Send Email</Tooltip>}>
                    <button className="btn btn-link user-action" onClick={this.props.onSendEmail} ><i className="fa fa-envelope"></i></button>
                </OverlayTrigger>
            </div>
        );
    }
}

export default AgentActionLinks;