import React, {Component} from 'react';
import PopoverDateFilter from '../../cloudAccount/formSections/PopoverDateFilter';
import PropTypes from 'prop-types';

const labelRequired = {
    color: 'red',
    position: 'absolute',
    top: '0',
    right: '4px'
}

class CustomDatePicker extends Component {
    static propTypes = {
        setHasErrorValidation: PropTypes.func.isRequired,
        isValidDate: PropTypes.func,
    }

    constructor(props) {
        super(props);
        this.state = {
            input: this.props.input,
            error: this.props.meta.error,
            hasError: (this.props.meta.dirty || this.props.meta.touched) && this.props.meta.error
        }
        this.onValueChange = this.onValueChange.bind(this);
        this.setError = this.setError.bind(this);
    }

    onValueChange = (date) => {
        this.state.input.onChange(date);
    }

    setError = (err) => {
        if(err){
            this.setState({error:err, hasError:true});
            this.props.setHasErrorValidation(true);
        }else{
            this.setState({error:'', hasError:false});
            this.props.setHasErrorValidation(false);
        }
    }

    render(){
        return (
            <div className={this.state.hasError ? 'form-group has-error has-feedback' : 'form-group'}>
                <label className="col-sm-4 control-label" htmlFor="name">
                    {this.props.label}
                    {this.props.customProps && this.props.customProps.required && <span style={labelRequired}>*</span>}
                </label>
                <div className="col-sm-8">
                    <PopoverDateFilter {...this.props.customAttrs} setError={this.setError} className="form-control" disabled={this.props.disabled} selectedItem={this.props.input.value} onChange={this.onValueChange} isValidDate={this.props.customProps.isValidDate}/>
                    { this.state.hasError && <span className="help-block">{this.state.error}</span> }
                </div>
            </div>
        )
    }
    
}

export default CustomDatePicker;
