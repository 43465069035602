import { AxiosResponse } from "axios";

export const saveAs = (
  response: AxiosResponse,
  defaultFilename: string
): void => {
  const url = window.URL.createObjectURL(
    new Blob([response.data], { type: response.data.type })
  );
  const link = document.createElement("a");
  link.href = url;
  let contentDisposition = response.headers["content-disposition"];
  let filename = defaultFilename;
  if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    let matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
  }
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};