import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import CustomCheckbox from '../../../common/inputs/CustomCheckbox';

const FormSectionServices = (props) => {
  return (
    <fieldset key="fieldset-1">
      <legend><FormattedMessage id='cloudAccountPage.form.legendServices' /></legend>
      <Field
        name="subscription.features.xmo.isActive"
        id="subscription.features.xmo.isActive"
        disabled={props.readOnly}
        parse={value => !!value}
        component={CustomCheckbox}
        label={<FormattedMessage id='cloudAccountPage.form.features.xmo' />}
      />
    </fieldset>
  )
}

FormSectionServices.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  features: PropTypes.object.isRequired,
}

FormSectionServices.defaultProps = {
  readOnly:false,
  features:{}
}

export default FormSectionServices;