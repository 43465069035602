import Navbar from 'react-bootstrap/lib/Navbar';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import '../../assets/css/terms.css';

const TermsPage = () => (
  <div>
    <Navbar collapseOnSelect fixedTop fluid>
      <Navbar.Header>
        <Navbar.Brand>
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="EVS | VIA" />
          </Link>
        </Navbar.Brand>
      </Navbar.Header>
    </Navbar>
    <div className="container">
      <div className="terms">
        <h1>XTRAMOTION &amp; C-NEXT TERMS OF USE</h1>
        <p>By accessing or using the Platform You acknowledge that:</p>
        <ul>
          <li>
            The XtraMotion services hosted through the XtraMotion platform and
            the C-Next services hosted through the C-Next platform (each a
            “Platform”) are offered to You directly by Your provider (the
            “Provider”) pursuant to Your Provider standard terms and conditions
            or such other terms as agreed upon between You and Your Provider and
            that the only warranties offered with regard to the XtraMotion
            services are those offered by Your Provider and not by EVS Broadcast
            Equipment SA which is merely hosting the Platform.
          </li>
          <li>
            You are legally bound by these Terms of Use with regard to Your use
            of the Platform.
          </li>
        </ul>
        <p>
          If You do not agree with these Terms of Use, please do not access or
          use the Platform.
        </p>
        <p>
          "<b>EVS</b>", "<b>We</b>" or "<b>Us</b>" as used in these Terms of Use
          means EVS Broadcast Equipment SA.
          <br />"<b>Content</b>" as used in these Terms of Use means any
          contents of any type, form or format whatsoever including but not
          limited to audio and video contents.
          <br />"<b>You/r</b>" as used in these Terms of Use means You and the
          organisation to which you belong as a user of the Platform.
        </p>

        <h2>1. PRIVACY STATEMENT</h2>
        <p>
          At EVS We give utmost importance to Your data's privacy. Please refer
          to our{' '}
          <a href="https://evs.com/en/privacystatement">Privacy Statement</a>{' '}
          which explains how EVS collects, processes, uses, stores and transfers
          Your personal data as well as Your rights over any personal data We
          hold about You in compliance with the General Data Protection
          Regulation 2016/679 of the European Parliament and of the Council of
          27 April 2016.
        </p>

        <h2>2. LINKS TO OTHER WEBSITES</h2>
        <p>
          The Platform may contain links to other websites for Your convenience
          and information. These other websites may be operated by companies not
          affiliated with EVS. We do not assume any responsibility with regard
          to the content and/or Your use of these third-party websites which are
          subject to their own terms of use.
        </p>

        <h2>3. USE OF THE PLATFORM</h2>
        <p>
          You agree that You will not use the Platform in any manner that could
          misuse, damage or overburden any Content, equipment, software or
          services of EVS or any of its affiliates and/or of Third Parties.
        </p>

        <h2>4. PROPRIETARY RIGHTS OF THE PLATFORM AND CONTENT</h2>
        <p>
          All Intellectual Property Rights in or relating to the Platform
          anywhere in the world vests in EVS. You agree that You will not use
          any Intellectual Property Rights in any way that would be inconsistent
          with or in violation of such EVS Intellectual Property Rights.
        </p>
        <p>
          All Content made available by You while using the Platform shall vest
          in You and/or third parties. By accessing the Platform, You
          expressively warrant and undertake not in any way or by any manner to
          retain, make copies of, store, distribute, share, publish, broadcast
          or in other ways or by other means process or transfer Content to
          serve any other purpose than what is explicitly agreed between You
          and/or those third parties.
        </p>
        <p>
          For the purpose of this clause, "Intellectual Property Rights" means
          any invention, patent or application for a patent, design (registered
          or unregistered), trademark (registered or unregistered), name,
          copyright, rights in software, database rights, rights in circuit
          layout, trade secret, know-how, proprietary information or other
          industrial or intellectual property right in respect of any invention,
          information, process, work, Content or method.
        </p>

        <h2>5. INDEMNIFICATION</h2>
        <p>
          You agree to defend, indemnify and hold Us (including officers,
          directors, employees, agents, affiliates and subcontractors) harmless
          from and against any claims, suit, action or damages (including legal
          fees) arising from or related to (a) any negligent act or omission in
          connection with the observance and performance of these Terms of Use,
          and any breach of these Terms of Use, and/or (b) the use of the
          Platform and of the Content including any liability or expense arising
          from claims, losses, damages, suits, judgments, litigation costs and
          attorneys' fees. This defence and indemnification will survive the
          present Terms of Use and Your use of the Platform.
        </p>

        <h2>6. LEGAL NOTICE</h2>
        <p>
          The Platform is provided to You "as is" and, to the fullest extent
          permitted by applicable law in each specific jurisdiction, We
          (including our officers, directors, employees, agents affiliates and
          subcontractors) disclaim all express or implied warranties in
          connection with the Platform and Your use thereof and do not assume
          any responsibility for any damage, including direct and indirect
          damages such as loss of goodwill or business, loss of revenue or
          profits or any other claim, demand, proceeding, cost and expense
          arising from or related to the use (including errors, inaccuracies,
          delays, interruptions, failure, poor quality or limitations of any
          kind) of the Platform and/or the Content it contains.
        </p>
        <p>
          We reserve the right to modify, improve, update, suspend or terminate
          any functionality of the Platform or its Content, including by
          removing any content or service where We consider it necessary to
          protect our rights and legitimate interests.
        </p>

        <h2>7. SUSPENSION OF ACCESS </h2>
        <p>
          We can suspend Your access to the Platform, without a right to any
          compensation or indemnity, with immediate effect and without notice or
          compensation, in case We have valid reasons to believe that You do not
          respect these Terms of Use. Your access will be reinstated when the
          non-compliance with the Terms of Use is fully resolved. Your access to
          the Platform can be terminated indefinitely in case of serious breach,
          or in case You refuse to act in a manner compliant with these Terms of
          Use.
        </p>

        <h2>8. FORCE MAJEURE</h2>
        <p>
          Any failure or inability of EVS to provide the access or services
          related to the Platform, or to otherwise comply with its obligations
          to any user hereunder, due to the occurrence of a Force Majeure Event,
          shall not be a breach of contract by EVS and no liability shall be
          attached to such situations. If affected by a Force Majeure Event, EVS
          shall, as soon as practicable, inform You of the occurrence of the
          Force Majeure Event, the reasons for the delay or failure, its likely
          duration, and the steps that are being taken to rectify the delay or
          failure.
        </p>
        <p>
          For the purpose of this clause, "Force Majeure Event" means any event
          or circumstance that could not be predicted and is beyond the control
          of EVS (or its contractors) and which is unavoidable even if EVS (or
          its contractors) exercised the utmost diligence, and which prevents
          EVS or its contractor from performing a part or all of its obligations
        </p>

        <h2>9. GOVERNING LAW</h2>
        <p>
          These Terms of Use shall be governed by and construed in accordance
          with the laws of Belgium.
        </p>
        <p>
          The court of Liège (Belgium) shall have the exclusive jurisdiction
          over any lawsuit, action or proceeding arising out of these Terms of
          Use.
        </p>

        <h2>10. TERMS OF USE UPDATES</h2>
        <p>
          EVS may change these Terms of Use from time to time as We improve our
          current offerings, and as technologies and laws change. You can
          determine when these Terms of Use were last revised by referring to
          the "update " legend at the bottom of this page. Any changes will
          become effective upon our posting of the revised Terms of Use on the
          Platform.
        </p>

        <h2>11. Contact us</h2>
        <p>
          If You have any question related to these Terms of Use, please do not
          hesitate to contact us by sending an e-mail to{' '}
          <a href="mailto:legal@evs.com">legal@evs.com</a> or by sending a
          letter to EVS Broadcast Equipment SA - to the attention of the Legal
          Department - rue Bois Saint-Jean 13, 4102 Seraing, Belgium. We are
          happy to assist You and answer any questions You might have.
        </p>
        <p>
          <em>These Terms of Use were most recently updated in May 2022.</em>
        </p>
      </div>
    </div>
  </div>
);

export default TermsPage;
