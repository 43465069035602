import uuidV4 from 'uuid/v4';
import _ from 'lodash';

/**
 * 
 * @param {object} locationConfig 
 */
export const getParametersFromEndpoint = (endpoint) => {
    return Object.keys(endpoint.configuration).map(key => ({
        label: key,
        value: endpoint.configuration[key].value,
        sensitive: endpoint.configuration[key].sensitive,
        description : '',
        index : 0,
        advanced : false
    }))
}

/**
 * 
 * @param {object} protocol 
 */
export const getParametersFromProtocol = (protocol) => {
    return Object.keys(protocol.configuration).map(key => ({
        label: key,
        value: protocol.configuration[key].defaultValue || '',
        sensitive: protocol.configuration[key].sensitive,
        description : protocol.configuration[key].description,
        index : protocol.configuration[key].index,
        advanced : protocol.configuration[key].advanced
    }))
}

/**
 * 
 * @param {object} protocol 
 */
export const getOverwritePoliciesFromProtocol = (protocol) => {
    return protocol.overwritePolicies.map(overwritePolicyName =>({
        id: overwritePolicyName,
        name: overwritePolicyName
    }))
}

/**
 * 
 * @param {string, object} locationToCreate 
 * @param {string} key 
 * @param {array} protocols 
 */
export const createLocation = (locationApi, protocolsById) => {
    let location = {
        id: '',
        name: '',
        status: 'unknow',
        endpointId: '',
        parameters: [],
        extraParameters: [],
        overwritePolicy : 'error',
        lastUpdateDate: undefined
    }
    if (typeof locationApi === 'string') {
        //empty location? (locationToCreate equals protocol id)
        Object.assign(location, { protocol: locationApi, key: uuidV4() });
    }
    else if (typeof locationApi === 'object') {
      //create location from object api
        Object.assign(location, { id: locationApi.id, key: locationApi.id, name: locationApi.name ,lastUpdateDate:locationApi.lastUpdateDate });
        let uniqueEndpoint = locationApi.endpoints[0];
        Object.assign(location, {
            protocol: uniqueEndpoint.protocol,
            overwritePolicy: uniqueEndpoint.overwritePolicy,
            parameters: getParametersFromEndpoint(uniqueEndpoint),
            endpointId: uniqueEndpoint.id,
        });
    }

    //add missing parameters from protocols
    let protocolConfig = protocolsById[location.protocol].configuration;
    const subFolderProp = window.env.subFolderPropertyName;
    // add hardcoded subfolder property
    protocolConfig[subFolderProp] = {
      label: subFolderProp,
      defaultValue: locationApi.subfolder,
      sensitive: false,
      index : 100,
      description: "Destination subfolder under the root of the location endpoint",
      advanced: false
  }

    Object.keys(protocolConfig).reduce((previous, current) => {
            let findProperty = previous.find(x => x.label === current);
            if (findProperty === undefined) {
                previous.push({
                    label: current,
                    value: protocolConfig[current].defaultValue || '',
                    sensitive: protocolConfig[current].sensitive,
                    description : protocolConfig[current].description,
                    index : protocolConfig[current].index,
                    advanced : protocolConfig[current].advanced
                })
            }
            else {
                findProperty.description = protocolConfig[current].description;
                findProperty.index = protocolConfig[current].index;
                findProperty.advanced = protocolConfig[current].advanced;
            }
            return previous;
        }, location.parameters);

    location.parameters = _.orderBy(location.parameters, ['index'], ['asc']);

    //add extra parameters (endpoint)
    if (window.env.advancedParameters) {
        Object.keys(window.env.advancedParameters).reduce((previous, param) => {
            previous.push({
                label: param,
                value: locationApi && locationApi.endpoints[0][param] !== undefined ? locationApi.endpoints[0][param].toString() : window.env.advancedParameters[param].default,
                // sensitive: window.env.advancedParameters[param].sensitive,
                type: window.env.advancedParameters[param].type,
            })
            return previous;
        }, location.extraParameters);
    }
    return location;
}