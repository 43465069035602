import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import SlotList from './panels/slots/SlotList';
import InstanceStatus from './panels/status/InstanceStatus';
import HistoricActivity from './panels/operations/HistoricOperations';
import styles from './XtraMotionPage.module.scss';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { UserRights } from '../../utils/ability';
import Can from '../common/abilities/Can';
import XtraMotionApi from "../../api/xtraMotionApi";
import { useQuery } from 'react-query';
import { showSpinner, hideSpinner } from '../../actions/spinnerActions';
import { initializeXtraMotionPage, setSelectedSlotById } from "../../actions/xtraMotionActions";
import { toastr } from 'react-redux-toastr';
import { Account, HistoricOperationBackend, SlotBackend } from '../../@types';
import axios, { CancelToken } from 'axios';

export function batchXtraMotionQueries(accountId: string) {
  return Promise.all([
    XtraMotionApi.getSlots(accountId).then((result) => result.slots),
    XtraMotionApi.getHistoricOperations(accountId)])
}

interface Props {
  account: Account;
  featureActive: boolean;
  selectedSlotId: string;
  showSpinner: (text?:string) => void;
  hideSpinner: () => void;
  setSelectedSlotById: (id:string|undefined) => void;
  initializeXtraMotionPage: (
    accountId: string,
    cancelToken?: CancelToken
  ) => Promise<void>;
}

const XtraMotionPage:FC<Props> = ({account, featureActive, selectedSlotId, showSpinner, hideSpinner, setSelectedSlotById, initializeXtraMotionPage }) => {  

  useEffect(() => {
    const cancelTokenSrc = axios.CancelToken.source();
    if (account?.id) {
      initializeXtraMotionPage(
        account.id,
        cancelTokenSrc.token
      );
    }
  }, [initializeXtraMotionPage, account]);

  const { data: [slots = [], historicOperations = []] = [] , isLoading, isFetching } = useQuery<[Array<SlotBackend>, Array<HistoricOperationBackend>], Error>(
    {
      queryKey: ['xtramotion', account],
      queryFn: () => batchXtraMotionQueries(account.id),
      refetchInterval: 5000,
      onError: (error:any) => toastr.error("Failed to retrieve data", error.message)
    });

  useEffect(() => {
    isLoading ? showSpinner('Loading data') : hideSpinner();
  }, [hideSpinner, isLoading, showSpinner]);

  useEffect(() => {
    const slot = slots.find(slot => slot.id === selectedSlotId)
    if (slots.length) {
      if (!slot)
        setSelectedSlotById(slots[0].id);
    }
    else if (!slots.length) {
      setSelectedSlotById(undefined);
    }
  }, [selectedSlotId, setSelectedSlotById, slots]);

  const selectedSlot = slots.find(slot => slot.id === selectedSlotId);

  return (
    <div className='container-fluid container-full-height'>
      <h1 className={styles.header}>
        <FormattedMessage id='xtraMotionPage.title' />
        {!featureActive && (
          <Can I={UserRights.DisplayXmo}>
            {!featureActive && (
            <Alert bsStyle="danger" className={styles.alert}>
              <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
              <FormattedMessage id='xtraMotionPage.serviceNotActivated' />
              <Link to="/app/cloudaccount" className={styles.hyperlink}>Cloud Account</Link>
            </Alert>
            )}
          </Can>
        )}
      </h1>
      <div className={styles.xtramotionContent}>
        <div className={styles.slotsSection}>
          <div>
            <SlotList
              slots={slots}
              account={account}
              selectedSlot={selectedSlot}
            />
            {selectedSlot && (
              <InstanceStatus slot={selectedSlot} isFetching={isFetching} />
            )}
          </div>
        </div>
        <div className={styles.operationsSection}>
          <HistoricActivity historicOperations={historicOperations} />
        </div>
      </div>
    </div>
  )
}

export default connect((state:any) => ({
  account: state.account.currentAccount,
  featureActive: state.account.currentAccount.subscription.features.xmo.isActive,
  selectedSlotId: state.xtramotion.selectedSlotId,
}), { 
  showSpinner,
  hideSpinner,
  setSelectedSlotById,
  initializeXtraMotionPage
})(XtraMotionPage);
