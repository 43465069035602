import { FC, useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { OverlayTrigger, Panel, Tooltip, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { toastr } from 'react-redux-toastr';
import SlotItem from './SlotItem';
import { setSelectedSlotById, removeSlot, addSlot, updateSlot, renewCode } from "../../../../actions/xtraMotionActions";
import { showXtraMotionSlotDlg, showUpdateXtraMotionSlotDlg, showConfirmDlg, hideModal } from '../../../../actions/modalActions';
import { UserRights } from '../../../../utils/ability';
import styles from './SlotList.module.scss';
import Can from '../../../common/abilities/Can';
import { SlotBackend, SubmittedSlotUI, UpdateSlotBackend } from '../../../../@types';

interface Props {
  slots: Array<SlotBackend>,
  setSelectedSlotById: (id: string) => void;
  intl: any;
  showConfirmDlg: (parameter: any) => void;
  hideModal: () => void;
  addSlot: (slot: SubmittedSlotUI) => void;
  updateSlot: (slotId: string, slot: UpdateSlotBackend) => Promise<SlotBackend>;
  removeSlot: (slot: SlotBackend) => void;
  renewCode: (slot: SlotBackend) => void;
  account: any;
  selectedSlot: SlotBackend;
  showXtraMotionSlotDlg: (parameter: any) => void;
  showUpdateXtraMotionSlotDlg: (parameter: any) => void;
}

const SlotList:FC<Props> = ({
  setSelectedSlotById,
  intl,
  showConfirmDlg,
  hideModal,
  addSlot,
  updateSlot,
  removeSlot,
  renewCode,
  account,
  slots,
  selectedSlot,
  showXtraMotionSlotDlg,
  showUpdateXtraMotionSlotDlg
}) => {

  const scrollBarRef = useRef<any>(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    setTimeout(() => {
      if (scrollBarRef && scrollBarRef.current) {
        scrollBarRef.current.forceUpdate();
      }
    }, 0)
  },[]);

  const handleRemoveSlot = ($event:any, item: SlotBackend) => {
    $event.preventDefault();
    $event.stopPropagation();
  
    showConfirmDlg({
      title: intl.formatMessage({ id: 'xtraMotionPage.confirmRemoveServerDlg.title' }),
      htmlBody: intl.formatHTMLMessage({ id: 'xtraMotionPage.confirmRemoveServerDlg.message' }, { slotName: item.name }),
      async: true,
      onConfirm: async () => {
        try {
          await removeSlot(item);
          await queryClient.invalidateQueries('xtramotion');
          hideModal();
          toastr.success(intl.formatMessage({ id: 'xtraMotionPage.confirmRemoveServerDlg.removeSlotSucceed' }));
        }
        catch (ex) {
          toastr.error(intl.formatMessage({ id: 'xtraMotionPage.confirmRemoveServerDlg.removeSlotFailed' }));
        };
      },
      onClose: () => hideModal()
    });
  }

  const handleRenewCode = ($event:any, item: SlotBackend) => {
    $event.preventDefault();
    $event.stopPropagation();
  
    showConfirmDlg({
      title: intl.formatMessage({ id: 'xtraMotionPage.confirmRenewCodeDlg.title' }),
      htmlBody: intl.formatHTMLMessage({ id: 'xtraMotionPage.confirmRenewCodeDlg.message' }, { slotName: item.name }),
      async: true,
      onConfirm: async () => {
        try {
          await renewCode(item);
          await queryClient.invalidateQueries('xtramotion');
          hideModal();
          toastr.success(intl.formatMessage({ id: 'xtraMotionPage.confirmRenewCodeDlg.renewCodeSucceed' }));
        }
        catch (ex) {
          toastr.error(intl.formatMessage({ id: 'xtraMotionPage.confirmRenewCodeDlg.renewCodeFailed' }));
        };
      },
      onClose: () => hideModal()
    });
  }

  const handleAddSlot = () => {
    showXtraMotionSlotDlg({
      title: intl.formatMessage({ id: 'xtraMotionPage.addSlotDlg.title' }),
      account,
      onSubmit: async (model:SubmittedSlotUI) => {
        await addSlot(model);
        await queryClient.invalidateQueries('xtramotion');
      },
      onClose: () => hideModal()
    });
  }

  const handleUpdateSlot = (_$event: any, item: SlotBackend) => {
    showUpdateXtraMotionSlotDlg({
      title: intl.formatMessage({ id: 'xtraMotionPage.EditSlotDlg.title' }),
      slot: item,
      onSubmit: async (model:SlotBackend) => {
        await updateSlot(model.id, model);
        await queryClient.invalidateQueries('xtramotion');
      },
      onClose: () => hideModal()
    });
  };

  return (
    <div>
      <h3>
        <span>{intl.formatMessage({ id: 'xtraMotionPage.slotList.title' }, { nbSlots: slots.length })}</span>
        <Can I={UserRights.DisplayAndPerformActionsOnInstances}>
          <OverlayTrigger placement="top" delayShow={500} overlay={<Tooltip id="tooltip"><FormattedMessage id='xtraMotionPage.addSlotDlg.title' /></Tooltip>}>
            <Button data-test-id="btn-add-slot" onClick={handleAddSlot} className={styles.addSlot}>
              <i className="fa fa-plus no-text" />
            </Button>
          </OverlayTrigger>
        </Can>
      </h3> 
      <Panel>
        <Panel.Body className={styles.panelList}>
            {slots && slots.length === 0 && (
              <div className={styles.placeholder}>
                <span data-test-id="instance-list-placeholder">No Service</span>
                <Can I={UserRights.DisplayAndPerformActionsOnInstances}>
                  <Button data-test-id="btn-add-first-slot" bsStyle="link" onClick={handleAddSlot} className={styles.addFirstSlot}>
                    Add new 
                  </Button>
                </Can>
              </div>
            )}
          <Scrollbars ref={scrollBarRef}>
            {slots.map((slot, index) =>
                <SlotItem key={index}
                  dataTestId={`slot_${index}`}
                  onSelectItem={($event:any, item:SlotBackend) => setSelectedSlotById(item.id)}
                  isActive={selectedSlot ? slot.id === selectedSlot.id : false}
                  item={slot}
                  onUpdateItem={handleUpdateSlot}
                  onRemoveItem={handleRemoveSlot}
                  onRenewCode={handleRenewCode}
                />
            )}
          </Scrollbars>
        </Panel.Body>
      </Panel>
    </div>
  )
}

export default connect(null, { 
  setSelectedSlotById,
  showXtraMotionSlotDlg,
  showUpdateXtraMotionSlotDlg,
  showConfirmDlg,
  hideModal,
  removeSlot,
  addSlot,
  updateSlot,
  renewCode
})(injectIntl(SlotList));