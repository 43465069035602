import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import CloudAccountList from './CloudAccountList';
import CloudAccountDetails from './CloudAccountDetails';
import { initializeCloudAccountMgmtPage, resetCloudAccountMgmtPage } from './../../../actions/accountActions';
import { cloudAccountsSelector } from './../../../reducers/pages/accountsMgmtPageReducer';
import './cloud-account-mgmt.css';
import _ from 'lodash';
import axios from 'axios';

class CloudAccountMgmtPage extends Component {

    cancelTokenSrc = axios.CancelToken.source();
    
    componentDidMount() {
        this.props.initializeCloudAccountMgmtPage(this.cancelTokenSrc.token);
    }

    componentWillUnmount() {
        this.cancelTokenSrc.cancel();
        this.props.resetCloudAccountMgmtPage();
    }

    render() {
        return (
            <div className="container-fluid container-full-height">
                <h1><FormattedMessage id="admin.cloudAccountMgmtPage.title" /></h1>
                {!_.isEmpty(this.props.cloudAccounts) &&
                    <div className="row content">
                        <div className="col-md-offset-1 col-md-3 col-full-height" >
                            <CloudAccountList {...this.props} />
                        </div>
                        <div className="col-md-5">
                            <Route exact path={`/app/admin/cloudaccounts/:cloudaccountid`} component={CloudAccountDetails} />
                        </div>
                    </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        cloudAccounts: cloudAccountsSelector(state),
        userRights: state.common.userRights,
    }
}

export default connect(mapStateToProps, { initializeCloudAccountMgmtPage, resetCloudAccountMgmtPage })(CloudAccountMgmtPage);
