import React, { Component } from 'react';
import { connect } from 'react-redux';
import Panel from 'react-bootstrap/lib/Panel';
import { addAuthKey } from './../../../actions/authKeyAction';
import { toastr } from 'react-redux-toastr';
import { injectIntl } from 'react-intl';
import AuthKeyForm from './AuthKeyForm';

class AddAuthKey extends Component {

    handleAddAuthKey(formValues) {
        let { intl } = this.props;
        if(!formValues.authKeyName)
            return;
        return this.props.addAuthKey(this.props.accountId,formValues.authKeyName,).then(() => {
            this.backToAuthKeysPage();
            toastr.success(intl.formatMessage({ id: 'common.toastr.success' }),intl.formatMessage({ id: 'authKeysMgmtPage.toastrAuthKeyAddSucceed'},{name:formValues.authKeyName}));
        });
    }

    backToAuthKeysPage() {
        this.props.history.push(`/app/authkeys`);
    }


    render() {
        return (
            <Panel>
                <Panel.Heading><h3 className="panel-title">Add Auth Key</h3></Panel.Heading>
                <Panel.Body>
                    <AuthKeyForm 
                        onSubmit={(values) => this.handleAddAuthKey(values)}
                        onCancel={() => { this.backToAuthKeysPage() }} 
                    />
                </Panel.Body>
            </Panel>
        )
    }
}

function mapStateToProps(state) {
    return {
        accountId: state.account.currentAccount.id,
    };
}

export default connect(mapStateToProps, { addAuthKey })(injectIntl(AddAuthKey));