import Moment from "moment";

export enum InstanceStateBackend {
  Pending = "pending",
  Running = "running",
  Stopping = "stopping",
  Stopped = "stopped",
  Unknown = "unknown"
}

export enum XtraMotionServiceStateBackend {
  Starting = "starting",
  Started = "started",
  Stopped = "stopped",
  Unknown = "unknown"
}

export type XtraMotionInstanceBackend = {
  id: string;
  region: string;
  state: InstanceStateBackend;
  startDate?: string;
  stopDate?: string;
  serviceState: XtraMotionServiceStateBackend;
  scheduledStopDate?: string;
};

export type ListResponseBackend<TData> = {
  data: Array<TData>;
}

export type XtraMotionVersionBackend = {
  version: string;
  compatibility: string;
};

export type SlotsBackend = {
  slots: Array<SlotBackend>;
};

export type SlotBackend = {
  id: string;
  name: string;
  region: string;
  code?: string;
  instance?: XtraMotionInstanceBackend;
  version: string;
};

export type HistoricOperationBackend = {
  serverStartDate: string;
  serverStopDate?: string;
  instanceId: string;
  instanceName: string;
  points: number;
  startedBy: string;
};

export type ScheduleInstanceRequest = {
  scheduledStartDateTime?: Moment.Moment;
  scheduledStopDateTime: Moment.Moment;
};

export type SubmittedSlotUI = {
  name: string;
  region: string;
  version: string;
};

export type UpdateSlotBackend = {
  name: string;
  version: string;
};

export type Account = {
  id: string;
  displayName: string;
  commercialContact: {
    email: string;
  };
  technicalContact: {
    email: string;
  };
  createdOn: string;
  latestAgentActivity: string;
  subscription: {
    type: string;
    expirationDate: string;
    maximumLinkedAgent: number;
    features: {
      xmo: {
        isActive: boolean;
      };
    };
  };
  linkedAgentsCount: number;
  owner: string;
  latestUserActivity: string;
  status: string;
};

export type OperationSummary = {
  name: string;
  date: string;
  consumedCredits: number;
  id: string;
};

export type CreditOrder = {
  id: string;
  description: string;
  date: string;
  expirationDate: string;
  credits: number;
  currentBalance: number;
};

export type OverdueCredit = {
  operationId: string;
  credits: number;
};

export type CreditOrders = {
  date: string;
  orders: CreditOrder[];
  expiredOrders: CreditOrder[];
  overdueCredits: OverdueCredit[];
  balance: number;
};

export type Balance = {
  date: string;
  balance: number;
};

export type CreditOrderRequest = {
  credits: number;
  id: string;
};
