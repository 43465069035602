import React, { FC, useEffect, useState, useMemo } from 'react';
import classnames from 'classnames';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { UserRights } from '../../../../utils/ability';
import styles from './SlotItem.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Can from '../../../common/abilities/Can';
import { InstanceStateBackend, XtraMotionServiceStateBackend, SlotBackend, XtraMotionVersionBackend } from '../../../../@types';
import SlotCode from './slotCode';
import { connect } from 'react-redux';

type Props = {
  dataTestId: string;
  item: SlotBackend;
  isActive: boolean;
  onSelectItem: (event:any, slot: SlotBackend) => void;
  onUpdateItem: (event:any, slot: SlotBackend) => void;
  onRemoveItem: (event:any, slot: SlotBackend) => void;
  onRenewCode: (event:any, slot: SlotBackend) => void;
  xtraMotionVersions: Array<XtraMotionVersionBackend>;
  intl: any; // update react-intl and use the hook useIntl()
}

const SlotItem: FC<Props> = ({ dataTestId, item, isActive, onSelectItem, onUpdateItem, onRemoveItem, onRenewCode, xtraMotionVersions, intl }) => {

  const [status, setStatus] = useState<string>();

  useEffect(() => {
    let status: React.SetStateAction<string>;
    switch (item.instance?.state) {
      case InstanceStateBackend.Unknown:
        status = "unknow";
        break;
      case undefined:
      case InstanceStateBackend.Stopped:
        status = "stopped";
        break;
      case InstanceStateBackend.Running:
        status = item.instance?.serviceState === XtraMotionServiceStateBackend.Started ? "started" : "inProgress";
        break;
      default:
        status = "inProgress";
        break;
    }
    setStatus(status);
  }, [item.instance?.state, item.instance?.serviceState]);

  const compatibility = useMemo(() => {
    const xtraMotionVersion = xtraMotionVersions.find(
      (xv) => xv.version === item.version
    );
    return xtraMotionVersion
      ? {
          label: "Compatible with " + xtraMotionVersion.compatibility,
          color: "",
        }
      : {
          label: intl.formatMessage({
            id: "xtraMotionPage.slotItem.unsupportedXtraMotionVersion",
          }),
          color: "text-danger",
        };
  }, [intl, item.version, xtraMotionVersions]);

  return (
    <div data-test-id={dataTestId} onClick={event => onSelectItem(event, item)} className={classnames(styles.root, { "active": isActive })} style={{ position: 'relative' }}>
      <div className="media">
        <div className={`${styles.borderStatus} ${status}`} />
        <div className="media-body">
          <div className={styles.slotItemMainContainer}>
            <div className={styles.slotPropertiesContainer}>
              <span className={styles.slotName}>{item.name}</span>
              <div>
                  <FormattedMessage id='xtraMotionPage.slotItem.region' />
                  <span className={styles.slotPropertyValue}>{ " " }{item.region}</span>
              </div>
              <div className={styles.slotPropertyContainer}>
                <FormattedMessage id='xtraMotionPage.slotItem.connectionCode' />
                {item.code
                  ? <SlotCode message={item.code} dataTestId={dataTestId} />
                  : <span className={styles.slotPropertyValue}>None</span>
                }
                <Can I={UserRights.DisplayAndPerformActionsOnInstances}>
                  <OverlayTrigger placement="top" delayShow={500} overlay={<Tooltip id={`tooltip-renew-code-${dataTestId}`}><FormattedMessage id='xtraMotionPage.slotItem.renewCode' /></Tooltip>}>
                    <Button data-test-id={`btn-renew-shortcode-${dataTestId}`} bsStyle="warning" bsSize="small" disabled={item.instance} onClick={(event:React.MouseEvent<HTMLElement>) => onRenewCode(event, item)}>Renew <i className="fa fa-refresh no-text"></i></Button>
                  </OverlayTrigger>
                </Can>
              </div>
              <div className={styles.slotPropertyContainer}>
                <FormattedMessage id='xtraMotionPage.slotItem.xtraMotionVersion' />
                <span className={`${styles.slotPropertyValue} ${compatibility.color}`}>{item.version}</span>
                <OverlayTrigger placement="top" delayShow={500} overlay={<Tooltip id={`tooltip-compatibility-${dataTestId}`}>{compatibility.label}</Tooltip>}>
                  <i style={{marginLeft: '5px'}} className={`fa fa-question-circle-o ${compatibility.color}`} aria-hidden="true"></i>          
                </OverlayTrigger>
              </div>
            </div>
            {!item.instance && (
              <>
                <Can I={UserRights.UpdateXmo}>
                  <div className={styles.slotActionContainer}>
                    <OverlayTrigger placement="top" delayShow={500} overlay={<Tooltip id={`tooltip-update-slot-${dataTestId}`}><FormattedMessage id='xtraMotionPage.EditSlotDlg.title' /></Tooltip>}>
                      <Button data-test-id={`btn-update-${dataTestId}`} bsStyle="link" className="user-action" onClick={(event:React.MouseEvent<HTMLElement>) => onUpdateItem(event, item)}><i className="fa fa-pencil"></i></Button>
                    </OverlayTrigger>
                  </div>
                </Can>
                <Can I={UserRights.DisplayAndPerformActionsOnInstances}>
                  <div className={styles.slotActionContainer}>
                    <OverlayTrigger placement="top" delayShow={500} overlay={<Tooltip id={`tooltip-delete-slot-${dataTestId}`}><FormattedMessage id='xtraMotionPage.confirmRemoveServerDlg.title' /></Tooltip>}>
                      <Button data-test-id={`btn-remove-${dataTestId}`} bsStyle="link" className="user-action" onClick={(event:React.MouseEvent<HTMLElement>) => onRemoveItem(event, item)}><i className="fa fa-trash-o"></i></Button>
                    </OverlayTrigger>
                  </div>
                </Can>
              </>              
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect((state:any) => ({
  xtraMotionVersions: state.xtramotion.xtraMotionVersions,
}))(injectIntl(SlotItem));