import { createReducer } from './../../utils/reduxHelper';
import * as types from '../../actions/actionTypes';

const initialState = {
    users: null
};

const getUsersSucceeded = (state, action) => {
    return {
        ...state,
        users: action.payload
    }
}

const addUserSucceeded = (state, action) => {
    return {
        ...state,
        users: [
            ...state.users.slice(0, 1),
            action.payload,
            ...state.users.slice(1),
        ]
    }
}

const deleteUserSucceeded = (state, action) => {
    return {
        ...state,
        users: state.users.filter(user => user.email !== action.payload)
    }
}

const updateUserSucceeded = (state, action) => {
    return {
        ...state,
        users: state.users.map((user) => user.email === action.payload.email ? action.payload : user)
    }
}

const resetUsersPage =  (state, action) => {
    return initialState;
}

const usersPageReducer = createReducer(initialState, {
    [types.REQUEST_GET_USERS_SUCCEEDED]: getUsersSucceeded,
    [types.REQUEST_POST_USER_SUCCEEDED]: addUserSucceeded,
    [types.REQUEST_DELETE_USER_SUCCEEDED]: deleteUserSucceeded,
    [types.REQUEST_UPDATE_USER_SUCCEEDED]: updateUserSucceeded,
    [types.RESET_USERS_PAGE]:resetUsersPage
})

export default usersPageReducer;