import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Field } from "redux-form";
import * as formatter from "../../../../utils/formFormatterUtils";
import * as validation from "../../../../utils/formValidationUtils";
import CustomInput from "../../../common/inputs/CustomInput";

const FormSectionDetails = (props) => {
  let { setHasErrorValidation } = props;
  return (
    <fieldset>
      <legend>
        <FormattedMessage id="cloudAccountPage.form.detailLegendAccount" />
      </legend>
      <Field
        name="displayName"
        id="displayName"
        type="text"
        setHasErrorValidation={setHasErrorValidation}
        component={CustomInput}
        label={<FormattedMessage id="cloudAccountPage.form.displayName" />}
        disabled={props.readOnly}
        validate={[
          validation.maxLength(64),
          validation.noSpecialCharsOrEmpty,
          validation.atLeastOneCharOrEmpty
        ]}
      />
      {props.readOnly && (
        <Field
          name="id"
          id="id"
          type="text"
          setHasErrorValidation={setHasErrorValidation}
          component={CustomInput}
          label={<FormattedMessage id="cloudAccountPage.form.accountId" />}
          disabled="true"
        />
      )}
      <Field
        name="owner"
        id="owner"
        type="text"
        customProps={{ required: !props.readOnly }}
        setHasErrorValidation={setHasErrorValidation}
        component={CustomInput}
        label={<FormattedMessage id="cloudAccountPage.form.owner" />}
        disabled={props.readOnly}
        validate={[validation.required, validation.email]}
      />
      { props.readOnly && (
        <>
          <Field
            name="createdOn"
            format={formatter.formatDate}
            id="createdOn"
            disabled={true}
            type="text"
            setHasErrorValidation={setHasErrorValidation}
            component={CustomInput}
            label={<FormattedMessage id="cloudAccountPage.form.createdOn" />}
          />
          <Field
            name="latestAgentActivity"
            format={formatter.formatDate}
            id="latestAgentActivity"
            disabled={true}
            type="text"
            setHasErrorValidation={setHasErrorValidation}
            component={CustomInput}
            label={
              <FormattedMessage id="cloudAccountPage.form.latestAgentActivity" />
            }
          />
          <Field
            name="latestUserActivity"
            format={formatter.formatDate}
            id="latestUserActivity"
            disabled={true}
            type="text"
            setHasErrorValidation={setHasErrorValidation}
            component={CustomInput}
            label={
              <FormattedMessage id="cloudAccountPage.form.latestUserActivity" />
            }
          />
          </>
      )}
      <Field
        name="technicalContact.email"
        id="technicalContactEmail"
        disabled={props.readOnly}
        type="text"
        component={CustomInput}
        label={
          <FormattedMessage id="cloudAccountPage.form.technicalContact" />
        }
      />
      <Field
        name="commercialContact.email"
        id="commercialContactEmail"
        disabled={props.readOnly}
        type="text"
        component={CustomInput}
        label={
          <FormattedMessage id="cloudAccountPage.form.commercialContact" />
        }
      />
        
    </fieldset>
  );
};

FormSectionDetails.propTypes = {
  readOnly: PropTypes.bool.isRequired
};

FormSectionDetails.defaultProps = {
  readOnly: false
};

export default FormSectionDetails;
