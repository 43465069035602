import React from 'react';
import Navbar from 'react-bootstrap/lib/Navbar';
import Nav from 'react-bootstrap/lib/Nav';
import NavItem from 'react-bootstrap/lib/NavItem';
import NavDropdown from 'react-bootstrap/lib/NavDropdown';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import EvsViaLogo from '../../../assets/images/evs-via.png';
import { logoutFromKeycloak, redirectToUserAccount } from './../../../actions/keycloakActions';
import AccountsDropdown from './AccountDropdown.js';
import AboutItemMenu from './About.js'
import { UserRights } from '../../../utils/ability';
import Can from '../abilities/Can';

const Header = ({ match, logoutFromKeycloak, hasAccounts, username, displayAdminMenu, accountId }) => {

  function logout() {
    logoutFromKeycloak(`${window.location.origin}/login`);
  }

  function myAccount() {
    redirectToUserAccount();
  }
  return (
    <Navbar collapseOnSelect fixedTop fluid>
      <Navbar.Header>
        <Navbar.Brand>
          <Link className="navbar-brand" style={{padding: 0}} to="/"><img src={EvsViaLogo} alt="EVS | VIA" /></Link>
        </Navbar.Brand>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse>
        {hasAccounts &&
          <Nav>
            <LinkContainer data-test-id="nav-users" to={`${match.url}/users`}>
              <NavItem><i className="fa fa-user margin-right"></i>Users</NavItem>
            </LinkContainer>
            <LinkContainer data-test-id="nav-agents" to={`${match.url}/agents`}>
              <NavItem><i className="fa fa-truck margin-right"></i>Agents</NavItem>
            </LinkContainer>
            <Can I={UserRights.DisplayApiKeys}>
              <LinkContainer data-test-id="nav-auth-keys" to={`${match.url}/authkeys`}>
                <NavItem><i className="fa fa-key margin-right"></i>Auth Keys</NavItem>
              </LinkContainer>
            </Can>
            <Can I={UserRights.DisplayAndPerformActionsOnLocations}>
              <LinkContainer data-test-id="nav-locations" to={`${match.url}/locations`}>
                <NavItem><i className="fa fa-map-marker margin-right"></i>Locations</NavItem>
              </LinkContainer>
            </Can>
            <LinkContainer data-test-id="nav-cloud-account" to={`${match.url}/cloudaccount`}>
              <NavItem><i className="fa fa-cloud margin-right"></i>Cloud Account</NavItem>
            </LinkContainer>
            <NavItem data-test-id="nav-monitoring" href={`${window.env.monitoringWebSiteUrl}?accountId=${accountId}`}>
              <i className="fa fa-tasks margin-right"></i>Monitoring
            </NavItem>
          </Nav>
        }
        {displayAdminMenu &&
          <Nav>
            <NavDropdown data-test-id="nav-admin" title="Admin" id="admin-dropdown">
              <Can I={UserRights.DisplayCloudAccounts}>
                <LinkContainer data-test-id="nav-admin-cloudaccounts" to={`${match.url}/admin/cloudaccounts`}>
                  <MenuItem >Cloud Accounts</MenuItem>
                </LinkContainer>
              </Can>
              <Can I={UserRights.DisplayAgents}>
                <LinkContainer data-test-id="nav-admin-agents" to={`${match.url}/admin/agents`}>
                  <MenuItem >Agents</MenuItem>
                </LinkContainer>
              </Can>
            </NavDropdown>
          </Nav>
        }
        <Can I={UserRights.DisplayXmo}>
          <Nav>
            <LinkContainer data-test-id="nav-xtramotion" to={`${match.url}/xtramotion`}>
              <NavItem><i className="fa fa-cloud margin-right"></i>XtraMotion</NavItem>
            </LinkContainer>
          </Nav>
        </Can>
        <Nav pullRight>
          <AccountsDropdown><i className="" style={{ 'minWidth': '14px', 'display': 'inline-block' }}></i></AccountsDropdown>
          <NavDropdown eventKey={3} id="basic-nav-dropdown" title={<span><i className="fa fa-question-circle-o"></i>Help</span>} >
            <MenuItem eventKey={3.1} href={window.location.origin + "/C-Next Cloud - User Manual.pdf"} target="_blank"><i className="fa fa-question-circle"></i>Help</MenuItem>
            <MenuItem divider />
            <AboutItemMenu />
          </NavDropdown>
          <NavDropdown eventKey={3} title={<span><i className="fa fa-user-circle-o"></i>{username}</span>} id="basic-nav-dropdown">
            <MenuItem eventKey={3.1} onSelect={myAccount}><i className="fa fa-address-card-o margin-right"></i>My Account</MenuItem>
            <MenuItem divider />
            <MenuItem eventKey={3.2} onSelect={() => logout()}><i className="fa fa-sign-out"></i>Sign-out</MenuItem>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

function mapStateToProps(state) {
  return {
    hasAccounts: state.account.accounts.length > 0,
    accountId: state.account.currentAccount.id,
    username: state.keycloak.userInfo.name,
    displayAdminMenu: state.common.userRights.displayAgents || state.common.userRights.displayCloudAccounts,
  };
}

export default connect(mapStateToProps, { logoutFromKeycloak, redirectToUserAccount })(Header);