import React from 'react';
import HistoricOperationRow from './HistoricOperationRow';
import {Scrollbars} from 'react-custom-scrollbars';
import {UserRights} from '../../../../utils/ability';
import styles from './HistoricOperations.module.scss';
import {FormattedMessage} from 'react-intl';
import Can from '../../../common/abilities/Can';

const HistoricOperations = ({historicOperations}) => {
    return (
        <Can I={UserRights.DisplayHistoryXmo}>
            <div className={styles.root}>
                <h3>
                    <FormattedMessage id='xtraMotionPage.historicPanel.title'/>
                </h3>
                <div className={styles.tableContainer}>
                    <div className={styles.innerTableContainer}>
                        <Scrollbars>
                            <table className={styles.customTable}>
                                <thead>
                                <tr>
                                    {[
                                        'instance',
                                        'startedBy',
                                        'startDate',
                                        'stopDate',
                                        'duration',
                                        'points',
                                    ].map((columnName) => (
                                        <th key={columnName}>
                                            <FormattedMessage
                                                id={`xtraMotionPage.historicPanel.columns.${columnName}`}
                                            />
                                        </th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {historicOperations.length === 0 && (
                                    <h3 className={styles.tablePlaceholder}>
                                        <FormattedMessage id={`xtraMotionPage.historicPanel.placeholder`}/>
                                    </h3>
                                )}
                                {historicOperations.map((instanceActivity, index) => (
                                    <HistoricOperationRow
                                        key={`${instanceActivity.instanceId}_${instanceActivity.serverStartDate}_${index}`}
                                        {...instanceActivity}
                                    />
                                ))}
                                </tbody>
                            </table>
                        </Scrollbars>
                    </div>
                </div>
            </div>
        </Can>
    );
}

export default HistoricOperations;
