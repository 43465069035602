import React, { FC, useState} from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import '../../../../assets/css/modal.css';
import { connect } from 'react-redux';
import { isInvalid, isSubmitting, submit } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { injectIntl } from 'react-intl';
import { getErrorDescription } from '../../../../api/axiosConfig';
import CreditOrderForm, { CreditOrderFormId } from "../../../cloudAccount/orderForm/CreditOrderForm";

type Props = {
    title: string;
    submit: (model:any) => void;
    submitting: boolean;
    isInvalid: boolean;
    intl: any;
    onClose: () => void;
    onSubmit: (values:any) => void;
}

const CreditOrderModal:FC<Props> = ({submit, submitting, isInvalid, intl, onClose, onSubmit, title  }) => {

    const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);

    const handleSubmit = async (values:any) => {
        try {
            setSubmitInProgress(true);
            await onSubmit(values);
            toastr.success(intl.formatMessage({ id: 'cloudAccountPage.form.createOrder.addCreditOrderSucceed' }));
            onClose();
        }
        catch (ex) {
            toastr.error(intl.formatMessage({ id: 'cloudAccountPage.form.createOrder.addCreditOrderFailed' }),getErrorDescription(ex));
        }
        finally {
            setSubmitInProgress(false);
        }
    };

    let classSubmitBtn = submitInProgress ? 'fa fa-spinner fa-spin' : 'fa fa-check';
    return (
        <Modal show={true} data-test-id="modal-add-credit-order">
            <Modal.Header closeButton onHide={onClose}>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CreditOrderForm
                    onSubmit={handleSubmit}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    data-test-id="btn-submit-modal"
                    bsStyle='primary'
                    onClick={() => submit(CreditOrderFormId)}
                    disabled={submitting || isInvalid}
                >
                    <i className={classSubmitBtn}/>Add
                </Button>
                <Button onClick={onClose} data-test-id="btn-cancel-modal">
                    <i className='fa fa-times'/>Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );

}

export default connect(
    state => ({
        submitting: isSubmitting(CreditOrderFormId)(state),
        isInvalid: isInvalid(CreditOrderFormId)(state),
    }),
    { submit }
)(injectIntl(CreditOrderModal));
