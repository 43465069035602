import React, { Component } from 'react';
import { connect } from 'react-redux';

const userRightComponent = (options) =>
    (WrappedComponent) => {
        class UserRightcomponent extends Component {

            constructor(props) {
                super(props);
                this.state = {
                    hasRight: this.checkRights(this.props.userRights)
                }
            }

            componentWillReceiveProps(nextProps) {
                if (nextProps.userRights !== this.props.userRights) {
                    this.setState({hasRight:this.checkRights(nextProps.userRights)});
                }
            }

            checkRights(userRights) {
                if (userRights) {
                    let hasRight = options.rights.every(item => userRights[item]);
                    if (!hasRight)
                        this.props.history.push('/unauthorized');
                    return hasRight;
                }
                return false;
            }

            render() {
                return this.state.hasRight ? <WrappedComponent {...this.props} /> : null;
            }
        }

        function mapStateToProps(state) {
            return {
                userRights: state.common.userRights,
            };
        }

        return connect(mapStateToProps, null)(UserRightcomponent);
    }

export default userRightComponent;
