import React from 'react';
import {getDiffDateDaysHours} from '../../../../utils/dateUtils';
import UtcToLocalTime from "../../../common/utils/UtcToLocalTime";

const HistoricActivityTabLine = ({instanceName, serverStartDate, serverStopDate, points, startedBy}) => (
    <tr>
        <td>{instanceName}</td>
        <td>{startedBy}</td>
        <td><UtcToLocalTime date={serverStartDate}/></td>
        <td><UtcToLocalTime date={serverStopDate}/></td>
        <td>{getDiffDateDaysHours(serverStartDate, serverStopDate)}</td>
        <td>{points}</td>
    </tr>
)

export default HistoricActivityTabLine;