import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { injectIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import { showConfirmDlg, hideModal } from '../../../actions/modalActions';
import { refreshUserSession } from './../../../actions/userActions';
import CloudAccountItem from './CloudAccountItem';
import './cloud-account-mgmt.css';
import { setCurrentUserAccount, initializeCloudAccountMgmtPage } from '../../../actions/accountActions';
import AccountsApi from './../../../api/accountsApi';

class CloudAccountList extends Component {

    state = {
        addNewCloudAccount: false
    }

    static propTypes = {
        cloudAccounts: PropTypes.array.isRequired,
        userRights: PropTypes.object.isRequired
    }

    static defaultProps = {
        cloudAccounts: []
    }

    handleClickItem = item => {
        this.props.history.push(`/app/admin/cloudaccounts/${item.id}`);
    }

    handleSendEmail = (event, cloudAccount) => {
        event.preventDefault();
        event.stopPropagation();
        window.location.href = this.props.intl.formatMessage({ id: 'cloudAccountMgmtPage.sendEmailtoOwner' }, {
            owner: cloudAccount.owner,
            name: cloudAccount.displayName
        });
    }

    handleRedirection = async (event, cloudAccount, redirectUrl) => {
        event.preventDefault();
        event.stopPropagation();
        // change user account before
        if (this.props.currentAccount.id !== cloudAccount.id)
            await this.props.setCurrentUserAccount(this.props.userEmail, cloudAccount.id);
        this.props.history.push(redirectUrl);
    }

    disableCloudAccount = async (event, cloudAccount) => {
        let { intl } = this.props;
        event.preventDefault();
        event.stopPropagation();
        this.props.showConfirmDlg({
            title: intl.formatMessage({ id: 'cloudAccountMgmtPage.confirmDlg.title' }),
            htmlBody: intl.formatHTMLMessage({ id: 'cloudAccountMgmtPage.confirmDlg.text' }, { accountName: cloudAccount.displayName }),
            async: true,
            onConfirm: async () => {
                await AccountsApi.disableAccount(cloudAccount.id);
                await Promise.all([this.props.refreshUserSession(), this.props.initializeCloudAccountMgmtPage()]);
                this.props.history.push('/app/admin/cloudaccounts');
                toastr.success(intl.formatMessage({ id: 'common.toastr.success' }), intl.formatMessage({ id: 'cloudAccountMgmtPage.toastrAccountDisableSucceed' }, { accountName: cloudAccount.displayName }));
            },
            onClose: () => this.props.hideModal() //nothing to do
        });
    }

    render() {
        return (
            <div className="flex-list-container">
                <div style={{ height: '35px', maxHeight: '35px', padding: '15px' }}>
                    {this.props.userRights && this.props.userRights.addCloudAccount &&
                        <OverlayTrigger placement="top" delayShow={500} overlay={<Tooltip id="tooltip">Add Cloud Account</Tooltip>}>
                            <Link to={`/app/admin/cloudaccounts/add`} style={{ float: 'right' }} className="btn btn-default btn-add-location">
                                <i className="fa fa-plus no-text"/>
                            </Link>
                        </OverlayTrigger>
                    }
                </div>
                <div className="list-group agent-list">
                    <Scrollbars ref="scrollbars" >
                        {this.props.cloudAccounts.map(item =>
                            <CloudAccountItem
                                rights={this.props.userRights}
                                key={item.id}
                                item={item}
                                isActive={this.props.match.params.cloudaccountid === item.id}
                                onClick={() => this.handleClickItem(item)}
                                onSendEmail={event => this.handleSendEmail(event, item)}
                                onManageUsers={event => this.handleRedirection(event, item, '/app/users')}
                                onManageAgents={event => this.handleRedirection(event, item, '/app/agents')}
                                onManageAccount={event => this.handleRedirection(event, item, '/app/cloudaccount')}
                                onDisable={event => this.disableCloudAccount(event, item)}
                            />
                        )}
                    </Scrollbars>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        currentAccount: state.account.currentAccount,
        userEmail: state.keycloak.userInfo.email
    };
}

export default connect(mapStateToProps, { setCurrentUserAccount, showConfirmDlg, hideModal, refreshUserSession, initializeCloudAccountMgmtPage })(injectIntl(CloudAccountList));