import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Can } from '@casl/react';

const withReduxAbilities = CanComponent => {
  class CanRedux extends PureComponent {
    render() {
      return (
        <CanComponent {...this.props} />
      )
    }
  }
  return connect(state => ({ability: state.common.abilities}))(CanRedux);
}

export default withReduxAbilities(Can);