import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { initKeycloak } from './../actions/keycloakActions';
import { initUserSession } from './../actions/userActions';
import { showSpinner, hideSpinner } from './../actions/spinnerActions';
import { getUserAccountFromUrl, setUserAccountCookie } from './../utils/userAccountUtils';

const authComponent = (options) =>
    (WrappedComponent) => {
        class KeyCloakComponent extends Component {
            constructor(props) {
                super(props);
                this.state = {
                    isAuthenticated: null,
                    isUserSessionInitialized: false,
                    loginRequired: options.onLoad === 'login-required',
                    redirectAfterCallback: options.redirectAfterCallback
                }
            }

            async componentDidMount() {
                try {
                    this.props.showSpinner("Performing user sign-in");
                    let authenticated = await this.props.initKeycloak(options);
                    this.props.hideSpinner();
                    this.setState({ isAuthenticated: authenticated });
                    if (authenticated) {
                        if (this.state.redirectAfterCallback) {
                            //if accountId is pass as parameter, set it to cookie
                            const userAccountId = getUserAccountFromUrl();
                            if (userAccountId) {
                                setUserAccountCookie(this.props.userEmail,userAccountId);
                            }
                            this.props.history.push(this.state.redirectAfterCallback);
                        } else {
                            this.props.showSpinner("Loading user profile");
                            try{
                                await this.props.initUserSession(this.props.userEmail);
                            } catch(error)
                            {
                                if(error && error.message && error.message === "Account not found")
                                    this.props.history.push('/accountnotfound');
                            }
                            this.setState({ isUserSessionInitialized: true });
                        }
                    }
                }
                catch (error) {
                    toastr.error("Init Keycloak failed", error.response ? error.response.data : "")
                }
            }

            render() {
                let showComponent = (this.state.loginRequired && this.state.isUserSessionInitialized) || (this.state.isAuthenticated === false && !this.state.loginRequired);
                if (showComponent) {
                    return <WrappedComponent {...this.props} />
                }
                    return null; 
            }
        }

        function mapStateToProps(state) {
            return {
                userEmail: state.keycloak.userInfo ? state.keycloak.userInfo.email : null,
            };
        }

        return connect(mapStateToProps, { initKeycloak, initUserSession, showSpinner, hideSpinner })(KeyCloakComponent);

    }

export default authComponent;

