import { Ability, AbilityBuilder } from "@casl/ability"

export function defineRules(rights) {
  const { can, rules } = new AbilityBuilder(Ability);
  Object.keys(rights).forEach(key => {
    if (rights[key])
      can(key);
  });
  return new Ability(rules);
}

export const UserRights = {
  StartStopXmo: "startStopXmo",
  UpdateXmo: "updateXmo",
  DisplayXmo: "displayXmo",
  DisplayHistoryXmo: "displayHistoryXmo",
  DisplayAndPerformActionsOnInstances: 'displayAndPerformActionsOnInstances',
  DisplayAgents: "displayAgents",
  DisplayCloudAccounts: "displayCloudAccounts",
  DisplayApiKeys: "displayApiKeys",
  DisplayAndPerformActionsOnLocations: "displayAndPerformActionsOnLocations",
  DisplayCredits: "displayCredits",
  DownloadCreditsReport: "downloadCreditsReport",
  DisplayAdminMenu: "displayAdminMenu",
  UpdateCloudAccountSubscription: "updateCloudAccountSubscription",
  AddCredits: "addCredits",
}