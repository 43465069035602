import React from 'react';

export default props => {
    let hasError = (props.meta.dirty || props.meta.touched) && props.meta.error;
    let formGroupClass = hasError ? 'form-group has-error has-feedback' : 'form-group';
    let { meta, input, children, ...customAttrs } = props;
    return (
        <div className={formGroupClass}>
            <label className="col-sm-4 control-label" htmlFor="name">{customAttrs.label}</label>
            <div className="col-sm-8">
                <select {...input} {...customAttrs} className="form-control">
                    {children}
                </select>
                {hasError && <span className="help-block">{meta.error}</span>}
            </div>
        </div>
    )
}