import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import CustomCheckbox from '../../common/inputs/CustomCheckbox';
import { UserRights } from '../../../utils/ability';
import Can from '../../common/abilities/Can';

const FormSectionServices = () => {
    return (
        <Can I={UserRights.UpdateCloudAccountSubscription} passThrough>
            {allowed => (
                <fieldset key="fieldset-1">
                    <legend><FormattedMessage id='cloudAccountPage.form.legendServices' /></legend>
                    <Field 
                        name="subscription.features.xmo.isActive"
                        id="subscription.features.xmo.isActive"
                        disabled={!allowed} 
                        parse={value => !!value}
                        component={CustomCheckbox} 
                        label={<FormattedMessage id='cloudAccountPage.form.features.xmo' />} 
                    />
                </fieldset>
            )}
        </Can>
    )
}

FormSectionServices.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    features: PropTypes.object.isRequired,
}

FormSectionServices.defaultProps = {
    readOnly:false,
    features:{}
}

export default FormSectionServices;