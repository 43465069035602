import { toastr } from 'react-redux-toastr';
import * as types from './actionTypes';
import LocationApi from '../api/locationsApi';
import ProtocolDefinitionApi from '../api/protocolDefinitionApi';
import { showSpinner, hideSpinner } from './spinnerActions';
import AgentsApi from '../api/agentsApi';

const getAgentsLinkedSucceeded =  payload => ({
   type: types.REQUEST_COUNT_AGENTS_SUCCEEDED,
   payload 
})

const getProtocolsSucceeded = payload => ({
    type: types.REQUEST_GET_PROTOCOLS_SUCCEEDED,
    payload
})

const getLocationsSucceeded = payload => ({
    type: types.REQUEST_GET_LOCATIONS_SUCCEEDED,
    payload
})

const getLocationsFailed = error => ({
    type: types.REQUEST_GET_LOCATIONS_FAILED,
    error
})

const deleteLocationSucceeded = key => ({
    type: types.REQUEST_DELETE_LOCATION_SUCCEEDED,
    key
});

const saveLocationSucceeded = (location, key) => ({
    type: types.REQUEST_SAVE_LOCATIONS_SUCCEEDED,
    location,
    key
})

const saveLocationFailed = error => ({
    type: types.REQUEST_SAVE_LOCATIONS_FAILED,
    error
})

function convertToLocationApi(location) {
    const subFolderProp = window.env.subFolderPropertyName;
    
    // special case: remove subfolder from parameters
    var filteredParameters = location.parameters.filter(param => param.label !== subFolderProp);

    let locationApi = {
        id: location.id ? location.id : undefined,
        name: location.name,
        lastUpdateDate: location.lastUpdateDate,
        endpoints: [{
            id: location.endpointId ? location.endpointId : undefined,
            protocol: location.protocol,
            overwritePolicy: location.overwritePolicy,
            configuration: filteredParameters.reduce(function (acc, current, i) {
                if (current.value)
                    acc[current.label] = { value: current.value, sensitive: current.sensitive };
                return acc;
            }, {})
        }],
    }

    // add subfolder property?
    const subFolder = location.parameters.find(param => param.label === subFolderProp);
    if (subFolder && subFolder.value) {
      locationApi.subfolder = subFolder.value
    }

    //add extra parameters
    location.extraParameters.forEach(param => {
        locationApi.endpoints[0][param.label] = param.type === 'number' ? parseInt(param.value, 10) : param.value;
    });

    return locationApi;
}

export function initializeLocationsConfiguration(accountId) {
    return function (dispatch) {
      dispatch(showSpinner());  
      return Promise.all([ProtocolDefinitionApi.getAll(), LocationApi.getLocationsByAccountId(accountId),AgentsApi.getAgents(accountId)]).then(([protocols, locations,agents]) => {
          dispatch(getProtocolsSucceeded(protocols.data));
          dispatch(getLocationsSucceeded(locations.data));
          dispatch(getAgentsLinkedSucceeded(agents.data.length))
          dispatch(hideSpinner());
        },error => {
          toastr.error("Can't get locations", error.fullError); 
          dispatch(hideSpinner());
        });
    }
}

export const orderByLocationColumn = (payload) => ({
    type: types.TOGGLE_ORDERBY_LOCATION_COLUMN,
    payload
})

export const cancelEditLocation = (locationId) => ({
    type: types.CANCEL_EDIT_LOCATION,
    locationId
})

export const clearLocations = () => ({
    type: types.CLEAR_ALL_LOCATIONS
})

export const addLocation = (protocolId) => ({
    type: types.ADD_LOCATION,
    protocolId
})

export function deleteLocation(location, accountId) {
    return function (dispatch) {
        let promiseDelLocation = location.id ? LocationApi.deleteLocation(location, accountId) : Promise.resolve(true);
        return promiseDelLocation.then(() => {
            dispatch(deleteLocationSucceeded(location.key));
        }, error => {
            return Promise.reject(error);
        });
    }
}

export function saveLocation(location) {
    return function (dispatch, getState) {
      const {id:accountId} = getState().account.currentAccount;
      return LocationApi.saveLocation(convertToLocationApi(location),accountId).then( ({data}) => {
            dispatch(saveLocationSucceeded(data, location.key));
            return data.id;
        }, error => {
            dispatch(saveLocationFailed({ errorMsg: error.request.status }));
            return Promise.reject(error);
        })
    }
}

export function getLocations(){
    return function (dispatch,getState) {
        const {id:accountId} = getState().account.currentAccount;        
        return LocationApi.getLocationsByAccountId(accountId).then(locations => 
            {
                dispatch(getLocationsSucceeded(locations.data));
                return locations;
            }, error => {
                dispatch(getLocationsFailed({ errorMsg: error }));
                return Promise.reject(error);
            });
    }
}