import * as types from './actionTypes';
import AuthKeysApi from './../api/authKeysApi';
import { showSpinner, hideSpinner } from './spinnerActions';

const getAuthKeysSucceeded = payload => ({
    type: types.REQUEST_GET_AUTH_KEYS_SUCCEEDED,
    payload
})

export const initializeAuthKeysMgmtPage = (accountId, cancelToken = null) => {
    return async dispatch => {
        dispatch(showSpinner());
        const {data} = await AuthKeysApi.getAuthKeys(accountId, cancelToken);
        dispatch(getAuthKeysSucceeded(data));
        dispatch(hideSpinner());
    }
}

export const addAuthKey = (accountId, name, cancelToken = null) => {
    return async dispatch => {
        dispatch(showSpinner());
        const {data} = await AuthKeysApi.createAuthKey(accountId, name, cancelToken);
        dispatch(getAuthKeysSucceeded(data));
        dispatch(hideSpinner());
    }
}

export const removeAuthKey = (accountId, authKey, cancelToken = null) => {
    return async dispatch => {
        dispatch(showSpinner());
        await AuthKeysApi.removeAuthKey(authKey, cancelToken);
        const {data} = await AuthKeysApi.getAuthKeys(accountId, cancelToken);
        dispatch(getAuthKeysSucceeded(data));
        dispatch(hideSpinner());
    }
}