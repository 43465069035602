import React from 'react';
import classnames from 'classnames';

export default ({data, onClick}) => {
    var headers = data.map(header => {
        let classes = classnames('flex-table-row-item', header.className);
        let order = header.orderBy;
        let classOrderIcon = classnames('fa sort-icon', {'fa-sort':order === 'none'},{'fa-sort-asc':order === 'asc'},{'fa-sort-desc':order === 'desc'});
        return (
            <div key={header.label} onClick={() => onClick(header.label)} className={classes}>
                {header.label}
                {header.orderBy && <i className={classOrderIcon}></i>}
            </div>
        )
    });
    return <div className="flex-table-row flex-table-header">{headers}</div>;
}