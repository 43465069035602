import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import ReduxToastr from 'react-redux-toastr';
import Header from './common/header/Header';
import UsersPage from './users/UsersPage';
import AgentsPage from './agents/AgentsPage';
import SettingsPage from './settings/SettingsPage';
import CloudAccountPage from './cloudAccount/CloudAccountPage';
import Footer from './common/footer/Footer.js';
import 'evs-themes-web-bootstrap3/dist/css/bootstrap-dark-evs.min.css';
import ModalRoot from './common/modal/ModalRoot';
import CloudAccountMgmtPage from './admin/cloudAccountMgmt/CloudAccountMgmtPage';
import userRightsComponent from './userRightsComponent';
import NotFoundPage from './../components/common/error/NotFoundPage';
import AuthKeysMgmtPage from './authKeysMgmt/AuthKeysMgmtPage';
import LocationsPage from './locations/LocationsPage';
import XtraMotionPage from './xtraMotion/XtraMotionPage'

const SecureCloudAccountMgmtPage = userRightsComponent({rights:['displayCloudAccounts']})(CloudAccountMgmtPage);
const SecureAgentPage = userRightsComponent({rights:['displayAgents']})(AgentsPage);
const SecureLocationPage = userRightsComponent({rights:['displayAndPerformActionsOnLocations']})(LocationsPage);
const SecureAuthKeysPage = userRightsComponent({rights:['displayApiKeys']})(AuthKeysMgmtPage);
const SecureXtraMotionPage = userRightsComponent({rights:['displayXmo']})(XtraMotionPage);

const queryClient = new QueryClient();

const SecureApp = ({ match }) => {
    return (
        <div>
            <QueryClientProvider client={queryClient}>
            <Header match={match} />
            <Switch>
                <Route path={`${match.url}/users/:user_email?`} component={UsersPage} />
                <Route exact path={`${match.url}/agents/:agent_id?`} component={AgentsPage} />
                <Route exact path={`${match.url}/settings`} component={SettingsPage} />
                <Route exact path={`${match.url}/cloudaccount`} component={CloudAccountPage} />
                <Route path={`${match.url}/admin/cloudaccounts/:cloudaccountid?`} component={SecureCloudAccountMgmtPage} />
                <Route exact path={`${match.url}/admin/agents/:agent_id?`} component={SecureAgentPage} />
                <Redirect from={`${match.url}/home`} to={`${match.url}/users`}/>
                <Route path={`${match.url}/authkeys`} component={SecureAuthKeysPage} />
                <Route path={`${match.url}/locations`} component={SecureLocationPage} />
                <Route path={`${match.url}/xtramotion`} component={SecureXtraMotionPage} />
                <Route component={NotFoundPage} />
            </Switch>
            <Footer />
            <ReduxToastr
                timeOut={4000}
                newestOnTop={false}
                preventDuplicates={true}
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar />
            <ModalRoot />
            </QueryClientProvider>
        </div>
    )
}

export default SecureApp;